@charset "UTF-8";
.pg-top {
  .mv {
    overflow: hidden;
    transition: .5s;
    &:hover {
      opacity: 0.9;
    }
    .swiper-container {
      .swiper-slide {
        width: 100%;
        .bg {
          width: 100%;
          height: 530px;
          background-size: cover;
          background-position: center;
        }
        @media all and (min-width: 769px) {
          a {
            width: 100%;
            height: 530px;
            display: block;
          }
        }
        &.slide01 .is-pc {
          font-family: $mincho;
          color: $c-ao;
          text-align: center;
          span.ti1 {
            padding: 70px 70px 0 0;
            writing-mode: vertical-rl;
            font-size: 2rem;
            letter-spacing: 5px;
          }
          span.ti2 {
            margin: 0 0 40px 12px;
            line-height: 1;
            font-size: 6rem;
            letter-spacing: 10px;
            display: block;
          }
          span.btn {
            margin: 0 auto;
            width: 130px;
            height: 40px;
            line-height: 40px;
            background: $c-ao;
            color: #fff;
            font-size: 1.5rem;
            display: block;
            transition: .3s ease-in-out;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            &:hover {
              opacity: .7;
            }
          }
        }
        &.slide02 .is-pc {
          img {
            margin: 0 auto 35px;
            padding-top: 100px;
            width: 305px;
            display: block;
          }
          span.btn {
            margin: 0 auto;
            border: 1px $c-ao solid;
            width: 130px;
            height: 40px;
            line-height: 40px;
            font-size: 1.5rem;
            display: block;
            font-family: $mincho;
            color: $c-ao;
            text-align: center;
            transition: .3s ease-in-out;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            &:hover {
              opacity: .7;
            }
          }
        }
        &.slide04 .is-pc {
          img {
            margin: 0 auto;
            padding-top: 60px;
            width: 825px;
            display: block;
          }
        }
      }
      @include media(sp) {
        width: 100%;
        height: auto;
        display: block;
        .swiper-slide .bg {
          background: transparent;
          height: auto;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  // ======================
  // SEC01
  // ======================
  .sec01 {
    text-align: center;
    font-family: $mincho;
    padding: 50px 0 40px;
    background: url('/img/common/pat_01.png') left top repeat;
    @include media(sp) {
      padding: 11% 0;
    }
    &-head {
      color: $c-audo;
      font-size: 2.4rem;
      line-height: 1.86;
      @include media(sp) {
        font-size: 5.4vw;
      }
    }
    &-subhead {
      color: #fff;
      font-size: 1.6rem;
      line-height: 1.86;
      margin-bottom: 28px;
      @include media(sp) {
        font-size: 3.4vw;
      }
    }
    [class^="btn"] {
      a {
        width: 320px;
      }
      @include media(sp) {
        a {
          display: inline-block;
          width: 100%;
          font-size: 1.4rem;
          &::after {
            right: 7%;
          }
        }
      }
    }
  }

  // ======================
  // SEC02
  // ======================
  .sec02 {
    padding: 52px 0;
    background: url('/img/common/pat_02.png') left top repeat;
    @include media(sp) {
      padding: 52px 0 77px;
    }
    .sec02-head {
      text-align: center;
      font-weight: normal;
      span {
        font-family: $mincho;
        &:nth-child(1) {
          writing-mode: vertical-rl;
          font-size: 2rem;
          color: $c-ao;
          @include media(sp) {
            // font-size: 1.9rem;
            font-size: 5.7vw;
            letter-spacing: .1em;
            writing-mode: horizontal-tb;
          }
        }
        &:nth-child(2) {
          display: block;
          color: $c-aka;
          font-size: 6rem;
          margin-top: -2px;
          line-height: 1;
          @include media(sp) {
            // font-size: 4rem;
            font-size: 12.1vw;
            margin-top: 0;
            line-height: 1.2;
          }
        }
      }
    }

    .strong-head {
      width: 278px;
      height: 278px;
      border-radius: 50%;
      background: $c-ao;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: $c-audo;
      font-family: $mincho;
      font-size: 2.8rem;
      line-height: 1.78;
      margin-bottom: 25px;
      @include media(sp) {
        position: relative;
        width: 52%;
        height: 0;
        padding-bottom: 52%;
        display: flex;
        margin: 0 auto 20px;
        font-size: 4.5vw;
        font-weight: normal;
        span {
          display: block;
          text-align: center;
          position: absolute;
          top: 51%;
          transform: translateY(-50%);
        }
      }
    }

    ul {
      display: flex;
      @include media(sp) {
        display: block;
      }
      li {
        position: relative;
        padding-bottom: 95px;
        padding-top: 56px;
        @include media(sp) {
          padding-bottom: 77px;
          padding-top: 35px;
          margin-top: 33px;
        }
        p {
          &:nth-of-type(1) {
            line-height: 1.67;
            @include media(sp) {
              font-size: 1.4rem;
            }
          }
          &:last-child {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
          }
          a {
            display: block;
          }
        }
        .btn__red {
          a {
            padding: 19px;
            @include media(sp) {
              padding: 12px;
              width: 64%;
              font-size: 1.6rem;
              margin: 0 auto;
              &::after {
                right: 15px;
              }
            }
          }
        }
        &:nth-child(1) {
          padding-right: 42px;
          @include media(sp) {
            padding-right: 0;
          }
          p {
            &:last-child {
              padding-right: 42px;
              @include media(sp) {
                padding-right: 0;
              }
            }
          }
        }
        &:nth-child(2) {
          border-left: 1px solid $c-gray;
          border-right: 1px solid $c-gray;
          padding-left: 42px;
          padding-right: 42px;
          @include media(sp) {
            border: 0;
            border-top: 1px solid $c-gray;
            padding-left: 0;
            padding-right: 0;
          }
          p {
            &:last-child {
              padding: 0 42px;
              @include media(sp) {
                padding: 0;
              }
            }
          }
        }
        &:nth-child(3) {
          padding-left: 42px;
          @include media(sp) {
            padding-left: 0;
            border-top: 1px solid $c-gray;
            .strong-head {
              span {
                top: 49%;
              }
            }
          }
          p {
            &:last-child {
              padding-left: 42px;
              @include media(sp) {
                padding-left: 0;
              }
            }
          }
        }
      }
    }

    .sec02-wrap {
      margin-top: -27px;
      position: relative;
      overflow: hidden;
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        top: 0;
        left: -56%;
        background: $c-gray;
      }
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        top: 0;
        right: -56%;
        background: $c-gray;
      }
      @include media(sp) {
        &::before,
        &::after {
          content: normal;
        }
      }
    }
  }

  // ======================
  // SEC03
  // ======================
  .sec03 {
    display: none;

    padding: 84px 0 100px;
    background: #f9f6f0;
    @include media(sp) {
      padding: 48px 0 60px;
    }
    .sec03-head {
      text-align: center;
      font-size: 3.2rem;
      color: $c-ao;
      font-family: $mincho;
      margin-bottom: 48px;
      @include media(sp) {
        font-size: 5.6vw;
        font-weight: normal;
        margin-bottom: 32px;
      }
    }
    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      li {
        width: 480px;
        padding: 20px 20px 30px;
        background: #fff;
        border: 1px solid $c-gray;
        @include media(sp) {
          padding: 15px 15px 30px;
        }
        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 40px;
          @include media(sp) {
            margin-bottom: 30px;
          }
        }
        &:nth-child(3) {
          @include media(sp) {
            margin-bottom: 30px;
          }
        }
        .thum {
          margin-bottom: 24px;
          img {
            width: 100%;
            height: auto;
          }
        }
        .course-head {
          margin-bottom: 9px;
          text-align: center;
          font-size: 2.4rem;
          color: $c-ao;
          font-family: $mincho;
          @include media(sp) {
            font-size: 2rem;
            font-weight: normal;
          }
        }
        .course-lead {
          line-height: 1.625;
          font-size: 1.6rem;
          max-width: 355px;
          margin: 0 auto 20px;
          @include media(sp) {
            font-size: 1.4rem;
            text-align: center;
          }
        }
        p {
          &:last-child {
            width: 180px;
            margin: 0 auto;
            a {
              display: block;
            }
            @include media(sp) {
              width: 70%;
              a {
                font-size: 1.6rem;
                padding: 11px;
                &::after {
                  right: 15px;
                }
              }
            }
          }
        }
      }
    }
  }

  // ======================
  // SEC04
  // ======================
  .sec04 {
    padding: 112px 0 162px;
    background: url('/img/common/pat_03.png') left top repeat;
    @include media(sp) {
      padding: 28px 0 43px;
    }
    .experience-circle {
      width: 515px;
      height: 515px;
      margin: 0 auto;
      border-radius: 50%;
      border: 2px solid $c-aka;
      display: flex;
      justify-content: center;
      align-items: center;
      @include media(sp) {
        width: 90%;
        height: 0;
        padding-bottom: 90%;
        position: relative;
        z-index: 1;
      }
    }
    .circle-inner {
      text-align: center;
      font-family: $mincho;
      margin-top: -80px;
      @include media(sp) {
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 18%;
        width: 100%;
      }
    }
    .circle-head {
      font-size: 4.8rem;
      color: $c-aka;
      border-top: 1px solid $c-aka;
      border-bottom: 1px solid $c-aka;
      font-weight: normal;
      margin-bottom: 20px;
      @include media(sp) {
        font-size: 8.6vw;
        width: 60%;
        margin: 0 auto 20px;
      }
    }
    .circle-year {
      font-size: 2.7rem;
      line-height: 1.5;
      span {
        font-size: 1.6rem;
      }
      @include media(sp) {
        font-size: 4.6vw;
        span {
          font-size: 3.4vw;
        }
      }
    }
    .circle-txt {
      font-size: 3.3rem;
      line-height: 1;
      span {
        color: $c-aka;
        &:nth-child(1) {
          font-size: 4.4rem;
          position: relative;
          top: 3px;
        }
        &:nth-child(2) {
          font-size: 2.5rem;
        }
      }
      @include media(sp) {
        font-size: 5.8vw;
        span {
          &:nth-child(1) {
            font-size: 8vw;
            top: 3px;
            margin-left: 4px;
          }
          &:nth-child(2) {
            font-size: 4.6vw;
            margin-left: 4px;
          }
        }
      }
    }
    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: -150px;
      li {
        margin-bottom: 40px;
        width: 480px;
        background: #fff;
        border: 1px solid $c-gray;
        @include media(sp) {
          margin-bottom: 30px;
        }
        &:nth-last-child(2), &:last-child {
          margin-bottom: 0;
        }
        &:nth-last-child(2) {
          @include media(sp) {
            margin-bottom: 30px;
          }
        }
        a {
          padding: 30px 30px 40px;
          width: 480px;
          transition: .3s ease-in-out;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          display: block;
          &:hover {
            opacity: .7;
          }
          @include media(sp) {
            padding: 25px 25px 30px;
            width: auto;
          }
        }
        .upper-wrap {
          display: flex;
          align-items: center;
          margin-bottom: 17px;
        }
        .thum {
          width: 155px;
          margin-right: 25px;
          img {
            width: 100%;
            height: auto;
          }
          @include media(sp) {
            width: percentage(170/510);
            margin-right: 20px;
          }
        }
        .txt {
          margin-top: 50px;
          font-size: 2rem;
          font-family: $mincho;
          color: $c-ao;
          @include media(sp) {
            flex: 1;
            margin-top: 0;
            font-size: 1.3rem;
          }
          .pass-name {
            display: none;
            @include media(sp) {
              display: block;
              color: #000;
              margin-top: 6px;
              font-family: $base-font-family;
            }
          }
        }
        .name {
          // padding-bottom: 10px;
          margin-bottom: 10px;
          // border-bottom: 1px solid $c-gray;
          // margin-bottom: 23px;
          color: #000;
          @include media(sp) {
            display: none;
          }
        }
        .desc {
          padding-top: 23px;
          border-top: 1px solid $c-gray;
          line-height: 1.67;
          color: #000;
          @include media(sp) {
            font-size: 1.4rem;
            padding-top: 16px;
          }
        }
      }
    }
    .btn-more {
      text-align: right;
      margin-top: 34px;
      @include media(sp) {
        margin-top: 20px;
      }
      a {
        font-family: $mincho;
        font-size: 1.9rem;
        color: $c-ao;
        background: url('/img/common/icn_arrow_02_blue.svg') right 1px / 9px no-repeat;
        padding-right: 28px;
        @include media(sp) {
          font-size: 1.4rem;
          background: url('/img/common/icn_arrow_02_blue.svg') right 1px / 6px no-repeat;
          padding-right: 13px;
        }
      }
    }
    > div {
      &:nth-child(2) {
        position: relative;
        z-index: 1;
        margin-top: 39px;
      }
    }
  }

  // ======================
  // SEC05
  // ======================
  .sec05 {
    padding: 108px 0 50px;
    background: #f0f0f0;
    @include media(sp) {
      padding: 48px 0 31px;
      margin-bottom: 60px;
    }
    .sec05-head {
      text-align: center;
      font-size: 3.2rem;
      color: $c-ao;
      font-family: $mincho;
      margin-bottom: 75px;
      font-weight: normal;
      @include media(sp) {
        font-size: 5.6vw;
        margin-bottom: 45px;
      }
    }

    .tabs {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $c-usui;
      @include media(sp) {
        justify-content: flex-start;
        &.inner {
          padding: 0;
        }
      }
      > div {
        width: percentage(360/740);
        padding: 26px;
        border: 1px solid $c-gray;
        padding: 30px;
        text-align: center;
        margin-bottom: -1px;
        background: #f0f0f0;
        font-family: $mincho;
        font-size: 2rem;
        color: #909090;
        cursor: pointer;
        margin-left: 19px;
        &.active {
          border-bottom: 1px solid #fff;
          background: #fff;
          color: #000;
        }
        &:nth-child(1) {
          margin-left: 0;
        }
        &:not(.active) {
          transition: .3s ease-in-out;
          &:hover {
            opacity: .7;
          }
        }
        @include media(sp) {
          width: auto;
          margin-left: 0;
          padding: 14px 15px;
          font-size: 1.4rem;
          &:nth-child(1) {
            flex: .4;
          }
          &:nth-child(2) {
            border-left: 0;
            flex: .6;
          }
          &:nth-child(3) {
            border-left: 0;
          }
        }
      }
    }
    .tabs-contents {
      background: #fff;
      padding: 75px 0 82px;
      @include media(sp) {
        padding: 35px 0 45px;
      }
      > div {
        padding: 0px 15px;
      }
      > div > div {
        display: none;
        &.active {
          display: block;
          animation: fadeIn .5s ease 0s 1 normal;
        }
        li {
          display: flex;
          padding: 30px 0 7px;
          border-bottom: 1px solid $c-gray;
          &:nth-child(1) {
            padding-top: 0;
          }
          @include media(sp) {
            flex-wrap: wrap;
            justify-content: space-between;
          }
          .date {
            margin-right: 14px;
            font-family: $mincho;
            padding: 3px;
            @include media(sp) {
              order: 1;
              font-size: 1.4rem;
              margin-right: 0;
            }
          }
          .tag {
            margin-right: 20px;
            width: 90px;
            span {
              width: 100%;
            }
            @include media(sp) {
              order: 0;
              font-size: 1.4rem;
              margin-right: 0;
            }
          }
          .desc {
            padding: 3px;
            flex: 1;
            a {
              color: #000;
            }
            @include media(sp) {
              flex: none;
              order: 2;
              width: 100%;
              font-size: 1.4rem;
              margin-top: 4px;
            }
          }
        }
      }
    }

    .btn-more {
      text-align: right;
      margin-top: 23px;
      @include media(sp) {
        margin-top: 20px;
      }
      a {
        font-family: $mincho;
        font-size: 1.9rem;
        color: #000;
        background: url('/img/common/icn_arrow_02_blue.svg') right 1px / 9px no-repeat;
        padding-right: 28px;
        @include media(sp) {
          font-size: 1.4rem;
          background: url('/img/common/icn_arrow_02_blue.svg') right 1px / 6px no-repeat;
          padding-right: 13px;
        }
      }
    }
  }
}
