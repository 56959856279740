@charset "UTF-8";

body {
  border: 20px solid $c-ao;
  position: relative;
  @include media(sp) {
    // border: 10px solid $c-ao;
    padding: 10px;
    border: 0;
  }
}
[class^="bars-"] {
  position: fixed;
  background: $c-ao;
  z-index: 2;
}
[class^="bars-row-"] {
  width: 20px;
  height: 100%;
  @include media(sp) {
    width: 10px;
  }
}
[class^="bars-line-"] {
  width: 100%;
  height: 20px;
  @include media(sp) {
    height: 10px;
  }
}
.bars-line-top {
  top: 0;
  left: 0;
}
.bars-line-bottom {
  bottom: 0;
  left: 0;
}
.bars-row-right {
  top: 0;
  right: 0;
}
.bars-row-left {
  top: 0;
  left: 0;
}

.breadcrumb {
  ol {
    display: flex;
    justify-content: flex-end;
    @include media(sp) {
      flex-wrap: wrap;
    }
  }
}