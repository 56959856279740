@charset "UTF-8";
.pg-course, [class^="pg-course"] {
  main {
    h1.page-title {
      margin: 0 auto 20px;
      max-width: 1000px;
    }
    .course-content {
      padding-top: 20px;
      border-top: 1px $c-gray solid;
      p.page-desc {
        margin-bottom: 40px;
      }
      .course-list {
        display: flex;
        display: -webkit-flex;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
      }
      .course-list-box {
        margin-right: 40px;
        padding: 20px 20px 35px;
        border: 1px #d2d2d2 solid;
        width: calc((100% - 80px) / 3);
        box-sizing: border-box;
        background: #f6f6f6;
        img {
          width: 100%;
        }
        h2 {
          padding: 20px 0;
          line-height: 3rem;
          font-family: $mincho;
          font-size: 2.4rem;
          color: $c-ao;
          text-align: center;
        }
        a.btn {
          margin: 0 auto;
          width: 180px;
          height: 45px;
          line-height: 45px;
          background: $c-ao;
          color: #fff;
          font-family: $mincho;
          font-size: 1.6rem;
          display: block;
          position: relative;
          text-align: center;
          &:after {
            position: absolute;
            right: 10px;
            content: "＞";
            transform: scale( 0.6, 1);
          }
        }
        &:nth-child(3) {
          margin-right: 0;
        }
        &:nth-child(4), &:nth-child(5) {
          margin-top: 40px;
          width: calc((100% - 40px) / 2);
        }
        &:last-child {
          margin-right: 0;
        }
      }
      @include media(sp) {
        p.page-desc {
          margin-bottom: 30px;
          font-size: 1.4rem;
        }
        .course-list {
          display: block;
        }
        .course-list-box {
          margin: 0 0 20px;
          padding: 15px 15px 20px;
          width: 100%;
          h2 {
            padding: 15px 0;
            font-size: 1.8rem;
          }
          span.is-sp {
            display: inline-block;
          }
          a.btn {
            margin: 0 auto;
            width: 100%;
          }
          &:nth-child(4), &:nth-child(5) {
            margin-top: 0;
            width: 100%;
          }
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}

[class^="pg-course"] {
  main {
    font-size: 1.5rem;
    ul.desc {
      margin-bottom: 20px;
      li {
        margin-bottom: 5px;
        padding-left: 1em;
        text-indent: -1em;
        span {
          font-weight: 800;
        }
      }
    }
    p.desc-kome {
      margin-top: 5px;
    }
    section.timetable {
      margin: 50px auto 0;
      max-width: 1000px;
      h2 {
        margin-bottom: 10px;
        font-family: $mincho;
        font-size: 2.5rem;
      }
      p.stress {
        font-size: 1.8rem;
        font-weight: 800;
      }
      .ofsrwrap {
        margin: 0;
        width: 100%;
        max-width: 1000px;
        overflow-x: auto;
      }
      table {
        margin-top: 20px;
        width: 1000px;
        &.last {
          margin-top: 60px;
        }
        caption {
          width: 445px;
          height: 50px;
          line-height: 50px;
          background: $c-ao;
          color: #fff;
          text-align: center;
          font-size: 1.8rem;
          font-weight: 800;
        }
        th, td {
          border: 1px #d5d5d5 solid;
          height: 70px;
          vertical-align: middle;
          text-align: center;
          font-size: 1.5rem;
          font-weight: 800;
        }
        tr:nth-child(odd) {
          th, td {
            background: #f5f5f5;
          }
        }
        tr th {
          width: calc(775px / 7);
        }
        tr th:first-child {
          width: 75px;
        }
        tr th:nth-child(2) {
          width: 150px;
        }
        tr:first-child {
          height: 60px;
          th {
            color: $c-ao;
            font-size: 1.6rem;
            background: #fff;
          }
        }
      }
      .mousedowna, .mousedownb {
        display: none;
      }
    }
    .contact {
      margin: 80px auto 0;
      max-width: 1000px;
      position: relative;
      .ti {
        padding: 25px 0 30px;
        border: 1px $c-gray solid;
        border-right: none;
        border-left: none;
        position: relative;
        p {
          font-weight: 800;
          text-align: center;
        }
        p:first-child {
          margin-bottom: 10px;
          font-size: 3rem;
          color: $c-aka;
        }
        p:last-child {
          font-size: 1.8rem;
        }
        &:before {
          margin-left: -15px;
          content: "";
          position: absolute;
          bottom: -15px;
          left: 50%;
          display: block;
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 15px 15px 0 15px;
          border-color: $c-gray transparent transparent transparent;
          z-index: 0;
        }
        &:after {
          margin-left: -15px;
          content: "";
          position: absolute;
          bottom: -14px;
          left: 50%;
          display: block;
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 15px 15px 0 15px;
          border-color: #fff transparent transparent transparent;
        }
      }
      a {
        width: 600px;
        height: 85px;
        line-height: 85px;
        background: $c-ao;
        color: #fff;
        font-family: $mincho;
        font-size: 2.5rem;
        display: block;
        position: relative;
        text-align: center;
        &:after {
          position: absolute;
          right: 10px;
          content: "＞";
          transform: scale( 0.6, 1);
        }
      }
      a.contact {
        margin: 40px auto 25px;
        background: $c-aka;
      }
      a.flow {
        margin: 0 auto 100px;
      }
    }
    @include media(sp) {
      font-size: 14px;
      p.desc-kome {
        padding-left: 1em;
        text-indent: -1em;
      }
      section.timetable {
        margin: 30px auto 0;
        p.stress {
          margin-top: 20px;
        }
        h2 {
          margin-bottom: 0;
          padding: 0 0 10px 25px;
          border-bottom: 1px $c-gray solid;
          font-size: 2.3rem;
        }
        p.stress {
          font-size: 1.5rem;
        }
        .ofsrbase {
          padding: 0 0 25px 25px;
          border-bottom: 1px $c-gray solid;
        }
        .ofsrbase:last-child {
          margin-bottom: 25px;
          border-bottom: 1px $c-gray solid;
          &:last-child table {
            margin-top: 25px;
          }
        }
        .ofsrwrap {
          margin: 0;
          padding: 0;
          max-width: 875px;
        }
        table {
          margin: 25px 0 0;
          max-width: 875px;
          caption {
            width: 420px;
            height: 45px;
            line-height: 45px;
            background: $c-ao;
            color: #fff;
            text-align: center;
            font-size: 1.6rem;
            font-weight: 800;
          }
          th, td {
            height: 50px;
            font-size: 1.4rem;
            font-weight: 800;
          }
          tr th {
            width: calc((875px - 140px) / 7);
          }
          tr th:first-child {
            width: 50px;
          }
          tr th:nth-child(2) {
            width: 100px;
          }
          tr:first-child {
            height: 40px;
            th {
              font-size: 1.5rem;
            }
          }
        }
        .mousedowna, .mousedownb {
          display: block;
          font-size: 0;
          opacity: 1 !important;
        }
        .mousedowna:after,
        .mousedownb:after {
          position: absolute;
          content: '';
          width: 20px;
          height: 20px;
          border: 0px;
          border-top: solid 2px $c-ao;
          border-right: solid 2px $c-ao;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
          top: 10px;
          left: 15px;
        }
        .mousedownb:after {
          left: -35px;
        }
      }
      section.sml {
        .mousedowna:after,
        .mousedownb:after {
          left: 0px;
        }
        .mousedownb:after {
          left: -15px;
        }
      }
      .contact {
        margin-top: 25px;
        .ti {
          padding: 25px 0;
          p {
            font-weight: 800;
            text-align: center;
          }
          p:first-child {
            margin-bottom: 10px;
            font-size: 1.9rem;
            color: $c-aka;
          }
          p:last-child {
            font-size: 1.4rem;
          }
          &:before {
            margin-left: -10px;
            bottom: -10px;
            left: 50%;
            border-width: 10px 10px 0 10px;
          }
          &:after {
            margin-left: -10px;
            bottom: -9px;
            left: 50%;
            border-width: 10px 10px 0 10px;
          }
        }
        a {
          width: 100%;
          height: 55px;
          line-height: 55px;
          font-size: 1.5rem;
        }
        a.contact {
          margin: 30px auto 15px;
          background: $c-aka;
        }
        a.flow {
          margin: 0 auto 60px;
        }
      }
    }
  }
}
