@charset "UTF-8";

.footer-contact {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 1;
  @include media(sp) {
    display: none;
  }
  p {
    a {
      display: flex;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      justify-content: center;
      align-items: center
    }
    &:nth-child(1) {
      a {
        background: #1c2237;
        border: 3px solid #485581;
        img {
          margin-top: -6px;
          width: 34px;
        }
      }
    }
    &:nth-child(2) {
      margin-top: 10px;
      a {
        background: #162867;
        border: 3px solid #2c4498;
      }
    }
  }
}

.l-footer {
  padding: 80px 0 56px;
  position: relative;
  @include media(sp) {
    padding: 36px 0 56px;
    background: #f5f5f5;
  }
  .pagetotop {
    display: none;
    @include media(sp) {
      display: block;
      position: absolute;
      top: -36px;
      left: 0;
      right: 0;
      a {
        width: 40px;
        display: block;
        margin: 0 auto;
        line-height: 1;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .footer-contents-01 {
    display: flex;
    margin-top: 3px;
    @include media(sp) {
      display: block;
      margin-top: 0;
    }
    .logo {
      margin-bottom: 6px;
      @include media(sp) {
        width: 230px;
        margin-bottom: 8px;
      }
    }
    .fc-01-01 {
      margin-right: 48px;
      width: 262px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .fc-01-02 {
      flex: 1;
      width: 675px;
      font-size: 1.5rem;
      @include media(sp) {
        width: 100%;
      }
      ul.list01 {
        width: 240px;
        float: left;
        li:first-child {
          margin-bottom: 30px;
        }
        ul li:first-child {
          margin-bottom: 0;
        }
        @include media(sp) {
          width: 100%;
          float: none;
        }
      }
      ul.list02 {
        width: 415px;
        float: right;
        display: flex;
        display: -webkit-flex;
        @include media(sp) {
          display: block;
          width: 100%;
          float: none;
        }
        li.result {
          margin: 0 auto 0 0;
          width: 220px;
        }
        li.teacher {
          width: 160px;
        }
        @include media(sp) {
          li.result, li.teacher, li.is-sp {
            margin-top: 30px;
            width: 100%;
          }
        }
        ul {
          li {
            @include media(sp) {
              margin-left: 0;
              &:nth-child(1) {
                margin-top: 0;
              }
            }
          }
        }
      }
      ul ul {
        > li {
          margin-top: 7px;
          position: relative;
          padding-left: 20px;
          height: 24px;
          line-height: 24px;
          @include media(sp) {
            font-size: 1.4rem;
            margin-top: 8px;
          }
          &::before {
            content: '';
            position: absolute;
            width: 11px;
            height: 11px;
            top: 7px;
            left: 0;
            border-radius: 50%;
            background: #c8c8c8;
            @include media(sp) {
              top: 5px;
            }
          }
          &:nth-child(1) {
            margin-top: 0;
          }
          a {
            color: inherit;
          }
        }
      }
      .list01-head--contents {
        margin-top: 30px;
      }
      @media all and (max-width: 1020px) {
        width: 100%;
        ul, ul.list01, ul.list02 {
          width: 100%;
        }
        ul.list02 {
          display: block;
        }
        ul.list02 li.result, ul.list02 li.teacher {
          margin-top: 30px;
          width: 100%;
        }
      }
    }
    .address {
      font-size: 1.4rem;
      font-family: $mincho;
      margin-bottom: 12px;
      span {
        font-size: 1.6rem;
      }
      @include media(sp) {
        font-size: 1.3rem;
        margin-bottom: 17px;
        span {
          font-size: 1.4rem;
          margin-left: 6px;
        }
      }
    }
    .tel,
    .fax {
      display: block;
      font-family: $q-min;
      color: $c-ao;
      font-size: 1.6rem;
      margin-bottom: 6px;
      a,
      span {
        font-size: 2.4rem;
        margin-left: 8px;
        display: inline-block;
        color: $c-ao;
        font-family: $q-min;
      }
      @include media(sp) {
        display: none;
      }
    }
    .tel {
      a {
        cursor: text;
      }
    }
    .fax {
      span {

      }
    }
    .receptiontime {
      display: block;
      font-family: $q-min;
      color: $c-ao;
      font-size: 1.2rem;
      span {
        font-size: 1.5rem;
        margin-left: 8px;
        display: inline-block;
      }
      @include media(sp) {
        display: none;
      }
    }
  }

  .footer-contents-02 {
    text-align: right;
    padding: 37px 0 17px;
    a {
      color: inherit;
      font-size: 1.4rem;
    }
    @include media(sp) {
      display: none;
    }
  }

  .footer-contents-03 {
    padding-top: 15px;
    border-top: 1px solid $c-gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media(sp) {
      display: block;
      padding-top: 0;
      margin: 30px -30px 0;
    }
    ul {
      display: flex;
      align-items: center;
      @include media(sp) {
        flex-wrap: wrap;
      }
      li {
        margin-left: 10px;
        position: relative;
        font-family: $font02;
        font-size: 1.3rem;
        &:after {
          content: '/';
          margin-left: 10px;
          color: #333;
          @include media(sp) {
            content: normal;
          }
        }
        &:nth-child(1) {
          margin-left: 0;
        }
        &:nth-child(5) {
          &:after {
            content: none;
          }
        }
        &:last-child {
          @include media(sp) {
            display: inline-block;
            display: block;
            width: 100%;
          }
        }
        a {
          color: #333;
          @include media(sp) {
            font-size: 1.4rem;
            padding: 11px;
            display: block;
            height: 42px;
          }
        }
        @include media(sp) {
          width: 50%;
          margin-left: 0;
          border-bottom: 1px solid $c-gray;
          text-align: center;
          &:nth-child(2n) {
            border-left: 1px solid $c-gray;
          }
          &:last-child {
            @include media(sp) {
              a {
                padding: 15px 11px;
              }
            }
          }
        }
      }
    }
  }

  .copy {
    font-size: 1.2rem;
    @include media(sp) {
      text-align: center;
      margin-top: 14px;
    }
  }
}

// .site-official {
//   .footer {
//     margin-top: 230px;
//     background: #ebebeb;
//     @include media(sp) {
//       margin-top: percentage(240/640);
//     }

//     > .inner {
//       position: relative;
//     }

//     .insta-account {
//       text-align: center;
//       margin-top: 30px;
//       a {
//         color: #fff;
//         font-family: $font02;
//         font-size: 1.5rem;
//       }
//     }

//     .instagram-wrap {
//       background: #000;
//       padding: 50px 0 40px;
//     }
//     .instagram-list {
//       ul {
//         display: flex;
//         justify-content: space-between;
//         li {
//           width: 100px;
//           height: 100px;
//           overflow: hidden;
//           img {
//             width: 100%;
//             height: auto;
//           }
//         }
//         @include media(sp) {
//           flex-wrap: wrap;
//           justify-content: flex-start;
//           width: percentage(580/650);
//           margin: 0 auto;
//           li {
//             width: percentage(130/580);
//             height: auto;
//             margin-left: percentage(20/580);
//             &:nth-child(1),
//             &:nth-child(2),
//             &:nth-child(3),
//             &:nth-child(4) {
//               margin-bottom: percentage(20/580);
//             }
//             &:nth-child(1),
//             &:nth-child(5) {
//               margin-left: 0;
//             }
//           }
//         }
//       }
//     }

//     .footer-contents-02 {
//       padding: 50px 0 36px;
//       background: #323232;
//       @include media(sp) {
//         padding: 45px 0 36px;
//       }

//       .footer-contents-02-01 {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         margin-bottom: 22px;
//         ul {
//           display: flex;
//           align-items: center;
//           li {
//             margin-left: 20px;
//             img {
//               width: 100%;
//               height: auto;
//             }
//             &:nth-child(1) {
//               width: 26px;
//               margin-left: 0;
//             }
//             &:nth-child(2) {
//               width: 23px;
//             }
//             &:nth-child(3) {
//               width: 12px;
//             }
//           }
//         }
//         @include media(sp) {
//           ul {
//             justify-content: flex-end;
//             li {
//               margin-left: 10.8%;
//               &:nth-child(1) {
//                 width: 23.1%;
//               }
//               &:nth-child(2) {
//                 width: 20%;
//               }
//               &:nth-child(3) {
//                 width: 10%;
//               }
//             }
//           }
//         }
//       }

//       .footer-logo {
//         width: 186px;
//         img {
//           width: 100%;
//           height: auto;
//         }
//         @include media(sp) {
//           width: 156px;
//           img {
//             vertical-align: middle;
//           }
//         }
//       }

//       .copy {
//         color: #fff;
//         font-size: 1.2rem;
//       }

//       .footer-menu-list {
//         display: flex;
//         align-items: center;
//         flex-wrap: wrap;
//         margin: 0 auto 43px;
//         @include media(sp) {
//           margin: 0 0 22px -10px;
//         }
//         li {
//           margin-left: 10px;
//           position: relative;
//           font-family: $font02;
//           font-size: 1.7rem;
//           margin-bottom: 11px;
//           @include media(sp) {
//             // margin-left: 0;
//           }
//           &:after {
//             content: '/';
//             margin-left: 10px;
//             color: #fff;
//           }
//           &:last-child {
//             &:after {
//               content: none;
//             }
//             @include media(sp) {

//             }
//           }
//           a {
//             color: #fff;
//             @include media(sp) {
//               font-size: 1.4rem;
//             }
//           }
//           @include media(sp) {

//           }
//         }
//       }
//     }

//     .pagetotop {
//       position: absolute;
//       top: 0;
//       right: 0;
//       margin-top: -46px;
//       @include media(sp) {
//         left: 0;
//         margin: -10% auto 0;
//         text-align: center;
//       }
//       a {
//         color: inherit;
//         position: relative;
//         font-family: $font02;
//         &:after {
//           content: '';
//           position: absolute;
//           background: url(/img/common/icn_arrow_04.svg) left top / 66px no-repeat;
//           width: 66px;
//           height: 19px;
//           top: 0;
//           left: 0;
//           right: 0;
//           margin: -26px auto 0;
//         }
//       }
//     }

//   }
// }
