@charset "UTF-8";
body.pg-campaign20180701 main {
  position: relative;
}

body.pg-campaign20180701,
body.pg-campaign20180701_conf {
  padding: 0;
  border: none;
  line-height: 1.5;
  position: static;
  color: #000;
  font-feature-settings: "palt";
  $yellow: #ffff00;
  $red: #f00000;
  $orange: #d64317;
  $blue: #152045;
  span.ul {
    color: $red;
    background: linear-gradient(transparent 80%, $yellow 80%);
  }
  @include media(sp) {
    .inner {
      padding: 0;
    }
  }
  .is-pc {
    @media all and (max-width: 999px) {
      display: none;
    }
  }
  .is-sp {
    display: none;
    @media all and (max-width: 999px) {
      display: block;
    }
  }
  // header
  header {
    padding: 50px 0;
    border-bottom: 2px #e5e5e5 solid;
    background: #f5f5f5;
    overflow: hidden;
    line-height: 1;
    .inner {
      width: 1000px;
    }
    .logo-wrap {
      margin: 10px 30px 0 0;
      float: left;
      width: 294px;
      img {
        width: 100%;
      }
    }
    .tel-wrap {
      margin-top: 10px;
      float: left;
      span.is-pc {
        line-height: 1.2;
        font-size: 16px;
        font-weight: bold;
      }
      .tel-num {
        float: left;
        font-size: 14px;
        font-family: $q-min;
        color: $orange;
        a {
          margin-left: 5px;
          font-size: 36px;
          color: $orange;
          letter-spacing: 1px;
        }
      }
      .tel-time {
        margin-left: 10px;
        float: right;
        line-height: 1.2;
        font-size: 13px;
        font-family: $q-min;
        span {
          font-size: 16px;
        }
      }
    }
    .contact-wrap {
      float: right;
      width: 300px;
      a {
        padding-left: 15px;
        width: 285px;
        height: 65px;
        line-height: 65px;
        background: $orange;
        color: #fff;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        position: relative;
        &:before {
          position: absolute;
          content: "";
          height: 0;
          position: absolute;
          width: 0;
          border: 5px solid transparent;
          border-left-color: $yellow;
          top: 27px;
          left: 20px;
        }
        .btn {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 52%;
          transform: translate(-50%,-50%);
          line-height: 1.3;
          font-size: 16px;
          .doc {
            font-size: 14px;
          }
        }
      }
    }
    @media all and (max-width: 999px) {
      padding: 10px 25px;
      @media all and (max-width: 374px) {
        padding: 10px 10px;
      }
      .inner {
        padding: 0;
        width: 100%;
      }
      .logo-wrap {
        margin: 0;
        float: left;
        width: 162px;
      }
      .tel-wrap {
        margin: 0;
        float: right;
        text-align: right;
        .tel-time {
          float: none;
          font-size: 10px;
          span {
            font-size: 10px;
          }
        }
        .tel-num {
          float: none;
          font-size: 10px;
          a {
            font-size: 17px;
          }
        }
      }
      .contact-wrap {
        display: none;
      }
    }
  }
  // cv
  .campaign-cv {
    .campaign-cv-top {
      padding: 30px 0;
      background: #fff;
      text-align: center;
      img {
        width: 390px;
      }
    }
    .campaign-cv-middle {
      padding: 70px 0;
      background: url('/img/campaign/20180701/bg_dot.png') repeat;
      background-size: 6px;
      text-align: center;
      p:first-child {
        margin-bottom: 10px;
        font-size: 28px;
        font-weight: bold;
        color: #fff;
      }
      p:nth-child(2) {
        margin: 0 auto 25px;
        width: 690px;
        color: $yellow;
        font-size: 50px;
        font-weight: bold;
        position: relative;
        &::before, &::after{
          content: '';
          position: absolute;
          top: 50%;
          display: inline-block;
          width: 55px;
          height: 1px;
          background-color: $yellow;
          -moz-transform: rotate(65deg);
          -webkit-transform: rotate(65deg);
          -ms-transform: rotate(65deg);
          transform: rotate(65deg);
        }
        &::before {
          box-shadow: 5px -10px 0 $yellow;
          left:0;
        }
        &::after {
          -moz-transform: rotate(-65deg);
          -webkit-transform: rotate(-65deg);
          -ms-transform: rotate(-65deg);
          transform: rotate(-65deg);
          left: auto;
          box-shadow: 5px 10px 0 $yellow;
        }
      }
      a {
        margin: 0 auto;
        display: block;
        width: 740px;
        height: 100px;
        line-height: 100px;
        border-radius: 50px;
        text-shadow: 0 2px #ffffca;
        font-size: 34px;
        font-weight: bold;
        color: $red;
        text-align: center;
        background: url('/img/campaign/20180701/cv_arrow.png') no-repeat top 40px right 40px, $yellow;
        background-size: 44px;
        box-shadow: 0 4px 0px 0px rgba(0, 0, 0, 0.35);
        position: relative;
        .btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          width: 100%;
          line-height: 1.3;
          .doc {
            font-size: 28px;
          }
        }
      }
    }
    .campaign-cv-bottom {
      padding: 35px 0 30px;
      background: #313131;
      color: #fff;
      text-align: center;
      p:first-child {
        font-size: 28px;
        font-weight: bold;
      }
      a {
        font-size: 40px;
        font-family: $q-min;
        color: #fff;
        span {
          position: relative;
          &::before {
            width: 27px;
            height: 34px;
            position: absolute;
            content: "";
            background: url('/img/campaign/20180701/cv_tel.png') no-repeat;
            background-size: 27px;
            left: -38px;
            top: 6px;
          }
        }
      }
      p:last-child {
        font-size: 18px;
      }
    }
    @media all and (max-width: 999px) {
      .campaign-cv-top {
        img {
          width: 240px;
        }
      }
      .campaign-cv-middle {
        padding: 30px 0;
        p:first-child {
          margin-bottom: 10px;
          font-size: 14px;
        }
        p:nth-child(2) {
          margin: 0 auto 20px;
          width: 280px;
          font-size: 20px;
          &::before, &::after{
            width: 30px;
          }
          &::before {
            box-shadow: 5px -6px 0 $yellow;
          }
          &::after {
            box-shadow: 5px 6px 0 $yellow;
          }
        }
        a {
          padding-top: 15px;
          width: 80%;
          height: 80px;
          border-radius: 40px;
          line-height: 1.3;
          font-size: 20px;
          background: url('/img/campaign/20180701/sp/cv_arrow.png') no-repeat top 35px right 25px, $yellow;
          background-size: 17px auto;
          .btn {
            font-size: 16px;
            .doc {
              font-size: 15px;
            }
          }
        }
      }
      .campaign-cv-bottom {
        padding: 30px 0 25px;
        p:first-child {
          font-size: 18px;
        }
        a {
          font-size: 30px;
          span {
            position: relative;
            &::before {
              width: 20px;
              height: 25px;
              background: url('/img/campaign/20180701/cv_tel.png') no-repeat;
              background-size: 20px auto;
              left: -24px;
              top: 5px;
            }
          }
        }
        p:last-child {
          font-size: 14px;
        }
      }
    }
  }
  // お正月限定mv
  .mv-ny {
    width: 100%;
    position: relative;
    .mv-ny-circle {
      margin-left: 195px;
      padding-top: 70px;
      width: 343px;
      height: 343px;
      border-radius: 50%;
      background: $red;
      color: #fff;
      box-sizing: border-box;
      position: absolute;
      top: 25px;
      left: 50%;
      text-align: center;
      @media all and (min-width: 768px) and (max-width: 1100px) {
        margin-left: 0;
        right: 0;
        left: auto;
      }
      span {
        line-height: 1.5;
        font-weight: bold;
        &.l1 {
          font-size: 21px;
        }
        &.l2 {
          line-height: 1.2;
          font-size: 40px;
          color: $yellow;
          span.sm1 {
            font-size: 30px;
          }
          span.sm2 {
            font-size: 35px;
          }
        }
        &.l3 {
          font-size: 17px;
        }
      }
    }
    .ph {
      width: 100%;
      height: 561px;
      background: #fff;
      overflow-x: hidden;
      .ph-inner {
        width: 100%;
        height: 561px;
        background-size: 1373px 561px;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    img.date {
      margin-left: -105px;
      width: 211px;
      position: absolute;
      top: 550px;
      left: 50%;
      display: block;
    }
    .mv-grid {
      margin-bottom: 40px;
      padding-bottom: 50px;
      width: 100%;
      background: url("/img/campaign/20180701/bg_grid.png");
      background-size: 4px auto;
    }
    h1 {
      margin: 30px 0 20px;
      text-align: center;
      font-size: 50px;
      font-family: $mincho;
      span {
        color: $red;
        font-weight: bold;
      }
    }
    p {
      line-height: 1.6;
      font-size: 25px;
      font-weight: bold;
      text-align: center;
      span.y {
        span {
          color: $red;
          font-size: 33px;
        }
      }
    }
    @media all and (max-width: 999px) {
      .mv-ny-circle {
        margin-left: -139px;
        padding-top: 55px;
        width: 278px;
        height: 278px;
        position: absolute;
        left: 50%;
        top: -230px;
        z-index: -1;
        span {
          &.l1 {
            font-size: 17px;
          }
          &.l2 {
            line-height: 1.2;
            font-size: 34px;
            color: $yellow;
            span.sm1 {
              font-size: 24px;
            }
            span.sm2 {
              font-size: 28px;
            }
          }
          &.l3 {
            font-size: 14px;
          }
        }
      }
      .ph {
        width: 100%;
        height: auto;
        background: transparent;
        img {
          width: 100%;
        }
      }
      img.date {
        display: none;
      }
      .ph-block {
        padding-top: 110px;
        width: 100%;
        position: relative;
        @media all and (max-width: 360px) {
          padding-top: 90px;
        }
      }
      img.ti {
        margin-left: -175px;
        width: 350px;
        display: block;
        position: absolute;
        top: -50px;
        left: 50%;
        @media all and (max-width: 360px) {
          margin-left: 0;
          width: 90%;
          left: 5%
        }
      }
      .mv-grid {
        margin: 200px 0 0;
        padding-bottom: 30px;
        z-index: -2;
        position: relative;
      }
      h1 {
        margin: 20px 0 15px;
        font-size: 25px;
      }
      p {
        line-height: 1.6;
        font-size: 15px;
        span.y {
          position: relative;
          z-index: 1;
          &:before {
            width: 150px;
            height: 6px;
            bottom: -3px;
            position: absolute;
            content: "";
            background: $yellow;
            z-index: -1;
          }
          &.l2 {
            margin-bottom: 8px;
            display: block;
          }
          &.l2::before {
            width: 220px;
            bottom: 5px;
          }
          span {
            font-size: 18px;
          }
        }
      }
    }
  }


  // mv
  .mv {
    margin-top: 46px;
    width: 100%;
    position: relative;
    .bg {
      margin-left: 40%;
      width: 60%;
      height: 600px;
      background-image: url('/img/campaign/20180701/mv.jpg');
      background-position: center;
      background-size: cover;
    }
    .inner {
      margin-left: -500px;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: 1;
    }
    h1 {
      line-height: 1.4;
      font-size: 72px;
      font-family: $mincho;
      font-weight: 500;
      writing-mode: vertical-rl;
      span.ul {
        background: linear-gradient(to right, transparent 85%, $yellow 85%);
      }
    }
    @media all and (max-width: 999px) {
      margin-top: 25px;
      width: 100%;
      .inner {
        margin-left: 25px;
        left: 0;
        width: calc(100% - 25px);
      }
      h1 {
        margin-top: 20px;
        font-size: 7vw;
      }
      img {
        float: right;
        width: 60%;
      }
    }
  }
  .mv-txt {
    position: relative;
  }
  .mv-circle {
    margin-left: -35px;
    padding-top: 72px;
    width: 562px;
    height: 562px;
    border-radius: 50%;
    background: $red;
    color: #fff;
    text-align: center;
    box-sizing: border-box;
    font-weight: bold;
    position: absolute;
    bottom: 10px;
    left: 50%;
    @media all and (max-width: 1080px) {
      margin-left: 0;
      left: auto;
      right: 0;
    }
    span.l1 {
      line-height: 1.3;
      font-size: 42px;
    }
    span.l2 {
      line-height: 1.4;
      font-size: 63px;
      color: $yellow;
      span {
        font-size: 48px;
      }
    }
    span.l3 {
      margin-top: 8px;
      display: block;
      font-size: 23px;
    }
    @media all and (max-width: 999px) {
      margin: auto;
      padding: 35%;
      width: 70%;
      height: 70%;
      line-height: 1.3;
      top: -115vw;
      left: 0;
      right: 0;
      .mv-circle-inner {
        margin: auto;
        position: absolute;
        top: 10%;
        left: 0;
        right: 0;
      }
      span.l1 {
        font-size: 6.1vw;
      }
      span.l2 {
        line-height: 1.2;
        font-size: 8.8vw;
        span {
          font-size: 6.8vw;
        }
      }
      span.l3 {
        font-size: 3.7vw;
      }
    }
    @media all and (max-width: 350px) {
      top: -135vw;
    }
  }
  .mv-desc {
    padding: 50px 0;
    background: url('/img/campaign/20180701/bg_grid.png');
    background-size: 4px;
    p {
      font-size: 25px;
      font-weight: bold;
      span {
        line-height: 1.5;
        font-size: 34px;
      }
    }
    @media all and (max-width: 999px) {
      margin-top: 60vw;
      padding: 40px 0;
      p {
        padding: 0 30px;
        font-size: 16px;
        span {
          font-size: 19px;
        }
      }
    }
  }
  // お悩み
  section.question {
    height: 1490px;
    background: url('/img/campaign/20180701/question_bg1.png') repeat-y;
    background-size: 1800px 1px;
    background-position: center;
    position: relative;
    overflow: hidden;
    h2 {
      margin: auto;
      width: 520px;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      img {
        width: 100%;
      }
    }
    .question-box {
      position: relative;
      top: 380px;
      div {
        position: absolute;
        line-height: 1.6;
        font-size: 22px;
        font-weight: bold;
      }
      .q1 {
        margin-left: -500px;
        padding: 280px 0 0 30px;
        box-sizing: border-box;
        width: 297px;
        height: 409px;
        top: 60px;
        left: 50%;
        background: url('/img/campaign/20180701/question_img_bg1.jpg');
        background-size: 297px 409px;
      }
      .q2 {
        margin-right: -500px;
        padding: 300px 0 0 65px;
        box-sizing: border-box;
        width: 285px;
        height: 437px;
        top: 0;
        right: 50%;
        background: url('/img/campaign/20180701/question_img_bg2.jpg');
        background-size: 285px 437px;
      }
      .q3 {
        margin-left: -500px;
        padding: 310px 0 0 30px;
        box-sizing: border-box;
        width: 320px;
        height: 448px;
        top: 510px;
        left: 50%;
        background: url('/img/campaign/20180701/question_img_bg3.jpg');
        background-size: 320px 448px;
      }
      .q4 {
        margin-right: -500px;
        padding: 290px 0 0 30px;
        box-sizing: border-box;
        width: 320px;
        height: 418px;
        top: 510px;
        right: 50%;
        background: url('/img/campaign/20180701/question_img_bg4.jpg');
        background-size: 320px 418px;
      }
      .q5 {
        margin-left: -160px;
        padding: 280px 0 0 30px;
        box-sizing: border-box;
        width: 320px;
        height: 409px;
        top: 240px;
        left: 50%;
        background: url('/img/campaign/20180701/question_img_bg5.jpg');
        background-size: 320px 409px;
      }
      .q6 {
        margin-left: -160px;
        padding: 295px 0 0 30px;
        box-sizing: border-box;
        width: 330px;
        height: 439px;
        top: 700px;
        left: 50%;
        background: url('/img/campaign/20180701/question_img_bg6.jpg');
        background-size: 330px 439px;
      }
    }
    @media all and (max-width: 999px) {
      height: calc(208vw + 240px);
      background: url('/img/campaign/20180701/sp/question_bg1.png') repeat-y;
      background-size: 100% 1px;
      background-position: center;
      position: relative;
      overflow: hidden;
      h2 {
        margin: 0;
        width: 226px;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 8vw;
        left: auto;
        img {
          width: 100%;
        }
      }
      .question-box {
        position: relative;
        top: 240px;
        div {
          font-size: 3.2vw;
          top: auto;
          left: auto;
          right: auto;
        }
        .q1 {
          margin: 0;
          padding: 37vw 0 0 4vw;
          box-sizing: border-box;
          width: 39.4vw;
          height: 54.9vw;
          top: 0;
          left: 8vw;
          background: url('/img/campaign/20180701/sp/question_img_bg1.jpg');
          background-size: 39.4vw 54.9vw;
        }
        .q2 {
          margin: 0;
          padding: 37.3vw 0 0 8vw;
          box-sizing: border-box;
          width: 38.4vw;
          height: 56vw;
          top: 10.6vw;
          right: 8vw;
          background: url('/img/campaign/20180701/sp/question_img_bg2.jpg');
          background-size: 38.4vw 56vw;
        }
        .q3 {
          margin: 0;
          padding: 37.3vw 0 0 2vw;
          box-sizing: border-box;
          width: 40vw;
          height: 55.8vw;
          top: 58.6vw;
          left: 10vw;
          background: url('/img/campaign/20180701/sp/question_img_bg3.jpg');
          background-size: 40vw 55.8vw;
          letter-spacing: -1px;
        }
        .q4 {
          margin: 0;
          padding: 42vw 0 0 4vw;
          box-sizing: border-box;
          width: 38.6vw;
          height: 62vw;
          top: 72vw;
          right: 8vw;
          background: url('/img/campaign/20180701/sp/question_img_bg4.jpg');
          background-size: 38.6vw 62vw;
        }
        .q5 {
          margin: 0;
          padding: 25.6vw 0 0 37.3vw;
          box-sizing: border-box;
          width: 81.3vw;
          height: 46vw;
          top: 120vw;
          left: 12vw;
          background: url('/img/campaign/20180701/sp/question_img_bg5.png');
          background-size: 81.3vw 46vw;
        }
        .q6 {
          margin: 0;
          padding: 9.8vw 0 0 6.6vw;
          box-sizing: border-box;
          width: 75vw;
          height: 35.4vw;
          top: 170.6vw;
          left: 12vw;
          background: url('/img/campaign/20180701/sp/question_img_bg6.jpg');
          background-size: 75vw 35.4vw;
        }
      }
    }
  }
  // 解決
  section.solution {
    margin-bottom: 70px;
    padding: 250px 0 200px;
    background: url('/img/campaign/20180701/question_bg2.png') no-repeat, url('/img/campaign/20180701/bg_grid.png') repeat;
    background-size: 1800px 187px, 4px 4px;
    background-position: top center;
    text-align: center;
    position: relative;
    h2 {
      margin-bottom: 20px;
      font-size: 36px;
      font-weight: bold;
      position: relative;
      z-index: 1;
      span {
        line-height: 1.3;
        font-size: 82px;
      }
    }
    p {
      padding-bottom: 50px;
      line-height: 1.75;
      font-size: 23px;
      position: relative;
      z-index: 1;
    }
    .solution-desc {
      position: relative;
      overflow: hidden;
    }
    .bg {
      margin-left: -655px;
      background: #f8f8d6;
      width: 1310px;
      height: 1310px;
      border-radius: 50%;
      position: absolute;
      top: 130px;
      left: 50%;
      z-index: 0;
    }
    .solution-teacher {
      width: 100%;
      height: 490px;
      background-image: url('/img/campaign/20180701/solution_img.jpg');
      background-position: center;
      background-size: cover;
    }
    .solution-65 {
      margin: 0 auto;
      padding-top: 80px;
      max-width: 1200px;
      font-size: 55px;
      text-align: center;
      font-weight: bold;
      span {
        display: block;
      }
      span.l1 {
        color: $red;
      }
      span.l2 {
        font-size: 98px;
        span {
          display: inline-block;
          font-size: 76px;
        }
      }
      span.l3 {
        margin-top: 5px;
        position: relative;
        display: block;
        z-index: 1;
        color: $red;
        &:before {
          width: 1040px;
          height: 9px;
          bottom: 12px;
          position: absolute;
          content: "";
          background: $yellow;
          z-index: -1;
          @media all and (min-width: 1000px) and (max-width: 1050px) {
            width: 0;
            height: 0;
          }
        }
      }
      .is-sp {
        display: none !important;
      }
    }
    @media all and (max-width: 999px) {
      margin-bottom: 50px;
      padding: 100px 0 70px;
      background: url('/img/campaign/20180701/sp/question_bg2.png') no-repeat, url('/img/campaign/20180701/bg_grid.png') repeat;
      background-size: 100% 60px, 4px 4px;
      h2 {
        font-size: 18px;
        span {
          font-size: 37px;
        }
      }
      p {
        padding: 0 30px 40px;
        font-size: 14px;
        text-align: left;
      }
      .bg {
        margin-left: -325px;
        background: #f8f8d6;
        width: 650px;
        height: 650px;
        top: 30px;
      }
      .solution-teacher {
        padding-top: 112%;
        width: 100%;
        height: 0;
        background-image: url('/img/campaign/20180701/sp/solution_img.jpg');
        background-position: center;
        background-size: cover;
      }
      .solution-65 {
        padding-top: 25px;
        width: 100%;
        line-height: 1;
        text-align: center;
        font-weight: bold;
        span.l1 {
          margin-bottom: 10px;
          font-size: 19px;
        }
        span.l2 {
          margin-bottom: 15px;
          font-size: 33px;
          span {
            font-size: 25px;
          }
          @media all and (max-width: 350px) {
            font-size: 30px;
          }
        }
        span.l3, span.l4 {
          margin-bottom: 14px;
          font-size: 28px;
          position: relative;
          display: block;
          z-index: 1;
          color: $red;
          &:before {
            width: 220px;
            height: 9px;
            bottom: -5px;
            position: absolute;
            content: "";
            background: $yellow;
            z-index: -1;
          }
        }
        span.l4:before {
          width: 310px;
        }
        .is-sp {
          display: block !important;
        }
        .is-pc {
          display: none !important;
        }
      }
    }
  }
  // 理数スタイル
  section.style {
    margin: 160px auto;
    max-width: 1000px;
    height: 986px;
    background: url('/img/campaign/20180701/style_ti_bg.png');
    background-size: 1000px 986px;
    text-align: center;
    position: relative;
    h2 {
      margin: auto;
      position: absolute;
      top: 390px;
      right: 0;
      left: 0;
      font-size: 27px;
      span {
        line-height: 1.2;
        font-size: 51px;
        span {
          font-size: 61px;
        }
      }
    }
    div {
      position: absolute;
      font-size: 28px;
      color: $orange;
      font-weight: bold;
      span {
        margin-top: 10px;
        line-height: 1.3;
        font-size: 22px;
        font-family: $mincho;
        font-weight: normal;
        color: $blue;
        display: block;
      }
    }
    .style1 {
      margin: auto;
      top: 150px;
      right: 0;
      left: 0;
    }
    .style2 {
      top: 470px;
      left: 50px;
    }
    .style3 {
      top: 470px;
      right: 50px;
    }
    .style4 {
      top: 800px;
      left: 180px;
    }
    .style5 {
      top: 800px;
      right: 175px;
    }
    @media all and (max-width: 999px) {
      margin: 0;
      padding: 60px 15px 40px ;
      border-bottom: 1px $blue solid;
      max-width: 100%;
      height: auto;
      min-height: 176vw;
      background: url('/img/campaign/20180701/bg_grid.png') repeat;
      background-size: 4px;
      h2 {
        margin: auto;
        padding-left: 20px;
        position: static;
        float: left;
        width: calc(50% - 6px);
        height: 52vw;
        font-size: 3.7vw;
        text-align: left;
        box-sizing: border-box;
        letter-spacing: -1px;
        span {
          line-height: 1.4;
          font-size: 6.6vw;
          span {
            font-size: 8vw;
          }
        }
        br.is-sp {
          display: block;
        }
      }
      div {
        padding-top: 21.3vw;
        position: static;
        float: left;
        width: calc(50% - 6px);
        height: 52vw;
        font-size: 3.73vw;
        color: $orange;
        font-weight: bold;
        box-sizing: border-box;
        &:nth-child(even) {
          float: right;
        }
        span {
          margin-top: 10px;
          line-height: 1.3;
          font-size: 2.93vw;
        }
      }
      .style1 {
        background: url('/img/campaign/20180701/sp/style_ti_bg1.png') no-repeat;
        background-size: 100%;
      }
      .style2 {
        background: url('/img/campaign/20180701/sp/style_ti_bg2.png') no-repeat;
        background-size: 100%;
      }
      .style3 {
        background: url('/img/campaign/20180701/sp/style_ti_bg3.png') no-repeat;
        background-size: 100%;
      }
      .style4 {
        background: url('/img/campaign/20180701/sp/style_ti_bg4.png') no-repeat;
        background-size: 100%;
      }
      .style5 {
        background: url('/img/campaign/20180701/sp/style_ti_bg5.png') no-repeat;
        background-size: 100%;
      }
    }
  }
  // 理数スタイル詳細
  section.style-detail {
    h2 {
      margin-top: 10px;
      line-height: 1.4;
      font-size: 42px;
      color: $orange;
      position: relative;
      box-sizing: border-box;
      background: #f3e9da;
      span {
        margin-top: 10px;
        display: block;
        font-size: 28px;
        font-family: $mincho;
        color: $blue;
      }
      img {
        margin-bottom: 15px;
        width: 150px;
        display: block;
      }
    }
    picture.img1 {
      position: absolute;
      img {
        border: 3px #fff solid;
        box-shadow: 3.857px 4.596px 0px 0px rgba(0, 0, 0, 0.15);
      }
    }
    .txt1, .txt2 {
      position: absolute;
      p {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .txt1 {
      background: $yellow;
    }
    .txt2 {
      background: #d64317;
      color: #fff;
    }
    .add-up {
      padding: 110px 0 70px;
      border-bottom: 3px #d9d9d9 solid;
      background: url('/img/campaign/20180701/bg_grid2.png') repeat;
      position: relative;
      .bg {
        margin-left: -165px;
        width: 330px;
        height: 330px;
        border-radius: 50%;
        background: url('/img/campaign/20180701/bg_grid2.png') repeat;
        text-align: center;
        position: absolute;
        top: -120px;
        left: 50%;
        z-index: -1;
      }
      .ph {
        margin: 0 auto 30px;
        width: 132px;
        position: absolute;
        top: -80px;
        right: 0;
        left: 0;
        img {
          width: 132px;
        }
      }
      .txt {
        z-index: 1;
        font-size: 32px;
        text-align: center;
        font-weight: bold;
        p {
          margin-bottom: 40px;
          padding-bottom: 10px;
          border-bottom: 1px #000 solid;
          display: inline-block;
          &.is-sp {
            display: none;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    @media all and (max-width: 999px) {
      h2 {
        margin-top: 10px;
        line-height: 1.4;
        font-size: 21px;
        span {
          font-size: 14px;
        }
        img {
          margin-bottom: 15px;
          width: 74px;
        }
      }
      .add-up {
        padding: 70px 0 40px;
        border-bottom: 3px #d9d9d9 solid;
        background: url('/img/campaign/20180701/bg_grid2.png') repeat;
        background-size: 4px;
        position: relative;
        .bg {
          margin-left: -80px;
          width: 160px;
          height: 160px;
          background: url('/img/campaign/20180701/bg_grid2.png') repeat;
          background-size: 4px;
          top: -60px;
        }
        .ph {
          margin: 0 auto 30px;
          width: 65px;
          position: absolute;
          top: -30px;
          right: 0;
          left: 0;
          img {
            width: 65px;
          }
        }
        .txt {
          padding: 0 25px;
          font-size: 16px;
          box-sizing: border-box;
          p {
            margin-bottom: 5px;
            padding-bottom: 5px;
            &.is-pc {
              display: none;
            }
            &.is-sp {
              display: inline-block;
            }
          }
        }
      }
    }
  }
  // style1
  section.style1 {
    margin-bottom: 160px;
    padding-top: 20px;
    .style1-inner {
      margin-top: 10px;
      width: 100%;
      height: 1720px;
      position: relative;
      overflow: hidden;
    }
    h2 {
      margin-left: -140px;
      padding: 100px 0 80px 180px;
      width: 640px;
      top: 0;
      left: 50%;
      &::after {
        width: 84px;
        height: 125px;
        position: absolute;
        content: "";
        background: url('/img/campaign/20180701/style1_ti_bg.png') no-repeat;
        background-size: 84px 125px;
        top: -10px;
        left: 310px;
      }
    }
    picture.img1 {
      margin-right: 30px;
      width: 814px;
      top: 100px;
      right: 50%;
      img {
        width: 814px;
      }
    }
    .txt1 {
      margin-left: -150px;
      padding: 70px 0 140px 70px;
      width: 1050px;
      top: 540px;
      left: 50%;
      font-size: 20px;
      .txt-inner {
        float: left;
        width: 590px;
      }
      span {
        font-size: 24px;
        font-weight: bold;
      }
    }
    .txt2 {
      margin-right: -80px;
      padding: 70px 70px 70px 0;
      width: 980px;
      top: 1050px;
      right: 50%;
      .txt-inner {
        float: right;
        width: 580px;
      }
      p {
        font-size: 18px;
        span {
          font-size: 24px;
          font-weight: bold;
        }
      }
      p:last-child {
        font-size: 30px;
        font-weight: bold;
        color: $yellow;
      }
    }
    .graph-box {
      margin-left: 130px;
      width: 335px;
      position: absolute;
      top: 1180px;
      left: 50%;
      img {
        margin-bottom: 20px;
        width: 100%;
        display: block;
      }
      p {
        line-height: 1.4;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        span {
          font-size: 36px;
        }
      }
    }
    .pass-box {
      font-weight: bold;
      position: relative;
      .juku {
        float: left;
        width: 620px;
        border: 1px #efefef solid;
        .t1 {
          padding: 65px 0 15px;
          font-size: 24px;
          color: #fff;
          text-align: center;
          background: url('/img/campaign/20180701/style1_ic1.png') no-repeat 50% 15px, #313131;
          background-size: 44px auto;
        }
        .t2 {
          background: #535353;
          position: relative;
          height: 45px;
          line-height: 45px;
          color: #fff;
          font-size: 20px;
          text-align: center;
          &::after {
            content: "";
            height: 0;
            position: absolute;
            width: 0;
            border: 10px solid transparent;
            border-top-color: #535353;
            top: 100%;
            left: 50%;
            margin-left: -10px;
          }
        }
        .t3 {
          font-size: 22px;
          .t3-1 {
            border-right: 1px #efefef solid;
            float: left;
            width: 50%;
            height: 115px;
            box-sizing: border-box;
            p {
              padding: 13px 0 10px 35px;
              height: 50%;
              &:last-child {
                border-top: 1px #efefef solid;
                background: #f0f0f0;
              }
            }
          }
          .t3-2 {
            padding: 25px 0 0 25px;
            float: left;
            width: 50%;
            height: 115px;
            box-sizing: border-box;
          }
        }
      }
      span.plus {
        width: 90px;
        height: 90px;
        line-height: 1;
        border-radius: 50%;
        position: absolute;
        top: 90px;
        left: 590px;
        background: $red;
        color: #fff;
        font-size: 80px;
        font-weight: bold;
        text-align: center;
      }
      .life {
        float: right;
        width: 330px;
        border: 1px #efefef solid;
        .t1 {
          padding: 65px 0 15px;
          border-bottom: 1px #efefef solid;
          font-size: 24px;
          text-align: center;
          background: url('/img/campaign/20180701/style1_ic2.png') no-repeat 50% 15px, #fdfdcb;
          background-size: 31px auto;
        }
        .t2 {
          background: #f0f000;
          position: relative;
          height: 45px;
          line-height: 45px;
          font-size: 20px;
          text-align: center;
          &::after {
            content: "";
            height: 0;
            position: absolute;
            width: 0;
            border: 10px solid transparent;
            border-top-color: #f0f000;
            top: 100%;
            left: 50%;
            margin-left: -10px;
          }
        }
        .t3 {
          font-size: 22px;
          height: 115px;
          .t3-1, .t3-2 {
            padding: 13px 0 10px 35px;
            height: 50%;
            box-sizing: border-box;
          }
          .t3-1 {
            border-right: 1px #efefef solid;
            background: #ffffea;
          }
          .t3-2 {
            border-top: 1px #efefef solid;
            background: #ededbd;
          }
        }
      }
    }
    .pass {
      margin: auto;
      border: 3px $red solid;
      border-top: none;
      position: relative;
      max-width: 1000px;
      height: 40px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      &::before, &::after {
        margin-left: -14px;
        content: "";
        height: 0;
        position: absolute;
        width: 0;
        border: 14px solid transparent;
        left: 50%;
      }
      &::before {
        border-top-color: $red;
        top: 100%;
      }
      &::after {
        border-top-color: #fff;
        top: 90%;
      }
    }
    .pass-txt {
      margin: 20px 0 180px;
      color: $red;
      font-size: 40px;
      font-weight: bold;
      text-align: center;
    }
    .add-up .txt p.l2 {
      margin-bottom: 20px;
    }
    @media all and (max-width: 999px) {
      margin-bottom: 50px;
      padding-top: 0;
      .style1-inner {
        margin-top: 10px;
        width: 100%;
        height: auto;
        min-height: 1570px;
        position: relative;
        overflow: hidden;
        @media all and (max-width: 330px) {
          min-height: 1620px;
        }
        @media all and (min-width: 450px) {
          min-height: 1530px;
        }
        @media all and (min-width: 650px) {
          min-height: 1510px;
        }
      }
      h2 {
        margin-left: -50px;
        margin-top: 30px;
        padding: 30px 0 80px 35px;
        width: 64%;
        top: 0;
        left: 50%;
        &::after {
          width: 42px;
          height: 62px;
          position: absolute;
          content: "";
          background: url('/img/campaign/20180701/style1_ti_bg.png') no-repeat;
          background-size: 42px 62px;
          top: -30px;
          left: 100px;
        }
      }
      picture.img1 {
        margin: auto;
        width: 310px;
        top: 280px;
        right: 0;
        left: 0;
        img {
          width: 100%;
        }
      }
      .txt1 {
        margin-left: 0;
        padding: 45px 30px 70px;
        width: 93%;
        top: 540px;
        left: 25px;
        font-size: 14px;
        @media all and (max-width: 330px) {
          padding: 45px 20px 70px;
        }
        .txt-inner {
          float: static;
          width: 100%;
          letter-spacing: -1px;
        }
        p {
          margin-bottom: 10px;
        }
        span {
          font-size: 16px;
          font-weight: bold;
        }
      }
      .txt2 {
        margin-left: 0;
        padding: 45px 0 50px 30px;
        width: 93%;
        top: 960px;
        left: 0;
        right: auto;
        .txt-inner {
          float: static;
          width: 100%;
        }
        p {
          margin-bottom: 10px;
          font-size: 14px;
          span {
            font-size: 16px;
            font-weight: bold;
          }
        }
        p:last-child {
          font-size: 16px;
        }
        @media all and (min-width: 450px) {
          top: 900px;
        }
        @media all and (min-width: 650px) {
          top: 880px;
        }
      }
      .graph-box {
        margin: auto;
        width: 186px;
        position: absolute;
        top: 1350px;
        left: 0;
        right: 0;
        @media all and (min-width: 325px) {
          top: 1300px;
        }
        @media all and (min-width: 450px) {
          top: 1250px;
        }
        @media all and (min-width: 640px) {
          top: 1230px;
        }
        img {
          margin-bottom: 10px;
          width: 100%;
          display: block;
        }
        p {
          font-size: 14px;
          span {
            font-size: 20px;
          }
        }
      }
      .pass-box {
        padding: 0 25px;
        .juku {
          margin-bottom: 30px;
          float: static;
          width: 100%;
          border: 1px #efefef solid;
          text-align: center;
          .t1 {
            padding: 40px 0 10px;
            font-size: 16px;
            background: url('/img/campaign/20180701/style1_ic1.png') no-repeat 50% 12px, #313131;
            background-size: 24px auto;
          }
          .t2 {
            background: #535353;
            position: relative;
            height: 34px;
            line-height: 34px;
            color: #fff;
            font-size: 14px;
            text-align: center;
            &::after {
              content: "";
              height: 0;
              position: absolute;
              width: 0;
              border: 8px solid transparent;
              border-top-color: #535353;
              top: 100%;
              left: 50%;
              margin-left: -10px;
            }
          }
          .t3 {
            width: 100%;
            font-size: 13px;
            .t3-1 {
              border-bottom: 1px #efefef solid;;
              width: 100%;
              height: 55px;
              box-sizing: border-box;
              p {
                padding: 6px 0 0;
                float: left;
                width: 50%;
                height: 55px;
                box-sizing: border-box;
                &:last-child {
                  padding: 16px 0 0;
                  border-left: 1px #efefef solid;
                  background: #f0f0f0;
                }
              }
            }
            .t3-2 {
              padding: 8px 0 0;
              width: 100%;
              height: 55px;
              box-sizing: border-box;
            }
          }
        }
        span.plus {
          margin: auto;
          width: 45px;
          height: 45px;
          line-height: 1;
          border-radius: 50%;
          position: absolute;
          top: 210px;
          left: 0;
          right: 0;
          background: $red;
          color: #fff;
          font-size: 40px;
          font-weight: bold;
          text-align: center;
        }
        .life {
          float: static;
          width: 100%;
          border: 1px #efefef solid;
          .t1 {
            padding: 40px 0 10px;
            font-size: 16px;
            background: url('/img/campaign/20180701/style1_ic2.png') no-repeat 50% 12px, #fdfdcb;
            background-size: 16px auto;
          }
          .t2 {
            background: #f0f000;
            height: 34px;
            line-height: 34px;
            font-size: 14px;
            text-align: center;
            box-shadow: 0 1px #e4e4d0;
            &::before {
              content: "";
              height: 0;
              position: absolute;
              width: 0;
              border: 8px solid transparent;
              border-top-color: #e4e4d0;
              top: 100%;
              left: 50%;
              margin-left: -9px;
            }
            &::after {
              content: "";
              height: 0;
              position: absolute;
              width: 0;
              border: 8px solid transparent;
              border-top-color: #f0f000;
              top: 95%;
              left: 50%;
              margin-left: -9px;
            }
          }
          .t3 {
            font-size: 13px;
            height: 55px;
            text-align: center;
            .t3-1, .t3-2 {
              padding: 18px 0 0;
              float: left;
              width: 50%;
              height: 55px;
              box-sizing: border-box;
            }
            .t3-2 {
              padding: 8px 0 0;
              border-top: none;
            }
          }
        }
      }
      .pass {
        margin: 10px auto 0;
        border: 2px $red solid;
        border-top: none;
        position: relative;
        width: calc(100% - 50px);
        max-width: calc(100% - 50px);
        height: 20px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-sizing: border-box;
        &::before, &::after {
          margin-left: -9px;
          content: "";
          height: 0;
          position: absolute;
          width: 0;
          border: 8px solid transparent;
          left: 50%;
        }
        &::before {
          border-top-color: $red;
          top: 100%;
        }
        &::after {
          border-top-color: #fff;
          top: 85%;
        }
      }
      .pass-txt {
        margin: 10px 0 90px;
        font-size: 25px;
      }
      .add-up .txt {
        p:nth-child(3) {
          margin-bottom: 20px;
        }
      }
    }
  }
  // style2
  section.style2 {
    margin-bottom: 150px;
    .style2-inner {
      width: 100%;
      min-height: 1880px;
      position: relative;
      overflow: hidden;
    }
    h2 {
      margin-right: -50px;
      margin-top: 35px;
      padding: 80px 0 120px;
      height: 560px;
      top: 0;
      right: 50%;
      &::after {
        width: 84px;
        height: 125px;
        position: absolute;
        content: "";
        background: url('/img/campaign/20180701/style2_ti_bg.png') no-repeat;
        background-size: 84px 125px;
        top: -35px;
        right: 280px;
      }
      .h2-inner {
        float: right;
        width: 480px;
      }
    }
    picture.img1 {
      margin-left: -35px;
      width: 884px;
      top: 80px;
      left: 50%;
      img {
        width: 884px;
      }
    }
    .txt2 {
      margin-right: -70px;
      padding: 80px 0 70px 100px;
      width: 670px;
      top: 510px;
      right: 50%;
      .txt-inner {
        float: right;
        width: 570px;
      }
      p {
        font-size: 18px;
        span {
          font-size: 24px;
          font-weight: bold;
        }
      }
      p:last-child {
        span.ylw {
          color: $yellow;
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
    .graph-box {
      margin-left: 120px;
      width: 380px;
      height: 650px;
      position: absolute;
      top: 860px;
      left: 50%;
      font-feature-settings: "pkna";
      .circle {
        margin-left: 95px;
        .circle1, .circle2, .circle3 {
          margin-bottom: 25px;
          padding-top: 60px;
          width: 200px;
          height: 200px;
          border-radius: 50%;
          background: #505050;
          color: #fff;
          text-align: center;
          box-sizing: border-box;
          p {
            font-size: 36px;
            font-weight: bold;
            span {
              font-size: 22px;
              font-family: $mincho;
              display: block;
            }
          }
        }
        .circle3 {
          margin-bottom: 0;
          background: $red;
        }
      }
      .risu, .juku {
        font-weight: bold;
        position: absolute;
        writing-mode: vertical-rl;
        p {
          padding: 20px 0;
          font-size: 18px;
          background: #fff;
          span.red {
            color: $red;
          }
        }
        span.stress {
          font-size: 28px;
        }
      }
      .risu {
        width: 60px;
        height: 650px;
        top: 0;
        left: 60px;
        border: 2px $red solid;
        border-right: none;
         p {
           position: absolute;
           top: 100px;
           left: -50px;
         }
      }
      .juku {
        width: 50px;
        height: 425px;
        top: 0;
        right: 50px;
        border: 1px #505050 solid;
        border-left: none;
        p {
          padding: 10px 0;
          position: absolute;
          top: 10px;
          right: -50px;
        }
      }
    }
    .txt3 {
      margin: 0 auto;
      margin-left: -500px;
      width: 630px;
      position: absolute;
      top: 1250px;
      left: 50%;
      letter-spacing: -2px;
      p {
        margin-bottom: 20px;
        font-size: 24px;
        font-weight: bold;
        span {
          font-size: 18px;
        }
        &:nth-last-child(2) {
          color: $red;
          font-size: 30px;
        }
        &:last-child {
          color: $red;
        }
      }
    }
    .add-up .txt {
      p {
        margin-bottom: 20px;
      }
    }
    @media all and (max-width: 999px) {
      margin: 0 0 40px;
      .style2-inner {
        padding-top: 0;
        width: 100%;
        min-height: auto;
        position: static;
        overflow: auto;
      }
      .style2-inner-sp {
        padding-top: 85px;
        min-height: 900px;
        position: relative;
        @media all and (max-width: 330px) {
          min-height: 950px;
        }
      }
      h2 {
        margin-right: -50px;
        margin-top: 30px;
        padding: 40px 0 100px 40px;
        width: 88%;
        height: 290px;
        top: 0;
        right: auto;
        left: 0;
        @media all and (max-width: 350px) {
          padding-left: 20px;
          width: 95%;
        }
        &::after {
          width: 84px;
          height: 125px;
          position: absolute;
          content: "";
          background: url('/img/campaign/20180701/style2_ti_bg.png') no-repeat;
          background-size: 42px 62px;
          top: -30px;
          left: 84px;
          right: auto;
        }
        .h2-inner {
          float: static;
          width: 100%;
        }
      }
      picture.img1 {
        margin-left: 0;
        width: 232px;
        height: 224px;
        top: 0;
        left: auto;
        right: 0;
        img {
          width: 100%;
        }
        @media all and (max-width: 350px) {
          width: 200px;
        }
      }
      .txt2 {
        margin-right: -70px;
        padding: 35px 5px 35px 25px;
        width: 94%;
        top: 370px;
        right: auto;
        left: 6%;
        box-sizing: border-box;
        .txt-inner {
          float: static;
          width: 100%;
        }
        p {
          font-size: 14px;
          span {
            font-size: 16px;
            font-weight: bold;
          }
        }
        p:last-child {
          span.ylw {
            font-size: 16px;
          }
        }
      }
      .graph-box {
        margin: 0 auto 60px;
        width: 270px;
        height: 437px;
        position: relative;
        top: auto;
        left: auto;
        .circle {
          margin: 0 auto;
          width: 135px;
          .circle1, .circle2, .circle3 {
            margin-bottom: 16px;
            padding-top: 40px;
            width: 135px;
            height: 135px;
            p {
              font-size: 24px;
              span {
                font-size: 16px;
              }
            }
          }
          .circle3 {
            margin-bottom: 0;
          }
        }
        .risu, .juku {
          font-weight: bold;
          position: absolute;
          writing-mode: vertical-rl;
          p {
            padding: 10px 0;
            font-size: 13px;
            background: #fff;
          }
          span.stress {
            font-size: 18px;
          }
        }
        .risu {
          width: 40px;
          height: 437px;
          top: 0;
          left: 30px;
          border: 1px $red solid;
          border-right: none;
           p {
             position: absolute;
             top: 70px;
             left: -36px;
           }
        }
        .juku {
          width: 70px;
          height: 286px;
          top: 0;
          right: 10px;
          border: 1px #505050 solid;
          border-left: none;
          p {
            padding: 10px 0;
            position: absolute;
            top: 20px;
            right: -13px;
          }
        }
      }
      .txt3 {
        margin: 0 auto 90px;
        width: calc(100% - 70px);
        position: static;
        top: auto;
        left: auto;
        letter-spacing: 0;
        p {
          margin-bottom: 15px;
          font-size: 16px;
          span {
            font-size: 12px;
          }
          &:nth-last-child(2) {
            font-size: 16px;
          }
          &:last-child {
            font-size: 14px;
          }
        }
      }
      .add-up .txt {
        p {
          margin-bottom: 10px;
        }
        p:nth-last-child(2) {
          margin-bottom: 5px;
        }
      }
    }
  }
  // style3
  section.style3 {
    margin-bottom: 160px;
    padding-top: 20px;
    .style3-inner {
      margin-bottom: 90px;
      width: 100%;
      height: 2350px;
      position: relative;
      overflow: hidden;
    }
    h2 {
      margin-left: -140px;
      margin-top: 0;
      padding: 100px 0 100px 180px;
      width: 640px;
      top: 0;
      left: 50%;
      &::after {
        width: 77px;
        height: 115px;
        position: absolute;
        content: "";
        background: url('/img/campaign/20180701/style3_ti_bg.png') no-repeat;
        background-size: 77px 115px;
        top: 30px;
        left: 340px;
      }
    }
    picture.img1 {
      margin-right: 50px;
      width: 731px;
      top: 150px;
      right: 50%;
      img {
        width: 731px;
      }
    }
    .txt1 {
      margin-left: -100px;
      padding: 80px 0 130px 70px;
      width: 1020px;
      top: 530px;
      left: 50%;
      font-size: 20px;
      .txt-inner {
        float: left;
        width: 540px;
        letter-spacing: -1.5px;
      }
      p {
        line-height: 1.6;
      }
      span {
        font-size: 24px;
        font-weight: bold;
      }
    }
    .txt2 {
      margin-right: -220px;
      padding: 70px 0 70px 0;
      width: 980px;
      top: 1060px;
      right: 50%;
      .txt-inner {
        float: right;
        width: 720px;
      }
      p {
        font-size: 18px;
        font-weight: bold;
        span {
          font-size: 24px;
        }
      }
      p:last-child {
        font-size: 30px;
        color: $yellow;
      }
    }
    .img2 {
      margin: auto;
      width: 1000px;
      position: absolute;
      top: 1340px;
      right: 0;
      left: 0;
      img {
        width: 100%;
      }
    }
    .add-up .txt p {
      margin-bottom: 10px;
    }
    @media all and (max-width: 999px) {
      margin-bottom: 60px;
      padding-top: 0;
      .style3-inner {
        margin-bottom: 50px;
        padding-top: 25px;
        width: 100%;
        height: 1490px;
        @media all and (max-width: 350px) {
          height: 1520px;
        }
      }
      h2 {
        margin: 0 0 0 auto;
        padding: 30px 0 80px 30px;
        width: 240px;
        height: 290px;
        top: 0;
        left: 0;
        right: 0;
        box-sizing: border-box;
        &::after {
          width: 42px;
          height: 63px;
          background-size: 42px 63px;
          top: -25px;
          left: 100px;
        }
      }
      picture.img1 {
        margin: 0 auto;
        width: 319px;
        height: 224px;
        top: 270px;
        right: 0;
        left: 0;
        img {
          width: 100%;
        }
      }
      .txt1 {
        margin-left: 0;
        padding: 40px 20px 80px 30px;
        width: calc(100% - 25px);
        top: 535px;
        left: auto;
        right: 0;
        font-size: 14px;
        .txt-inner {
          float: static;
          width: 100%;
          letter-spacing: 0;
        }
        span {
          font-size: 16px;
        }
        @media all and (max-width: 350px) {
          padding: 40px 20px 80px;
          br.is-sp-350 {
            display: none;
          }
        }
      }
      .txt2 {
        margin-right: 0;
        padding: 45px 20px 40px 30px;
        width: calc(100% - 25px);
        top: 860px;
        right: auto;
        left: 0;
        @media all and (max-width: 340px) {
          top: 900px;
        }
        .txt-inner {
          float: static;
          width: 100%;
        }
        p {
          font-size: 16px;
          letter-spacing: -1px;
          span {
            font-size: 16px;
          }
        }
        p:last-child {
          font-size: 16px;
        }
      }
      .img2 {
        margin: auto;
        width: 328px;
        position: absolute;
        top: 1120px;
        right: 0;
        left: 0;
        @media all and (max-width: 350px) {
          top: 1150px;
        }
        img {
          width: 100%;
        }
      }
      .add-up .txt p {
        margin-bottom: 10px;
      }
    }
  }
  // style4
  section.style4 {
    margin-bottom: 150px;
    .style4-inner {
      margin-bottom: 60px;
      width: 100%;
      min-height: 1180px;
      position: relative;
      overflow: hidden;
    }
    h2 {
      margin-right: -50px;
      margin-top: 120px;
      padding: 60px 0 120px;
      height: 560px;
      top: 0;
      right: 50%;
      letter-spacing: -2px;
      &::after {
        width: 77px;
        height: 115px;
        position: absolute;
        content: "";
        background: url('/img/campaign/20180701/style4_ti_bg.png') no-repeat;
        background-size: 77px 115px;
        top: -35px;
        right: 190px;
      }
      .h2-inner {
        float: right;
        width: 480px;
      }
    }
    picture.img1 {
      margin-left: -60px;
      width: 767px;
      top: 0;
      left: 50%;
      position: absolute;
      img {
        width: 767px;
        box-shadow: -3.857px 4.596px 0px 0px rgba(0, 0, 0, 0.15);
      }
    }
    .txt2 {
      margin: auto;
      padding: 90px 0 120px;
      width: 1000px;
      top: 590px;
      right: 0;
      left: 0;
      p {
        font-size: 20px;
        text-align: center;
        span {
          font-size: 24px;
          font-weight: bold;
        }
      }
      p:last-child {
        span {
          color: $yellow;
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
    .add-up {
      height: 330px;
      .bg {
        z-index: 1;
      }
      .ph, .txt {
        z-index: 2;
      }
      .txt {
        margin: auto;
        position: absolute;
        text-align: center;
        right: 0;
        left: 0;
        &.is-pc {
          p:first-child {
            margin-bottom: 20px;
          }
        }
      }
    }
    @media all and (max-width: 999px) {
      margin-bottom: 60px;
      .style4-inner {
        padding-top: 120px;
        margin-bottom: 40px;
        width: 100%;
        min-height: 850px;
        @media all and (max-width: 350px) {
          min-height: 900px;
        }
      }
      h2 {
        margin: 0 auto 0 0;
        padding: 35px 40px 75px;
        width: 67%;
        height: 290px;
        top: 0;
        right: 0;
        letter-spacing: -2px;
        box-sizing: border-box;
        &::after {
          width: 42px;
          height: 63px;
          background-size: 42px 63px;
          top: -30px;
          right: auto;
          left: 85px;
        }
        .h2-inner {
          float: static;
          width: 100%;
        }
        @media all and (max-width: 350px) {
          width: 75%;
        }
      }
      picture.img1 {
        margin: 0 0 0 auto;
        width: 232px;
        top: 0;
        right: auto;
        left: 37%;
        img {
          width: 100%;
          box-shadow: 3.857px 4.596px 0px 0px rgba(0, 0, 0, 0.15);
        }
        @media all and (max-width: 350px) {
          left: 40%;
        }
      }
      .txt2 {
        margin: 0 0 0 auto;
        padding: 40px 30px 55px;
        width: calc(100% - 25px);
        top: 375px;
        right: 0;
        left: 0;
        p {
          margin-bottom: 15px;
          font-size: 14px;
          text-align: left;
          letter-spacing: -1px;
          span {
            font-size: 16px;
          }
        }
        p:last-child {
          span {
            font-size: 16px;
          }
        }
      }
      .add-up {
        height: 230px;
        .txt {
          padding: 0;
          width: 100%;
          letter-spacing: -1px;
          p {
            margin-bottom: 0;
            &:nth-child(3) {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  // style5
  section.style5 {
    .style5-inner {
      width: 100%;
      min-height: 1340px;
      position: relative;
      overflow: hidden;
    }
    h2 {
      margin-left: -140px;
      padding: 100px 0 80px 180px;
      width: 640px;
      top: 0;
      left: 50%;
      &::after {
        width: 84px;
        height: 125px;
        position: absolute;
        content: "";
        background: url('/img/campaign/20180701/style5_ti_bg.png') no-repeat;
        background-size: 84px 125px;
        top: 30px;
        right: 220px;
      }
    }
    picture.img1 {
      margin-right: 60px;
      width: 774px;
      top: 140px;
      right: 50%;
      img {
        width: 774px;
      }
    }
    .txt2 {
      margin-left: -110px;
      padding: 70px 0 110px 70px;
      width: 1020px;
      top: 550px;
      left: 50%;
      font-size: 20px;
      .txt-inner {
        float: left;
        width: 540px;
        letter-spacing: -1.5px;
      }
      p:nth-child(3) {
        font-size: 24px;
        font-weight: bold;
        color: $yellow;
      }
    }
    .add-up {
      margin-bottom: 160px;
      height: 320px;
      .bg {
        z-index: 1;
      }
      .ph, .txt {
        z-index: 2;
      }
      .txt {
        margin: auto;
        position: absolute;
        right: 0;
        left: 0;
        p {
          margin-bottom: 10px;
        }
      }
    }
    .img2 {
      width: 100%;
      height: 476px;
      background-image: url('/img/campaign/20180701/style5_img2.jpg');
      background-size: cover;
      background-position: center;
    }
    @media all and (max-width: 999px) {
      .style5-inner {
        margin-bottom: 30px;
        padding-top: 25px;
        min-height: 1040px;
        @media all and (max-width: 350px) {
          min-height: 1070px;
        }
        @media all and (max-width: 330px) {
          min-height: 1050px;
        }
        @media all and (min-width: 768px) {
          min-height: 980px;
        }
      }
      h2 {
        margin: 0 0 0 auto;
        padding: 30px 0 80px 30px;
        width: 250px;
        top: 0;
        left: auto;
        right: 0;
        &::after {
          width: 42px;
          height: 63px;
          background-size: 42px 63px;
          top: -25px;
          right: 100px;
        }
      }
      picture.img1 {
        margin: 0 auto;
        width: 319px;
        top: 270px;
        right: 0;
        left: 0;
        img {
          width: 100%;
        }
      }
      .txt2 {
        margin-left: 0;
        padding: 40px 25px;
        width: 86.9%;
        top: 470px;
        right: auto;
        left: 0;
        font-size: 14px;
        p {
          margin-bottom: 15px;
        }
        @media all and (max-width: 330px) {
          padding: 40px 20px;
          width: 95%;
        }
        .txt-inner {
          float: static;
          width: 100%;
          letter-spacing: -1.5px;
        }
        p:nth-child(3) {
          font-size: 16px;
          font-weight: bold;
          color: $yellow;
        }
      }
      .add-up {
        margin-bottom: 50px;
        height: 200px;
        .txt {
          padding: 0;
          p {
            margin-bottom: 5px;
          }
        }
        @media all and (min-width: 374px) {
          height: 170px;
          p br.is-sp {
            display: none;
          }
        }
      }
      .img2 {
        padding-top: 69.3%;
        width: 100%;
        height: 0;
        background-image: url('/img/campaign/20180701/sp/style5_img2.jpg');
      }
    }
  }
  // model
  section.model {
    margin: 190px 0 140px;
    h2 {
      margin-bottom: 60px;
      padding: 20px 0;
      border: 1px #e2e2e2 solid;
      border-right: none;
      border-left: none;
      font-size: 42px;
      color: $orange;
      text-align: center;
      box-shadow: 0 -9px 0 #fff, 0 -10px 0 #e2e2e2, 0 9px 0 #fff, 0 10px 0 #e2e2e2;
      position: relative;
      &::after {
        margin-right: 270px;
        width: 188px;
        height: 127px;
        position: absolute;
        content: "";
        background: url('/img/campaign/20180701/model_ti_ic.png'), #fff;
        background-size: 188px 127px;
        top: -50px;
        right: 50%;
      }
    }
    .inner {
      p {
        margin-bottom: 60px;
        font-size: 18px;
        letter-spacing: -1px;
      }
      span.kome {
        font-size: 18px;
      }
      table {
        margin-bottom: 20px;
        width: 1000px;
        th, td {
          border: 1px #d5d5d5 solid;
          height: 70px;
          vertical-align: middle;
          text-align: center;
          font-size: 1.5rem;
          font-weight: 800;
        }
        tr:nth-child(odd) {
          th, td {
            background: #f5f5f5;
          }
        }
        tr th {
          width: calc(775px / 7);
        }
        tr th:first-child {
          width: 75px;
        }
        tr th:nth-child(2) {
          width: 150px;
        }
        tr:first-child {
          height: 60px;
          th {
            color: $c-ao;
            font-size: 1.6rem;
            background: #fff;
          }
        }
      }
    }
    @media all and (max-width: 999px) {
      margin: 60px 0 40px;
      h2 {
        margin: 0 auto 20px;
        padding: 5px 0;
        width: calc(100% - 50px);
        font-size: 20px;
        box-shadow: 0 -4px 0 #fff, 0 -5px 0 #e2e2e2, 0 4px 0 #fff, 0 5px 0 #e2e2e2;
        &::after {
          margin-right: -32px;
          width: 65px;
          height: 44px;
          position: absolute;
          content: "";
          background: url('/img/campaign/20180701/model_ti_ic.png'), #fff;
          background-size: 65px 44px;
          top: -40px;
          right: 50%;
        }
      }
      .inner {
        padding: 0 25px;
        width: 100%;
        box-sizing: border-box;
        p {
          margin-bottom: 30px;
          font-size: 14px;
        }
        span.kome {
          font-size: 11px;
        }
        table {
          margin-bottom: 5px;
          width: 100%;
          th, td {
            height: 35px;
            font-size: 10px;
            font-weight: 800;
          }
          th {
            font-size: 11px;
          }
          tr th:first-child {
            width: 20px;
          }
          tr th:nth-child(2) {
            width: 40px;
          }
          tr:first-child {
            height: auto;
            th {
              font-size: 10px;;
            }
          }
        }
      }
    }
  }
  // lifestyle
  section.lifestyle {
    padding-top: 100px;
    min-height: 1350px;
    position: relative;
    overflow: hidden;
    .life-detail {
      margin:  auto;
      width: 1095px;
      height: 1322px;
      background: url('/img/campaign/20180701/lifestyle_bg.png') no-repeat;
      background-size: 1095px auto;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      text-align: center;
      color: #fff;
      font-weight: bold;
      div {
        position: absolute;
        font-size: 22px;
        span {
          font-size: 40px;
        }
      }
      .life1 {
        top: 380px;
        left: 115px;
      }
      .life2 {
        top: 370px;
        right: 65px;
      }
      .life3 {
        margin: auto;
        top: 770px;
        left: 0;
        right: 0;
      }
      p {
        margin: auto;
        position: absolute;
        top: 1040px;
        left: 0;
        right: 0;
        color: #000;
        font-size: 32px;
      }
    }
    .bg {
      width: 100%;
      height: 1000px;
      background: url('/img/campaign/20180701/bg_grid.png') repeat;
      background-size: 4px;
    }
    @media all and (max-width: 999px) {
      margin-bottom: 40px;
      padding-top: 222.9%;
      min-height: auto;
      position: relative;
      overflow: hidden;
      .life-detail {
        margin: auto;
        padding-top: 222.9%;
        width: 100%;
        height: auto;
        background: url('/img/campaign/20180701/sp/lifestyle_bg.jpg') no-repeat;
        background-size: cover;
        div {
          position: absolute;
          font-size: 3.2vw;
          span {
            font-size: 5.3vw;
          }
        }
        .life1 {
          top: 4%;
          left: 24%;
        }
        .life2 {
          top: 3.5%;
          right: 19%;
        }
        .life3 {
          margin-left: 10px;
          top: 62.2%;
        }
        p {
          top: 79.1%;
          font-size: 4.26vw;
        }
      }
      .bg {
        background: transparent;
      }
    }
  }
  // voice
  section.voice {
    margin-bottom: 70px;
    h2 {
      margin-bottom: 70px;
      padding-top: 180px;
      width: 100%;
      height: 410px;
      box-sizing: border-box;
      font-size: 28px;
      color: #000;
      background-image: url('/img/campaign/20180701/voice_ti_bg.png');
      background-size: cover;
      background-position: center;
      text-align: center;
      span {
        display: block;
        font-size: 70px;
        font-family: $mincho;
        color: #be0000;
      }
    }
    @media all and (max-width: 999px) {
      margin-bottom: 40px;
      h2 {
        margin-bottom: 25px;
        padding-top: 30px;
        width: 100%;
        height: 176px;
        font-size: 16px;
        background-image: url('/img/campaign/20180701/voice_ti_bg.png');
        span {
          font-size: 27.5px;
        }
      }
      .inner {
        padding: 0 40px;
      }
    }
    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      li {
        margin-bottom: 40px;
        width: 480px;
        background: #fff;
        border: 1px solid $c-gray;
        @media all and (max-width: 999px) {
          margin-bottom: 30px;
          width: 100%;
        }
        &:nth-last-child(2), &:last-child {
          margin-bottom: 0;
        }
        &:nth-last-child(2) {
          @include media(sp) {
            margin-bottom: 30px;
          }
        }
        a {
          padding: 30px 30px 40px;
          width: 480px;
          transition: .3s ease-in-out;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          display: block;
          cursor: auto;
          pointer-events: none;
          &:hover {
            opacity: .7;
          }
          @include media(sp) {
            padding: 25px 25px 30px;
            width: auto;
          }
        }
        .upper-wrap {
          display: flex;
          align-items: center;
          margin-bottom: 17px;
        }
        .thum {
          width: 155px;
          margin-right: 25px;
          img {
            width: 100%;
            height: auto;
          }
          @include media(sp) {
            width: percentage(170/510);
            margin-right: 20px;
          }
        }
        .txt {
          margin-top: 50px;
          font-size: 2rem;
          font-family: $mincho;
          color: $c-ao;
          @include media(sp) {
            flex: 1;
            margin-top: 0;
            font-size: 1.3rem;
          }
          .pass-name {
            display: none;
            @include media(sp) {
              display: block;
              color: #000;
              margin-top: 6px;
              font-family: $base-font-family;
            }
          }
        }
        .name {
          margin-bottom: 10px;
          color: #000;
          @include media(sp) {
            display: none;
          }
        }
        .desc {
          padding-top: 23px;
          border-top: 1px solid $c-gray;
          line-height: 1.67;
          color: #000;
          @include media(sp) {
            font-size: 1.4rem;
            padding-top: 16px;
          }
        }
      }
    }
  }
  //faq
  section.faq {
    margin: 160px 0 120px;
    h2 {
      margin: 0 auto 30px;
      width: 400px;
      color: $blue;
      font-size: 40px;
      font-weight: bold;
      position: relative;
      text-align: center;
      &::before, &::after{
        content: '';
        position: absolute;
        top: 30px;
        display: inline-block;
        width: 45px;
        height: 1px;
        background-color: #646464;
        -moz-transform: rotate(70deg);
        -webkit-transform: rotate(70deg);
        -ms-transform: rotate(70deg);
        transform: rotate(70deg);
      }
      &::before {
        box-shadow: 3px -10px 0 #646464;
        left: 0;
      }
      &::after {
        -moz-transform: rotate(-70deg);
        -webkit-transform: rotate(-70deg);
        -ms-transform: rotate(-70deg);
        transform: rotate(-70deg);
        left: auto;
        right: 0;
        box-shadow: 3px 10px 0 #646464;
      }
      span {
        font-size: 28px;
        color: #646464;
        display: block;
        font-family: $q-min;
      }
    }
    @media all and (max-width: 999px) {
      margin: 50px 0;
      h2 {
        margin: 0 auto 40px;
        width: 250px;
        font-size: 22px;
        &::before, &::after{
          content: '';
          position: absolute;
          top: 15px;
          display: inline-block;
          width: 30px;
          height: 1px;
          background-color: #c8c8c8;
          -moz-transform: rotate(75deg);
          -webkit-transform: rotate(75deg);
          -ms-transform: rotate(75deg);
          transform: rotate(75deg);
        }
        &::before {
          box-shadow: 2px -5px 0 #c8c8c8;
          left: 0;
        }
        &::after {
          -moz-transform: rotate(-75deg);
          -webkit-transform: rotate(-75deg);
          -ms-transform: rotate(-75deg);
          transform: rotate(-75deg);
          left: auto;
          right: 0;
          box-shadow: 2px 5px 0 #c8c8c8;
        }
        span {
          font-size: 17.5px;
        }
      }
    }
    .tab-contents-title {
      color: $c-ao;
      margin-bottom: 15px;
      @media all and (max-width: 999px) {
        padding: 0 25px;
        font-size: 16px;
        text-align: center;
      }
    }
    .tab-contents-title2 {
      margin-top: 40px;
    }
    dl {
      border-bottom: 1px solid $c-gray;
    }
    dt p,
    dd {
      line-height: 2;
      position: relative;
      &::before {
        position: absolute;
        font-size: 3.4rem;
        font-family: $q-min;
        left: 0;
        top: 4px;
      }
      a {
        text-decoration: underline;
        color: #323232;
        &:hover {
          text-decoration: none;
        }
      }
      @include media(sp) {
        line-height: 1.7;
        font-size: 1.4rem;
      }
    }
    dt {
      border-top: 1px solid $c-gray;
      font-family: $mincho;
      cursor: pointer;
      p {
        padding: 22px 46px;
        &::before {
          content: 'Q';
          color: $c-ao;
        }
      }
      @include media(sp) {
        p {
          padding: 22px 0 22px 46px;
          margin: 0 25px;
        }
      }
    }
    dd {
      display: none;
      border-top: 1px dotted $c-gray;
      &::before {
        content: 'A';
        color: $c-aka;
      }
      @include media(sp) {
        display: none;
        width: calc(100% - 50px);
        margin: 0 auto;
      }
      > p {
        margin: 22px 46px;
        @include media(sp) {
          margin: 22px 0px 22px 46px;
        }
      }
    }
  }
  //company
  section.company {
    margin-bottom: 160px;
    padding: 100px 0;
    background: #f5f5f5;
    h2 {
      margin-bottom: 50px;
      text-align: center;
      color: $blue;
      font-size: 40px;
      font-weight: bold;
    }
    dl {
      margin: 0 auto;
      border-top: 1px #e0e0e0 solid;
      width: 700px;
      display: flex;
      flex-wrap: wrap;
      dt, dd {
        padding: 30px 0;
        border-bottom: 1px #e0e0e0 solid;
        font-size: 18px;
      }
      dt {
        width: 130px;
        font-weight: bold;
      }
      dd {
        width: 570px;
      }
    }
    @media all and (max-width: 999px) {
      margin-bottom: 60px;
      padding: 70px 0;
      h2 {
        margin-bottom: 20px;
        font-size: 20px;
      }
      .inner {
        padding: 0 25px;
      }
      dl {
        width: 100%;
        dt, dd {
          padding: 10px 0;
          font-size: 14px;
        }
        dt {
          padding: 10px;
          width: 70px;
          font-weight: bold;
        }
        dd {
          width: calc(100% - 70px);
        }
      }
    }
  }
  //contact
  section#contact {
    margin-bottom: 120px;
    h2 {
      padding: 20px 0;
      border: 1px #dcdcdc solid;
      border-right: none;
      border-left: none;
      font-size: 36px;
      font-weight: bold;
      text-align: center;
    }
    p.desc {
      margin: 20px 0 40px;
      text-align: center;
      font-size: 22px;
      font-weight: bold;
    }
    @media all and (max-width: 999px) {
      margin-bottom: 60px;
      .inner {
        padding: 0 50px;
      }
      h2 {
        padding: 0;
        border: none;
        font-size: 20px;
      }
      p.desc {
        margin: 20px 0 30px;
        font-size: 14px;
      }
    }
    .form-errors {
      border: 1px solid #cc0000;
      padding: 8px 10px;
      margin-bottom: 20px;
      li {
        line-height: 1.5;
        font-size: 1.6rem;
        font-weight: bold;
        color: #cc0000;
        padding: 4px 0 4px 20px;
        position: relative;
      }
    }
    .form {
      dl {
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        dt,
        dd {
          padding: 20px 0;
          font-family: $mincho;
          @include media(sp) {
            font-size: 1.4rem;
          }
        }
        dt {
          clear: both;
          // width: 200px;
          width: 360px;
          padding-right: 20px;
          float: left;
          span {
            font-size: 1.2rem;
          }
          @include media(sp) {
            width: 100%;
            float: none;
            padding-bottom: 0;
            margin-bottom: 10px;
            border-top: 1px solid #dcdcdc;
            &:first-child {
              border-top: 0;
            }
          }
        }
        dd {
          width: 100%;
          padding-left: 424px;
          border-top: 1px solid #dcdcdc;
          &:nth-of-type(1) {
            border-top: 0;
          }
          span.kome {
            margin-top: 5px;
            line-height: 1.5;
            font-size: 80%;
            font-family: $base-font-family;
            display: block;
            color: #777;
          }
          @include media(sp) {
            padding-left: 0;
            padding-top: 0;
            border-top: 0;
            &:nth-of-type(1) {
              border-top: 0;
            }
          }
        }
      }
      .type-radio {
        display: flex;
        flex-wrap: wrap;
        @include media(sp) {
          display: block;
        }
        > div {
          margin: 0 45px 5px 0;
          @include media(sp) {
            margin-top: 10px;
            &:nth-child(1) {
              margin-top: 0;
            }
            display: flex;
            align-items: center;
          }
        }
        &.type-radio-week {
          margin-bottom: 10px;
          > div {
            margin-right: 40px;
            @include media(sp) {
              margin-top: 0;
              display: flex;
              align-items: center;
              margin-bottom: 5px;
            }
          }
          @include media(sp) {
            display: flex;
            margin-bottom: 5px;
          }
        }
      }
      .ast {
        position: relative;
        &::after {
          content: '\002a';
          color: #fe0000;
          font-size: 1rem;
          position: relative;
          top: -4px;
          margin-left: 3px;
        }
      }
      input[type="text"],
      input[type="email"],
      input[type="tel"],
      textarea {
        background: #efefef;
        border: 1px solid $c-usui02;
        padding: 10px;
        font-size: 1.4rem;
        width: 100%;
        border-radius: 0;
      }
      textarea {
        height: 187px;
      }
      .address-area {
        input[name="pref"] {
          margin-bottom: 10px;
        }
        @include media(sp) {
          position: relative;
          padding-top: 15px;
        }
      }
      .input-02 {
        display: flex;
        justify-content: space-between;
        > input {
          width: percentage(560/1154);
        }
        @include media(sp) {
          display: block;
          > input {
            width: 100%;
            &:nth-child(2) {
              margin-top: 10px;
            }
          }
        }
      }
      .zip-area {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        p {
          &:first-child {
            margin-right: 45px;
            @include media(sp) {
              display: none;
            }
          }
        }
        @include media(sp) {
          position: absolute;
          top: -41px;
          left: 0;
          width: 100%;
          padding-left: 50px;
        }
      }
      .zip-num {
        display: flex;
        align-items: center;
        @include media(sp) {
          width: 56%;
        }
      }
      .zip01 {
        width: 88px;
      }
      .zip-space {
        margin: 0 10px;
        @include media(sp) {
          margin: 0 7px;
        }
      }
      .zip02 {
        width: 99px;
      }
      .address-search-btn {
        margin-left: auto;
        a {
          display: block;
          background: $c-ao;
          text-align: center;
          color: #fff;
          font-family: $mincho;
          font-size: 1.6rem;
          padding: 8px 10px;
          width: 214px;
        }
        @include media(sp) {
          // margin-left: 15px;
          a {
            width: auto;
            font-size: 1.4rem;
          }
        }
      }
      input[name="address01"] {
        margin-bottom: 20px;
      }
      input[name="time"] {
        margin-top: 18px;
      }

      .check-agree {
        text-align: center;
        margin: 52px 0 20px;
        font-family: $mincho;
        font-size: 1.8rem;
        input {
          position: relative;
          top: -1px;
        }
        @include media(sp) {
          margin: 35px 0 20px;
        }
      }

      .questionnaire-lead-01 {
        text-align: center;
        font-size: 1.4rem;
        line-height: 1.92;
        margin-bottom: 32px;
        font-family: $mincho;
        a {
          color: inherit;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
        @include media(sp) {
          font-size: 1.2rem;
          margin-bottom: 23px;
        }
      }
      .questionnaire-lead-02 {
        text-align: center;
        font-size: 1.2rem;
        font-family: $mincho;
      }
      button,
      .form-btns a {
        background: #be0000;
        font-size: 1.8rem;
        text-align: center;
        color: #fff;
        width: 278px;
        border: 0;
        margin: 18px auto 0;
        font-family: $mincho;
        padding: 19px 20px;
        cursor: pointer;
        display: block;
        transition: .3s ease-in-out;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 15px;
          margin-top: -8px;
          background: url(/img/common/icn_arrow_02_white.svg) left top / 10px no-repeat;
          width: 10px;
          height: 16px;
        }
        &:hover {
          opacity: .7;
        }
        @include media(sp) {
          font-size: 1.6rem;
          padding: 12px 70px;
          width: auto;
          margin-top: 28px;
        }
      }
    }
    .doc-link {
      text-align: center;
      font-size: 1.6rem;
      font-family: $mincho;
      padding: 0 0 20px;
      @include media(sp) {
        font-size: 1.2rem;
      }
    }
    .questionnaire-area {
      .sub-head {
        font-family: $mincho;
        color: $c-ao;
        margin-bottom: 35px;
        text-align: center;
        font-size: 2.5rem;
        font-weight: normal;
      }
      @include media(sp) {
        .sub-head {
          font-size: 1.8rem;
          margin-bottom: 22px;
          span {
            display: block;
          }
        }
      }
    }
    .questionnaire-content {
      padding: 32px 0;
      border-top: 1px solid $c-usui02;
      &:nth-of-type(2) {
        border-bottom: 1px solid $c-usui02;
      }
      @include media(sp) {
        padding: 25px 0;
      }
      .content-head {
        font-family: $mincho;
        color: $c-ao;
        margin-bottom: 25px;
        font-size: 2.1rem;
        font-weight: normal;
        @include media(sp) {
          font-size: 1.6rem;
          margin-bottom: 15px;
        }
      }
      .form-q {
        display: flex;
        flex-wrap: wrap;
        @include media(sp) {
          display: block;
        }
        > div {
          margin-right: 58px;
          margin-bottom: 10px;
          @include media(sp) {
            display: flex;
            font-size: 1.4rem;
            label {
              flex: 1;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .footer-contact-btn {
    margin-right: -180px;
    position: fixed;
    width: 360px;
    height: 55px;
    right: 50%;
    bottom: 20px;
    z-index: 10;
    a {
      width: 360px;
      height: 65px;
      line-height: 65px;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      position: relative;
      border-radius: 6px;
      background: $c-ao;
      display: block;
      transition: .5s;
      img {
        position: absolute;
        top: 22px;
        left: 32px;
      }
      &:hover {
        background: #121d40;
        transform: scale( 1.03);
      }
      &::after {
        position: absolute;
        right: 10px;
        content: "＞";
        transform: scale( 0.6, 1);
        color: $yellow;
        font-weight: 800;
      }
      .btn {
        position: absolute;
        top: 50%;
        left: 54%;
        transform: translate(-50%,-50%);
        width: 100%;
        font-size: 18px;
        line-height: 1.3;
        .doc {
          font-size: 14px;
        }
      }
    }
  }
  footer {
    padding: 20px 0 50px;
    border-top: 1px #f0f0f0 solid;
    text-align: center;
    font-size: 13px;
    @media all and (max-width: 999px) {
      padding: 15px 0 100px;
      font-size: 10px;
    }
  }
  @media all and (max-width: 999px) {
    ul.btm-contact {
      width: 100%;
      display: flex;
      position: fixed;
      bottom: 0;
      z-index: 2;
      li {
        width: 100%;
        height: 60px;
        font-weight: bold;
        a {
          display: block;
          width: 100%;
          height: 60px;
          line-height: 60px;
          color: #fff;
          box-sizing: border-box;
          text-align: center;
          .btn {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            line-height: 1.2;
            width: 100%;
            .doc {
              font-size: 0.9em;
            }
          }
        }
      }
      li {
        font-size: 16px;
        background: url('/img/campaign/20180701/sp/footer_ic_contact.png') no-repeat 8px 28px, #152045;
        background-size: 19px auto;
        background-position: 10px 26px;
      }
    }
  }
}

body.pg-campaign20180701_conf {
  .inner {
    padding: 0 20px;
  }
  header {
    margin-bottom: 60px;
    @media all and (max-width: 999px) {
      margin-bottom: 40px;
    }
    .inner {
      padding: 0;
    }
  }
  footer {
    margin-top: 60px;
  }
  .btm-contact {
    display: none !important;
  }
}
