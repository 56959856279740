@charset "UTF-8";
.pg-message {
  .ph-box {
    width: 100%;
    height: 540px;
    position: relative;
    background-size: cover;
    background-position: center;
  }
  .txt-box {
    margin-left: -500px;
    margin-top: -110px;
    padding: 100px 100px 0;
    position: relative;
    top: 0;
    left: 50%;
    width: 1000px;
    background: #fff;
    box-sizing: border-box;
    h2 {
      margin-bottom: 40px;
      font-size: 31px;
      font-family: $mincho;
      font-weight: 800;
      color: $c-ao;
      text-align: center;
    }
    p {
      margin-bottom: 15px;
      font-size: 16px;
      &.name {
        margin-top: 50px;
        text-align: right;
      }
    }
    @media all and (max-width: 1040px) {
      margin: -110px 0 0;
      width: calc(100% - 40px);
      left: 20px;
    }
  }
  @include media(sp) {
    .ph-box {
      width: 100%;
      height: 250px;
      position: relative;
      background-size: cover;
      background-position: center top;
    }
    .txt-box {
      margin: -40px 0 0;
      padding: 30px 20px 0;
      top: 0;
      left: 20px;
      h2 {
        margin-bottom: 20px;
        line-height: 1.6;
        font-size: 20px;
      }
      p {
        font-size: 15px;
        &.name {
          margin-top: 30px;
        }
      }
    }
  }
}
