@charset "UTF-8";
[class^="pg-strength"], {
  .mainvisual {
    width: 100%;
    height: 410px;
    background: $c-ao;
    font-family: $mincho;
    display: flex;
    display: -webkit-flex;
    .mainvisual-inner {
      width: 52%;
      height: 410px;
      background: url("/img/strength/strength1_mv.png") no-repeat;
      background-position: center;
      background-size: cover;
    }
    .txt {
      width: 48%;
    }
    h2 {
      margin-top: 90px;
      width: 460px;
      line-height: 50px;
      color: #fff;
      font-size: 3rem;
      text-align: center;
      span.l1 {
        font-size: 2rem;
        display: block;
        span {
          font-size: 5rem;
        }
      }
      span.l2 {
        margin: 0 auto 20px;
        border: 1px #fff solid;
        border-right: none;
        border-left: none;
        width: 210px;
        font-size: 2.2rem;
        display: block;
      }
    }
    @include media(sp) {
      width: 100%;
      height: auto;
      display: block;
      .mainvisual-inner {
        width: 100%;
        height: auto;
        background: none;
        img {
          width: 100%;
          display: block;
        }
      }
      .txt {
        padding: 20px 0 35px;
        width: 100%;
      }
      h2 {
        margin-top: 0;
        width: 100%;
        height: auto;
        color: #fff;
        font-size: 2.3rem;
        text-align: center;
        span.l1 {
          font-size: 1.6rem;
          display: block;
          span {
            font-size: 4rem;
          }
        }
        span.l2 {
          margin: 0 auto 15px;
          border: 1px #fff solid;
          border-right: none;
          border-left: none;
          width: 180px;
          font-size: 1.8rem;
          display: block;
        }
        span.l3 {
          line-height: 3.3rem;
          display: block;
        }
      }
    }
  }
  section {
    h3 {
      line-height: 5rem;
      font-family: $mincho;
      font-size: 3rem;
    }
    h4 {
      margin-bottom: 30px;
      padding: 15px 0;
      border: 1px #e0e0e0 solid;
      border-right: none;
      border-left: none;
      line-height: 3.5rem;
      font-size: 2rem;
      color: $c-ao;
    }
    p {
      line-height: 2.6rem;
      font-size: 1.4rem;
    }
    @include media(sp) {
      h3 {
        line-height: 8vw;
        font-size: 5.6vw;
      }
      h4 {
        margin-bottom: 25px;
        line-height: 2.6rem;
        font-size: 1.6rem;
      }
      p {
        line-height: 2.4rem;
        font-size: 1.3rem;
      }
    }
  }
}

body.pg-strength1 {
  section.sec1 {
    background: #f5f5f5;
    .inner {
      padding: 100px 0;
      position: relative;
    }
    h3 {
      margin-left: -280px;
      writing-mode: vertical-rl;
      position: absolute;
      top: 60px;
      left: 50%;
      z-index: 2;
    }
    img {
      width: 380px;
      height: 375px;
      position: absolute;
      top: 420px;
      left: 0;
      z-index: 1;
    }
    .txt {
      float: right;
      width: 550px;
    }
    @media all and (min-width: 769px) and (max-width: 1020px) {
      .txt {
        width: calc(100% - 400px);
      }
    }
    @include media(sp) {
      margin-bottom: 50px;
      background: -prefix-linear-gradient(to bottom, #f5f5f5, #f5f5f5 69.9%, #fff 70%, #fff);
      background: linear-gradient(to bottom, #f5f5f5, #f5f5f5 69.9%, #fff 70%, #fff);
      .inner {
        padding: 35px 0 0;
        position: static;
        width: 100%;
      }
      .intro {
        display: flex;
        display: -webkit-flex;
        width: 100%;
      }
      h3 {
        margin: 0 10% 0 0;
        width: 34%;
        position: static;
        top: auto;
        left: auto;
      }
      .ph {
        margin-bottom: 20px;
        width: 56%;
        position: static;
        img {
          width: 100%;
          height: auto;
          position: static;
          top: auto;
          left: auto;
        }
      }
      .txt {
        padding: $sp-pad;
        float: none;
        width: 100%;
      }
    }
    @media all and (min-width: 425px) and (max-width: 768px) {
      background: -prefix-linear-gradient(to bottom, #f5f5f5, #f5f5f5 89.9%, #fff 90%, #fff);
      background: linear-gradient(to bottom, #f5f5f5, #f5f5f5 89.9%, #fff 90%, #fff);
      h3 {
        line-height: 8vw;
        font-size: 5vw;
      }
    }
  }
  section.sec2 {
    margin: 0 auto 150px;
    padding-top: 350px;
    width: 1000px;
    position: relative;
    .inner {
      position: relative;
    }
    h3 {
      position: absolute;
      top: 90px;
      left: 450px;
    }
    img {
      width: 380px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
    .txt {
      float: left;
      width: 600px;
    }
    @media all and (min-width: 769px) and (max-width: 1020px) {
      .txt {
        width: calc(100% - 400px);
      }
    }
    @include media(sp) {
      margin: 0 auto 70px;
      padding-top: 0;
      width: 100%;
      .inner {
        width: 100%;
      }
      .intro {
        padding-top: 55%;
        position: relative;
      }
      h3 {
        margin: 0 0 25px;
        padding-left: 20px;
        position: static;
        top: 0;
        left: 0;
      }
      .ph {
        margin-bottom: 100px;
        width: 56%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        img {
          width: 100%;
          height: auto;
          position: static;
          top: auto;
          left: auto;
        }
      }
      .txt {
        float: none;
        width: 100%;
      }
    }
    @media all and (min-width: 425px) and (max-width: 768px) {
      h3 {
        line-height: 8vw;
        font-size: 5vw;
      }
    }
  }
  section.sec3 {
    padding: 80px 0;
    background: #f5f5f5;
    .inner {
      position: relative;
    }
    .txt {
      float: right;
      width: 570px;
    }
    @media all and (min-width: 769px) and (max-width: 1020px) {
      .txt {
        width: calc(100% - 410px);
      }
    }
    h3 {
      margin-bottom: 30px;
    }
    h4 {
      letter-spacing: -1px;
    }
    img {
      width: 404px;
      position: absolute;
      top: -130px;
      left: 0;
    }
    @include media(sp) {
      margin: 0 auto 50px;
      padding: 0 0 45px;
      width: 100%;
      background: -prefix-linear-gradient(to bottom, #fff, #fff 19.9%, #f5f5f5 20%, #f5f5f5);
      background: linear-gradient(to bottom, #fff, #fff 19.9%, #f5f5f5 20%, #f5f5f5);
      .inner {
        width: 100%;
      }
      .intro {
        padding-top: 55%;
        position: relative;
      }
      h3 {
        text-align: center;
      }
      img {
        margin-bottom: 25px;
        width: 100%;
        position: static;
        top: auto;
        left: auto;
      }
      .txt {
        float: none;
        width: 100%;
      }
    }
    @media all and (min-width: 425px) and (max-width: 768px) {
      h3 {
        line-height: 8vw;
        font-size: 5vw;
      }
    }
  }
}


body.pg-strength2 {
  .mainvisual {
    .mainvisual-inner {
      background: url("/img/strength/strength2_mv.png") no-repeat;
      background-position: center;
      background-size: cover;
    }
    h2 {
      span.l2 {
        width: 370px;
      }
    }
    @include media(sp) {
      h2 {
        span.l2 {
          width: 300px;
        }
      }
    }
  }
  section.sec1 {
    margin-bottom: 310px;
    padding: 70px 0 180px;
    background: #f5f5f5;
    .txt {
      float: left;
      width: 620px;
    }
    p.intro {
      margin-bottom: 30px;
      padding: 15px 0;
      border: 1px #e0e0e0 solid;
      border-right: none;
      border-left: none;
      line-height: 3.5rem;
      font-size: 2rem;
      font-weight: 800;
      color: $c-ao;
      letter-spacing: -1px;
    }
    .ph {
      margin-bottom: 30px;
      float: right;
      width: 350px;
      img {
        width: 100%;
      }
    }
    @include media(sp) {
      margin-bottom: 0;
      padding: 45px 0 35px;
      .txt {
        width: 100%;
      }
      p.intro {
        margin-bottom: 25px;
        line-height: 2.6rem;
        font-size: 1.6rem;
      }
      .h3 {
        position: relative;
        h3 {
          margin-bottom: 30px;
          position: relative;
          z-index: 2;
          span {
            width: 30%;
            text-align: center;
            display: block;
          }
        }
      }
      .ph {
        width: 50%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        img {
          width: 100%;
        }
      }
    }
    @media all and (min-width: 425px) and (max-width: 768px) {
      h3 {
        line-height: 8vw;
        font-size: 5vw;
      }
    }
  }
  section.sec2 {
    padding-bottom: 70px;
    .inner {
      position: relative;
    }
    .txt {
      width: 100%;
    }
    .ph {
      width: 600px;
      position: absolute;
      top: -420px;
      left: 0;
      img {
        width: 100%;
      }
    }
    h3 {
      margin-left: 140px;
      height: 530px;
      writing-mode: vertical-rl;
      position: absolute;
      top: -420px;
      right: 30px;
    }
    h4 {
      width: 600px;
    }
    @include media(sp) {
      padding-bottom: 50px;
      .inner {
        padding-top: 200px;
        position: relative;
      }
      .ph {
        width: 70%;
        position: absolute;
        top: 50px;
        left: auto;
        right: 10px;
        z-index: 1;
        img {
          width: 100%;
        }
      }
      h3 {
        margin: 0 0 20px;
        height: auto;
        writing-mode: horizontal-tb;
        position: relative;
        top: 0;
        right: 0;
        z-index: 2;
      }
      h4 {
        width: 100%;
      }
    }
    @media all and (min-width: 425px) and (max-width: 768px) {
      h3 {
        line-height: 8vw;
        font-size: 5vw;
      }
    }
  }
  section.sec3 {
    padding: 60px 0;
    background: #f5f5f5;
    h3 {
      margin-bottom: 30px;
      text-align: center;
    }
    h4 {
      margin-bottom: 40px;
      text-align: center;
    }
    @include media(sp) {
      padding: 45px 0;
      h3 {
        margin-bottom: 20px;
        text-align: left;
      }
      h4 {
        text-align: left;
      }
    }
    .hoshin {
      margin-bottom: 90px;
      display: flex;
      display: -webkit-flex;
      flex-wrap: nowrap;
      dl {
        border: 1px #d2d2d2 solid;
        border-right: none;
        padding: 0 20px 40px;
        width: 22%;
        box-sizing: border-box;
        dt {
          position: relative;
          width: 100%;
          &:after {
            position: absolute;
            top: -10px;
            right: -44px;
            content: "＞";
            transform: scale( 0.4, 1);
            font-size: 4.5rem;
            color: $c-ao;
          }
          span.ti {
            margin: 0 auto 15px;
            margin-top: -10px;
            width: 120px;
            height: 50px;
            line-height: 50px;
            background: $c-ao;
            text-align: center;
            font-family: $mincho;
            font-size: 1.9rem;
            color: #fff;
            display: block;
          }
          span.ti2 {
            margin-bottom: 10px;
            border-bottom: 1px $c-ao solid;
            padding-bottom: 5px;
            text-align: center;
            font-family: $mincho;
            font-size: 1.5rem;
            display: block;
          }
        }
        dd {
          p {
            font-size: 1.4rem;
            span {
              font-size: 1.5rem;
              font-weight: 800;
              color: $c-ao;
              display: block;
            }
            &:first-child {
              margin-bottom: 10px;
            }
          }
        }
        &:nth-child(1) {
          width: 21%;
        }
        &:nth-child(2) {
          width: 24%;
          dd p span.is-pc {
            display: inline-block;
          }
        }
        &:nth-child(3) {
          width: 21%;
        }
        &:last-child {
          border-right: 1px #d2d2d2 solid;
          padding: 0;
          width: 12%;
          background: #fff;
          dt {
            padding-top: 90px;
            &:after {
              content: "";
            }
            font-size: 1.9rem;
            font-family: $mincho;
            color: $c-ao;
            text-align: center;
            span {
              font-size: 2.5rem;
              display: block;
            }
          }
        }
      }
      @include media(sp) {
        margin-bottom: 40px;
        display: block;
        dl {
          border: 1px #d2d2d2 solid;
          border-right: none;
          border-left: none;
          padding: 0;
          width: 100%;
          box-sizing: border-box;
          dt {
            position: relative;
            width: 100%;
            &:after {
              content: "";
            }
            span.ti {
              margin-bottom: 10px;
              height: 40px;
              line-height: 40px;
              font-size: 1.6rem;
            }
            span.ti2 {
            }
          }
          dd {
            padding-bottom: 25px;
            display: flex;
            display: -webkit-flex;
            flex-wrap: nowrap;
            position: relative;
            p {
              font-size: 1.4rem;
              width: 49.5%;
              &:first-child {
                margin: 0 1% 0 0;
              }
            }
            &:after {
              margin-right: -15px;
              content: '';
              width: 30px;
              height: 30px;
              border: 0px;
              border-top: solid 1px $c-ao;
              border-right: solid 1px $c-ao;
              -ms-transform: rotate(135deg);
              -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
              position: absolute;
              bottom: -6px;
              right: 50%;
            }
          }
          &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
            margin-bottom: 40px;
            width: 100%;
          }
          &:nth-child(2) dd p span.is-pc {
            display: none;
          }
          &:last-child {
            border: 1px #d2d2d2 solid;
            width: 100%;
            height: 40px;
            line-height: 39px;
            background: #fff;
            dt {
              padding-top: 0;
              font-size: 1.6rem;
              span {
                font-size: 1.6rem;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
  .sec3-box {
    margin-bottom: 80px;
    width: 100%;
    .h5-box {
      width: 100%;
      height: 50px;
      line-height: 50px;
      background: -prefix-linear-gradient(to left, #fff, #fff 49%, $c-ao 50%, $c-ao);
      background: linear-gradient(to left, #fff, #fff 49%, $c-ao 50%, $c-ao);
      color: $c-ao;
      h5 {
        margin: 0 auto;
        max-width: 1000px;
        background: #fff;
        font-family: $mincho;
        font-size: 2rem;
      }
      span {
        margin-right: 25px;
        padding-right: 30px;
        background: $c-ao;
        color: #fff;
        display: inline-block;
      }
    }
    .txt {
      margin-top: 30px;
      float: left;
      width: 650px;
      p.stress {
        margin-bottom: 20px;
        line-height: 2.6rem;
        font-size: 1.6rem;
        font-weight: 800;
        color: $c-ao;
      }
      p span {
        margin-bottom: 10px;
        font-size: 1.6rem;
        font-weight: 800;
        display: block;
        color: $c-ao;
      }
      h6 {
        margin-bottom: 10px;
        font-size: 2rem;
        font-weight: 800;
        font-family: $mincho;
      }
      ul {
        margin-bottom: 20px;
        li {
          margin-bottom: 10px;
          padding-left: 20px;
          font-size: 1.6rem;
          font-weight: 800;
          position: relative;
          &:before {
            position: absolute;
            content: "●";
            top: 4px;
            left: 4px;
            color: #969696;
            font-size: 70%;
          }
        }
      }
    }
    .ph {
      float: right;
      width: 350px;
      img {
        width: 100%;
      }
    }
    @include media(sp) {
      margin-bottom: 45px;
      .h5-box {
        margin-left: 25px;
        width: calc(100% - 25px);
        height: 139px;
        line-height: 1;
        background: $c-ao;
        color: #fff;
        img {
          float: left;
          width: 117px;
          height: 139px;
        }
        h5 {
          margin: 40px 25px 0 0;
          padding-left: 20px;
          width: calc(100% - 142px);
          background: transparent;
          font-family: $mincho;
          font-size: 1.6rem;
          float: left;
          box-sizing: border-box;
        }
        span {
          margin: 0 0 10px;
          padding-bottom: 10px;
          border-bottom: 1px #3c55a7 solid;
          width: 100%;
          font-size: 2.1rem;
          display: block;
        }
      }
      .txt {
        float: none;
        width: 100%;
        p.stress {
          line-height: 2.4rem;
          font-size: 1.4rem;
        }
        p span {
          margin-bottom: 10px;
          font-size: 1.4rem;
        }
        h6 {
          margin-bottom: 15px;
        }
        ul {
          margin-bottom: 15px;
          li {
            margin-bottom: 10px;
            padding-left: 20px;
            font-size: 1.6rem;
            font-weight: 800;
            position: relative;
            letter-spacing: -1px;
            &:before {
              position: absolute;
              content: "●";
              top: 4px;
              left: 4px;
              color: #969696;
              font-size: 70%;
            }
          }
        }
      }
      .ph {
        float: right;
        width: 350px;
        img {
          width: 100%;
        }
      }
    }
  }
  .sec3-box.n2 {
    .h5-box {
      span {
        padding-left: 40px;
      }
    }
    .ph {
      float: left;
      width: 375px;
    }
    .txt {
      margin-top: 0;
      float: right;
      width: 625px;
      p.stress {
        margin-top: 30px;
      }
    }
    .txt-inner {
      margin: 0 0 0 auto;
      width: 580px;
    }
    @include media(sp) {
      .inner {
        padding: 0;
      }
      .h5-box {
        margin-right: 25px;
        margin-left: 0;
        span {
          padding-left: 0;
        }
        img {
          float: right;
        }
        h5 {
          margin-right: 20px;
          width: calc(100% - 137px);
          float: left;
        }
      }
      .txt {
        float: none;
        width: 100%;
        p.stress {
          margin-top: 30px;
        }
      }
      .txt-inner {
        margin: 0 auto;
        padding: 0 25px;
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
  .sec3-box.n3 {
    margin-bottom: 0;
  }
}

body.pg-strength3 {
  .mainvisual {
    .mainvisual-inner {
      background: url("/img/strength/strength3_mv.png") no-repeat;
      background-position: center;
      background-size: cover;
    }
    h2 {
      span.l2 {
        width: 370px;
        letter-spacing: -2px;
      }
    }
    @include media(sp) {
      h2 {
        span.l2 {
          width: 300px;
        }
      }
    }
  }
  section.sec1 {
    padding: 90px 0 70px;
    background: #f5f5f5;
    .inner {
      position: relative;
    }
    h3 {
      writing-mode: vertical-rl;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .txt {
      margin: 0 50px 30px auto;
      max-width: 750px;
    }
    .graph {
      margin: 0 50px 40px auto;
      width: 750px;
      li {
        margin-right: 75px;
        padding-top: 70px;
        float: left;
        width: 200px;
        height: 200px;
        line-height: 1.3;
        border-radius: 50%;
        border: 1px $c-ao solid;
        color: $c-ao;
        box-sizing: border-box;
        text-align: center;
        font-size: 3.5rem;
        font-weight: 800;
        position: relative;
        span {
          font-family: $mincho;
          font-size: 2rem;
          font-weight: normal;
          display: block;
          color: #000;
        }
        &:after {
          position: absolute;
          right: -65px;
          top: 80px;
          font-size: 4rem;
          content: "＞";
          transform: scale( 0.4, 1);
        }
        &:last-child {
          margin-right: 0;
          &:after {
            content: "";
          }
        }
      }
    }
    .inner2 {
      margin: 0 auto;
      max-width: 1000px;
      position: relative;
    }
    .arrow {
      position: absolute;
      display: inline-block;
      width: 750px;
      height: 60px;
      line-height: 60px;
      background: $c-ao;
      z-index: 1;
      font-size: 1.8rem;
      font-weight: 800;
      color: #fff;
      text-align: center;
      top: 0;
      right: 50px;
      &:after {
        margin-left: -10px;
        content: "";
        position: absolute;
        top: -10px;
        left: 50%;
        display: block;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent $c-ao transparent;
      }
    }
    .inner3 {
      p {
        margin-top: 120px;
        width: 546px;
        float: left;
      }
      .ph {
        float: right;
        position: absolute;
        width: 407px;
        top: 30px;
        right: 0;
        img {
          width: 100%;
        }
      }
    }
    @media all and (max-width: 1000px) {
      margin-bottom: 30px;
      h3 {
        margin-bottom: 30px;
        writing-mode: horizontal-tb;
        position: static;
        text-align: center;
      }
      .txt {
        margin: 0 auto 30px;
        width: 96%;
      }
      .inner3 p {
        width: calc(100% - 420px);
      }
      .arrow {
        margin: 0 auto;
        margin-right: -375px;
        top: 0;
        right: 50%;
      }
    }
    @include media(sp) {
      margin-bottom: 50px;
      padding: 40px 0;
      .txt {
        margin: 0 0 30px;
        width: 100%;
      }
      h3 {
        margin-bottom: 30px;
        writing-mode: horizontal-tb;
        position: static;
        text-align: center;
      }
      .graph {
        margin: 0 0 30px;
        width: 100%;
        li {
          margin-right: 5%;
          padding-top: 9%;
          border-radius: 50%;
          width: calc((100vw - 70px) * 0.3);
          height: calc((100vw - 70px) * 0.3);
          font-size: 5vw;
          span {
            font-size: 3.4vw;
          }
          &:after {
            font-size: 5vw;
            top: 40%;
            right: -20%;
          }
        }
      }
      .inner2 {
        margin: 0 auto;
        width: auto;
        position: static;
      }
      .arrow {
        padding: 20px 0;
        position: relative;
        display: inline-block;
        width: 100%;
        height: auto;
        line-height: 2.6rem;
        background: $c-ao;
        z-index: 1;
        font-size: 1.8rem;
        font-weight: 800;
        color: #fff;
        text-align: center;
        top: 0;
        right: 0;
        &:after {
          margin-left: -10px;
          content: "";
          position: absolute;
          top: -10px;
          left: 50%;
          display: block;
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 0 10px 10px 10px;
          border-color: transparent transparent $c-ao transparent;
        }
      }
      .inner3 {
        p {
          margin-top: 30px;
          width: 100%;
          float: none;
        }
        .ph {
          float: none;
          position: static;
          width: 100%;
          top: auto;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  section.sec2 {
    .inner2 {
      margin: 0 auto;
      position: relative;
      max-width: 1000px;
      .ph {
        float: left;
        width: 327px;
        img {
          width: 100%;
        }
      }
      h3 {
        writing-mode: vertical-rl;
        position: absolute;
        top: 90px;
        right: 480px;
      }
      .txt1 {
        margin-top: 120px;
        float: right;
        width: 410px;
      }
      .txt2 {
        margin: 70px 0 50px;
        max-width: 1000px;
      }
    }
    @media all and (max-width: 1000px) {
      margin-bottom: 30px;
      .inner2 {
        .ph {
          width: 52%;
          img {
            width: 100%;
          }
        }
        h3 {
          top: 0;
          right: 10%;
        }
        @media all and (min-width: 425px) and (max-width: 768px) {
          h3 {
            line-height: 8vw;
            font-size: 5vw;
          }
        }
        .txt1, .txt2 {
          margin: 30px auto 0;
          padding: 0 25px;
          width: 100%;
          box-sizing: border-box;
        }
      }
    }
  }
  section.sec3 {
    padding: 80px 0 110px;
    background: #f5f5f5;
    .inner {
      position: relative;
      h3 {
        margin-bottom: 30px;
      }
      .txt1 {
        margin-bottom: 70px;
        width: 550px;
      }
      .ph1 {
        width: 395px;
        top: 30px;
        right: 0;
        position: absolute;
        img {
          width: 100%;
        }
      }
    }
    .inner2 {
      margin: 0 auto;
      max-width: 1000px;
      .ph2 {
        float: left;
        width: 350px;
        img {
          width: 100%;
        }
      }
      .txt2 {
        float: right;
        width: 590px;
      }
    }
    @media all and (min-width: 769px) and (max-width: 1000px) {
      .inner {
        h3, .txt1 {
          width: calc(100% - 420px);
        }
      }
      .inner2 {
        .txt2 {
          width: calc(100% - 380px);
        }
      }
    }
    @include media(sp) {
      background: transparent;
      padding: 35px 0 0;
      .inner {
        h3 {
          margin: 0 0 35px;
          padding-top: 53%;
          letter-spacing: -1px;
        }
        .txt1 {
          margin-bottom: 50px;
          width: 100%;
        }
        .ph1 {
          margin-bottom: 100px;
          width: 66%;
          position: absolute;
          top: 0;
          right: 0;
          z-index: -1;
          img {
            width: 100%;
            height: auto;
            position: static;
            top: auto;
            left: auto;
          }
        }
      }
      .inner2 {
        margin: 0 auto;
        width: 100%;
        .ph2 {
          margin-bottom: 40px;
          float: static;
          width: 100%;
          box-sizing: border-box;
          img {
            width: 100%;
          }
        }
        .txt2 {
          padding: 0 25px;
          float: static;
          width: 100%;
          box-sizing: border-box;
        }
      }
    }
  }
}
