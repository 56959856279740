@charset "UTF-8";

main {
  display: block;
}

[class^="pg-"]:not(.pg-top) {
  .main {
    padding-bottom: 130px;
    @include media(sp) {
      padding-bottom: 90px;
    }
  }
}

.pg-faq,
.pg-company,
.pg-philosophy,
[class^="pg-strength"],
.pg-result,
.pg-national,
.pg-private,
.pg-college,
.pg-privacy,
.pg-flow,
.pg-message,
[class^="pg-result_"],
.pg-teacher,
[class^="pg-teacher_"],
.pg-course,
[class^="pg-course_"],
.pg-contact,
.pg-contact_conf,
.pg-contact_comp,
.pg-contact_parents,
.pg-contact_parents_conf,
.pg-contact_parents_comp,
[class^="pg-campaign20180701_"],
.pg-contents.page-contents,
.pg-contents.page-schedule,
.pg-contents.page-schedule2019,
.pg-contents.page-schedule2020,
.pg-contents.page-template-page-schedule2020-php,
.pg-contents.category,
.pg-contents.date,
.pg-contents.single {
  .page-title {
    font-size: 3rem;
    font-family: $mincho;
    margin-bottom: 35px;
    color: $c-ao;
    font-weight: normal;
    @include media(sp) {
      font-size: 2rem;
      margin-bottom: 20px;
      text-align: center;
    }
    span {
      display: block;
      max-width: $inner-width;
      margin: 0 auto;
      @include media(sp) {
        max-width: none;
        width: auto;
        padding: $sp-pad;
        margin: 0;
      }
    }
  }
  .page-title-text {
    font-size: 1.6rem;
    line-height: 1.5;
    font-feature-settings: "palt";
    margin-bottom: 20px;
    @include media(sp) {
      font-size: 1.4rem;
      margin-bottom: 16px;
    }
    >a {
      color: #000;
      text-decoration: underline;
    }
  }
}

.pg-company,
.pg-experience {
  .page-title {
    border-bottom: 1px solid $c-gray;
    padding-bottom: 9px;
    @include media(sp) {
      padding-bottom: 8px;
    }
  }
}

[class^="pg-result_"] {
  .page-title {
    @include media(sp) {
      border-bottom: 1px solid $c-gray;
      padding-bottom: 8px;
    }
  }
}
