@charset "UTF-8";
.pg-flow {
  h2.page-title {
    margin: 0 auto 20px;
    max-width: 1000px;
  }
  section {
    @include media(sp) {
      font-size: 1.4rem;
    }
  }
  section.head {
    border-bottom: 1px $c-gray solid;
    .head-content {
      padding-top: 20px;
      border-top: 1px $c-gray solid;
    }
    p.page-desc {
      margin-bottom: 60px;
    }
    h3 {
      margin-bottom: 20px;
      font-family: $mincho;
      font-size: 2.5rem;
    }
    .clearfix {
      max-width: 1000px;
    }
    dl {
      margin: 40px 0 70px;
      width: 47%;
      float: left;
      dt {
        margin-bottom: 20px;
        height: 50px;
        line-height: 50px;
        font-family: $mincho;
        font-size: 2rem;
        color: #fff;
        background: $c-ao;
        text-align: center;
      }
    }
    .cf dl:nth-child(2) {
      float: right;
    }
    @include media(sp) {
      border-bottom: none;
      p.page-desc {
        margin-bottom: 0;
      }
      dl {
        margin: 25px 0 0;
        width: 100%;
        float: static;
        dt {
          margin-bottom: 15px;
          height: 40px;
          line-height: 40px;
          font-size: 1.6rem;
        }
      }
      .cf dl:nth-child(2) {
        padding-bottom: 50px;
        border-bottom: 1px $c-gray solid;
        float: static;
      }
    }
  }
  section.sec {
    text-align: center;
    span.num {
      margin: 0 auto 20px;
      border: 1px $c-ao solid;
      width: 86px;
      height: 86px;
      line-height: 84px;
      display: block;
      font-family: $mincho;
      font-size: 3.6rem;
      font-weight: 800;
      border-radius: 50%;
    }
    h3 {
      margin-bottom: 20px;
      font-family: $mincho;
      font-size: 2.5rem;
      color: $c-ao;
    }
    @include media(sp) {
      span.num {
        margin: 0 auto 15px;
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: 2.4rem;
      }
      h3 {
        margin-bottom: 15px;
        font-size: 2.0rem;
      }
      p {
        text-align: left;
      }
    }
  }
  section.sec1 {
    margin: 70px 0 80px;
    p.stress {
      margin-bottom: 20px;
      color: $c-aka;
      font-size: 2.2rem;
      font-weight: 800;
    }
    a.btn {
      margin: 30px auto 0;
      width: 440px;
      height: 88px;
      line-height: 88px;
      background: $c-aka;
      color: #fff;
      font-family: $mincho;
      font-size: 2.5rem;
      display: block;
      position: relative;
      &:after {
        position: absolute;
        right: 20px;
        content: "＞";
        transform: scale( 0.6, 1);
      }
    }
    @include media(sp) {
      margin: 50px 0;
      p.stress {
        margin-bottom: 15px;
        font-size: 1.6rem;
        text-align: center;
      }
      a.btn {
        margin: 30px auto 0;
        width: 100%;
        height: 55px;
        line-height: 55px;
        font-size: 1.8rem;
      }
    }
  }
  section.sec2 {
    margin-bottom: 70px;
    padding-top: 90px;
    width: 100%;
    height: 460px;
    background: url('/img/flow/bg_flow1.png') no-repeat bottom center;
    background-size: auto 460px;
    overflow: hidden;
    box-sizing: border-box;
    span.num {
      background: $c-ao;
      color: #fff;
    }
    h3 {
      margin-bottom: 40px;
      color: #fff;
    }
    p {
      color: #fff;
    }
    @media all and (min-width: 1760px) {
      background-size: cover;
    }
    @include media(sp) {
      padding: 0;
      width: 100%;
      height: 355px;
      background: transparent;
      position: relative;
      &::after {
        margin-right: -20px;
        content: '';
        width: 0;
        height: 0;
        border-top: 20px #505454 solid;
        border-right: 20px transparent solid;
        border-left: 20px transparent solid;
        position: absolute;
        bottom: 0;
        right: 50%;
      }
      .inner {
        padding-top: 35px;
        height: 335px;
        background: url('/img/flow/sp/bg_flow1.png') no-repeat center;
        background-size: cover;
      }
      span.num {
        margin-bottom: 15px;
      }
      h3 {
        margin-bottom: 25px;
      }
    }
  }
  section.sec3 {
    margin-bottom: 420px;
    .box {
      margin: 0 auto 20px;
      border: 1px $c-ao solid;
      width: 270px;
      height: 55px;
      line-height: 55px;
      color: $c-ao;
      font-family: $mincho;
      font-size: 1.9rem;
    }
    .inner {
      position: relative;
    }
    picture {
      margin-left: -207px;
      position: absolute;
      top: 30px;
      left: 50%;
      z-index: -1;
      img {
        width: 415px;
        height: 415px;
      }
    }
    @include media(sp) {
      margin-bottom: 290px;
      span.num {
        margin-bottom: 15px;
      }
      .box {
        width: 200px;
        height: 45px;
        line-height: 45px;
        font-size: 1.8rem;
        text-align: center;
      }
      picture {
        margin-left: -130px;
        position: absolute;
        top: auto;
        bottom: -230px;
        left: 50%;
        z-index: -1;
        img {
          width: 260px;
          height: 260px;
        }
      }
    }
  }
  section.sec4 {
    margin-bottom: 70px;
    padding-bottom: 70px;
    position: relative;
    h3 {
      color: #000;
    }
    p:last-child {
      margin-top: 10px;
    }
    &::after {
      margin-right: -30px;
      content: '';
      width: 60px;
      height: 60px;
      border: 0px;
      border-top: solid 1px #131f4b;
      border-right: solid 1px #131f4b;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
      position: absolute;
      bottom: 0;
      right: 50%;
    }
    @include media(sp) {
      margin-bottom: 60px;
      padding-bottom: 40px;
      p:last-child {
        margin-top: 10px;
        padding-left: 1em;
        text-indent: -1em;
      }
      &::after {
        margin-right: -20px;
        width: 40px;
        height: 40px;
      }
    }
  }
  section.sec5 {
    margin-bottom: 70px;
    padding-top: 120px;
    width: 100%;
    height: 540px;
    background: url('/img/flow/bg_flow2.png') no-repeat bottom center;
    background-size: auto 540px;
    overflow: hidden;
    span.num {
      background: $c-ao;
      color: #fff;
    }
    h3 {
      color: #fff;
    }
    p {
      color: #fff;
      &.stress {
        margin-bottom: 15px;
        font-size: 2.2rem;
        font-weight: 800;
      }
    }
    @media all and (min-width: 1760px) {
      background-size: cover;
    }
    @include media(sp) {
      margin-bottom: 50px;
      padding: 0;
      width: 100%;
      height: 355px;
      background: transparent;
      position: relative;
      &::after {
        margin-right: -20px;
        content: '';
        width: 0;
        height: 0;
        border-top: 20px #373735 solid;
        border-right: 20px transparent solid;
        border-left: 20px transparent solid;
        position: absolute;
        bottom: 0;
        right: 50%;
      }
      .inner {
        padding-top: 35px;
        height: 335px;
        background: url('/img/flow/sp/bg_flow2.png') no-repeat bottom center;
        background-size: cover;
      }
      span.num {
        margin-bottom: 15px;
      }
      h3 {
        margin-bottom: 10px;
      }
      p.stress {
        margin-bottom: 20px;
        font-size: 1.6rem;
        text-align: center;
      }
    }
  }
  section.sec6 {
    h3 {
      color: #000;
    }
    p.stress {
      margin-bottom: 20px;
      color: $c-ao;
      font-size: 2.2rem;
      font-weight: 800;
    }
    @include media(sp) {
      p.stress {
        font-size: 1.6rem;
      }
    }
  }
}
