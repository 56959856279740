@charset "UTF-8";

[class^="btn__"] {
  a {
    text-align: center;
    padding: 14px;
    color: #fff;
    font-size: 1.8rem;
    font-family: $mincho;
    display: inline-block;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      background: url('/img/common/icn_arrow_02_white.svg') left top / 8px no-repeat;
      width: 8px;
      height: 14px;
      top: 50%;
      right: 35px;
      margin-top: -7px;
    }
  }
}
.btn__red {
  a {
    background: $c-aka;
  }
}
.btn__blue {
  a {
    background: $c-ao;
  }
}


.tag {
  span {
    color: #fff;
    text-align: center;
    // width: 68px;
    font-weight: bold;
    padding: 6px 10px;
    line-height: 1;
    display: inline-block;
    font-size: 1.3rem;
    @include media(sp) {
      font-size: 1.2rem;
    }
  }
  &-guardian {
    span {
      background: #0e4625;
    }
  }
  &-event {
    span {
      background: #2e79be;
    }
  }
  &-update {
    span {
      background: #618e22;
    }
  }
  &-news {
    span {
      background: #f1464a;
    }
  }
  &-graduate {
    span {
      background: #fc8b34;
    }
  }
  &-student {
    span {
      background: #51a648;
    }
  }
}


// swiepr
.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  @include media(sp) {
    width: 7px;
    height: 7px;
  }
}
.swiper-pagination-bullet-active {
  background: $c-ao;
}
.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 13px;
  @include media(sp) {
    margin: 0 7px;
  }
}

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 26px;
  @include media(sp) {
    bottom: 0;
    margin-bottom: 3.8%;
    font-size: 0;
  }
}


// ======================
// SEC CONTACT
// ======================
.sec-contact {
  background: $c-ao;
  display: flex;
  @include media(sp) {
    display: none;
  }
  .sec-contact-head {
    font-family: $mincho;
    font-size: 2.1rem;
    color: #fff;
    text-align: center;
    span {
      display: inline-block;
      position: relative;
      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 35px;
        height: 1px;
        background: $c-audo;
        top: 15px;
        transform: rotate(-65deg);
      }
      &::before {
        left: -40px;
      }
      &::after {
        right: -40px;
      }
    }

  }
  [class^="sec-contact-contents-0"] {
    padding: 57px 0 51px;
    height: 240px;
    display: flex;
    p {
      color: #fff;
      text-align: center;
    }
  }
  .sec-contact-contents-01 {
    width: calc(50% + 55px);
    justify-content: flex-end;
    padding-right: 152px;
    .sec-contact-head {
      margin-bottom: 12px;
    }
    .tel {
      font-size: 2.2rem;
      font-family: $font02;
      span {
        font-size: 4.4rem;
        padding-left: 10px;
      }
    }
    .receptiontime {
      font-size: 2rem;
      font-family: $font02;
    }
  }
  .sec-contact-contents-02 {
    width: calc(50% - 55px);
    background: #162867;
    padding-left: 46px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -110px;
      width: 111px;
      height: 240px;
      background: url('/img/common/slash_img.svg') left top / 111px no-repeat;
    }
    .sec-contact-head {
      margin-bottom: 30px;
    }
    .btn__black {
      background: #1b1b1b;
      a {
        width: 289px;
        color: $c-audo;
        &::after {
          right: 25px;
        }
      }
    }
  }
}

.sec-contact-sp {
  display: none;
  @include media(sp) {
    display: block;
    position: fixed;
    width: calc(100% - 20px);
    bottom: 0;
    z-index: 3;
    background: $c-ao;
    display: flex;
    [class^="sec-contact-contents-0"] {
      height: 60px;
      p {
        color: #fff;
        text-align: center;
      }
      a {
        display: block;
        padding-right: 23px;
        position: relative;
        height: 60px;
        line-height: 57px;
        text-align: center;
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 15px;
          margin-top: -5px;
          background: url(/img/common/icn_arrow_02_white.svg) left top / 6px no-repeat;
          width: 6px;
          height: 10px;
        }
      }
      .sec-contact-head {
        font-family: $mincho;
        font-size: 3.4vw;
        position: relative;
        display: inline-block;
      }
    }
    .sec-contact-contents-01 {
      width: calc(50% + 2px);
      padding-right: 0;
      .sec-contact-head {
        padding-left: 7.9%;
        background: url(/img/common/icn_tel.svg) left 3px/7.5% no-repeat;
      }
      .receptiontime {
        font-size: 1rem;
        font-family: $font02;
        span {
          font-size: 1.1rem;
          padding-left: 4px;
        }
      }
      a {
        padding: 10px 13px 0 0;
        line-height: 1.3;
      }
    }
    .sec-contact-contents-02 {
      width: calc(50% - 2px);
      background: #162867;
      padding-left: 0;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -14px;
        width: 15px;
        height: 60px;
        background: url('/img/common/slash_img_sp.svg') left top / 15px no-repeat;
      }
      .sec-contact-head {
        padding-left: 12%;
        background: url(/img/common/icn_contact.svg) left 5px/10.3% no-repeat;
      }
      a {
        padding-right: 16px;
      }
    }
  }
}

.list01-head {
  border-top: 1px solid $c-gray;
  border-bottom: 1px solid $c-gray;
  margin-bottom: 15px;
  a {
    padding: 5px 20px 5px 6px;
    position: relative;
    color: $c-ao;
    font-size: 1.6rem;
    display: block;
    &::after {
      content: '';
      position: absolute;
      background: url('/img/common/icn_arrow_03_blue.svg') left top / 9px no-repeat;
      width: 9px;
      height: 20px;
      top: 50%;
      right: 0;
      margin-top: -10px;
    }
    @include media(sp) {
      font-size: 1.4rem;
    }
  }
}


// ======================
// パンくず
// ======================
.breadcrumb {
  margin-bottom: 40px;
  padding-top: 8px;
  @include media(sp) {
    margin-bottom: 30px;
  }
  ol {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
    li,
    a {
      color: #000;
      font-size: 1.4rem;
      font-family: $mincho;
    }
    li {
      position: relative;
      padding-left: 20px;
      &::before {
        content:'\232a';
        position: absolute;
        left: 9px;
        top: 1px;
        font-size: 1.3rem;
      }
      &:first-child {
        &:before {
          content: normal;
        }
      }
    }
  }
  @include media(sp) {
    font-size: 1.4rem;
    ol {
      li,
      a {
        font-size: 1.2rem;
      }
      li {
        &::before {
        font-size: 1.1rem;
      }
      }
    }
  }
}


// ======================
// 並び替えフィルター
// ======================
.filtr-container {
  margin: -10px;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  align-content: flex-start;
  flex-flow: row wrap;
  @include media(sp) {
    display: block;
    width: 100%;
    margin: -10px 0;
  }
  > div {
    width: percentage(1/3);
    padding: 10px;
    @include media(sp) {
      width: 100%;
      padding: 10px 0;
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  .filtr-item {
    a {
      display: block;
    }
  }

  .meta {
    border: 1px solid #c8c8c8;
    color: #000;
    height: 110px;
    @include media(sp) {
      border-left: 0;
      border-right: 0;
      height: auto;
    }
  }
  .data {
    display: flex;
    border-bottom: 1px solid #c8c8c8;
    p {
      font-family: $mincho;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  p.subject {
    padding: 10px 0;
    background: $c-ao;
    color: #fff;
    width: 127px;
    text-align: center;
    font-size: 1.3rem;
  }
  .data p.name {
    font-size: 1.8rem;
    @include media(sp) {
      font-size: 1.7rem;
    }
    @media all and (max-width: 1000px) {
      padding: 10px 5px;
      font-size: 1.5rem;
    }
  }
  .desc {
    padding: 10px 20px;
    p {
      font-size: 1.2rem;
      line-height: 1.75;
      br {
        display: none;
      }
    }
    @include media(sp) {
      padding: 10px 0;
    }
  }
}
body.pg-teacher_kimura .teacher-container .teacher-item.kimura a,
body.pg-teacher_akagi .teacher-container .teacher-item.akagi a,
body.pg-teacher_kurusu .teacher-container .teacher-item.kurusu a,
body.pg-teacher_maeda .teacher-container .teacher-item.maeda a,
body.pg-teacher_senuma .teacher-container .teacher-item.senuma a,
body.pg-teacher_takamine .teacher-container .teacher-item.takamine a,
body.pg-teacher_takamine2 .teacher-container .teacher-item.takamine2 a,
body.pg-teacher_yokoi .teacher-container .teacher-item.yokoi a {
  opacity: .7;
  pointer-events: none;
}

// ======================
// BOTTOM LINK
// ======================
ul.page-btmlink {
  margin: 0 auto 100px;
  border-left: 1px $c-gray solid;
  border-bottom: 1px $c-gray solid;
  width: 1000px;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  @include media(sp) {
    margin: 0 auto 80px;
    width: 100%;
    display: block;
    border: none;
  }
  li {
    border: 1px $c-gray solid;
    border-left: none;
    border-bottom: none;
    width: calc(100% / 3);
    @include media(sp) {
      border: none;
      width: 100%;
    }
    a {
      padding: 2px;
      width: 100%;
      display: block;
      position: relative;
      transition: .3s ease-in-out;
      box-sizing: border-box;
      font-family: $mincho;
      color: #fff;
      &:hover {
        opacity: .7;
      }
      img {
        width: 100%;
      }
      .ti {
        padding-top: 50px;
        width: 280px;
        position: absolute;
        top: 0;
        left: 25px;
        text-align: center;
        span {
          display: block;
        }
        span.ti1 {
          margin-bottom: 10px;
          padding: 10px 0;
          border: 1px #737373 solid;
          border-right: none;
          border-left: none;
          line-height: 30px;
          font-size: 2.1rem;
          &.sml {
            letter-spacing: -2px;
          }
        }
        span.ti2 {
          line-height: 1;
          font-size: 1rem;
        }
      }
      @include media(sp) {
        padding: 2px 2px 0;
        .ti {
          margin-top: -12%;
          padding-top: 0;
          width: 90%;
          top: 50%;
          left: 5%;
          span.ti1 {
            margin-bottom: 2%;
            padding: 5px 0;
            line-height: 8.4vw;
            font-size: 5.6vw;
          }
          span.ti2 {
            font-size: 2.66vw;
          }
        }
      }
    }
    &.cs {
      pointer-events: none;
      cursor: default;
      .ti span.ti2 {
        font-size: 1.2rem;
      }
      @include media(sp) {
        .ti span.ti2 {
          font-size: 3.4vw;
        }
      }
    }
  }
  @media all and (min-width: 768px) and (max-width: 1020px) {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    width: 100%;
    border: none;
    li {
      width: calc(100% / 2);
      border: none;
      a {
        padding: 2px 2px 0 0;
      }
      a .ti {
        margin-top: -12%;
        padding-top: 0;
        width: 90%;
        top: 50%;
        left: 5%;
        span.ti1 {
          margin-bottom: 2%;
          padding: 5px 0;
          line-height: 4.2vw;
          font-size: 2.8vw;
        }
        span.ti2 {
          font-size: 1.3vw;
        }
      }
    }
  }
}
body.pg-philosophy ul.page-btmlink li.philosophy a,
body.pg-teacher ul.page-btmlink li.teacher a,
body.pg-course ul.page-btmlink li.course a,
body.pg-strength1 ul.page-btmlink li.strength1 a,
body.pg-strength2 ul.page-btmlink li.strength2 a,
body.pg-strength3 ul.page-btmlink li.strength3 a {
  opacity: .5;
  pointer-events: none;
  cursor: default;
}
