@charset "UTF-8";
.pg-result {
  main.main {
    padding-bottom: 0 !important;
  }
  ul.page-link {
    display: none;
  }
  @include media(sp) {
    ul.page-link {
      margin: 0 auto 20px;
      margin-top: -25px;
      width: 94%;
      display: block;
      display: flex;
      li {
        width: 50%;
      }
      li a {
        width: 100%;
        height: 50px;
        line-height: 50px;
        background: #b4b4b4;
        color: #fff;
        text-align: center;
        font-family: $mincho;
        font-size: 1.7rem;
        display: block;
        box-sizing: border-box;
        &.active {
          background: $c-ao;
        }
      }
    }
  }
  .mainvisual {
    margin-bottom: 80px;
    width: 100%;
    height: 410px;
    background-position: center;
    background-size: cover;
    text-align: center;
    font-family: $mincho;
    img {
      padding-top: 100px;
      margin-bottom: 50px;
      margin-bottom: 30px;
      width: 305px;
    }
    span {
      margin-bottom: 15px;
      display: block;
      line-height: 1
    }
    span.year {
      font-size: 3rem;
    }
    span:nth-last-child(2) {
      margin-bottom: 10px;
      font-size: 4rem;
    }
    span:last-child {
      font-size: 7rem;
      color: #ff0000;
      span {
        padding: 0;
        font-size: 4rem;
        display: inline-block;
      }
    }
    @include media(sp) {
      margin-bottom: 0;
      height: 225px;
      img {
        margin-bottom: 13px;
        padding-top: 30px;
        width: 207px;
      }
      span {
        margin-bottom: 10px;
        display: block;
        line-height: 1
      }
      span.year {
        font-size: 2.5rem;
      }
      span:nth-last-child(2) {
        margin-bottom: 10px;
        font-size: 2.7rem;
      }
      span:last-child {
        font-size: 47px;
        color: #ff0000;
        span {
          padding: 0;
          font-size: 2.7rem;
          display: inline-block;
        }
      }
    }
  }
  @include media(sp) {
    .inner {
      padding: $sp-pad;
    }
  }
  #result-sec {
    margin-bottom: 145px;
    @include media(sp) {
      margin-bottom: 60px;
    }
    h2 {
      margin: 0 auto 20px;
      max-width: 1000px;
      border-top: 1px #e2e2e2 solid;
      border-bottom: 1px #e2e2e2 solid;
      height: 70px;
      line-height: 70px;
      font-size: 3rem;
      font-family: $mincho;
      text-align: center;
      color: $c-ao;
      @include media(sp) {
        margin-bottom: 15px;
        padding: 15px 0;
        width: 100%;
        height: auto;
        line-height: 1;
        font-size: 2rem;
        box-sizing: border-box;
      }
    }
    .result-num {
      margin-bottom: 40px;
      font-size: 2.7rem;
      font-weight: 800;
      text-align: center;
      span.year {
        font-size: 1.5rem;
        display: block;
      }
      span.num {
        font-size: 3.1rem;
        color: #ff0000;
        span {
          font-size: 2rem;
        }
      }
      @include media(sp) {
        margin-bottom: 30px;
        font-size: 1.9rem;
        span.year {
          font-size: 1.2rem;
        }
        span.num {
          font-size: 2.1rem;
          span {
            font-size: 1.4rem;
          }
        }
      }
    }
    .result-table {
      display: flex;
      justify-content: space-between;
      > div {
        width: percentage(960/2000);
      }
      @include media(sp) {
        display: block;
        > div {
          width: 100%;
          &.table-r {
            margin-top: 20px;
          }
        }
      }
    }
    .result-table.result-table3clm {
      display: flex;
      justify-content: space-between;
      > div {
        width: 32%;
      }
      @include media(sp) {
        display: block;
        > div {
          width: 100%;
          &.table-m, &.table-r {
            margin-top: 20px;
          }
        }
      }
    }
    .table-wrap {
      margin-top: 20px;
      &:nth-of-type(1) {
        margin-top: 0;
      }
      .title {
        margin-bottom: 2px;
      }
      .title2 {
        margin: 20px 0 2px;
      }
    }
    table {
      border: 1px solid #c8c8c8;
      width: 100%;
      th,
      td {
        border: 1px solid #c8c8c8;
        font-size: 1.4rem;
        line-height: 1.4;
      }
      th {
        background: #f5f5f5;
        text-align: center;
        padding: 6px 0;
        &:nth-child(2) {
          width: 123px;
          @include media(sp) {
            // width: 77px;
            width: 106px;
          }
        }
      }
      td {
        padding: 7px 7px 7px 25px;
        @include media(sp) {
          padding: 7px 14px;
        }
        span {
          display: block;
          font-size: 1.2rem;
        }
        &:nth-child(2) {
          padding: 7px;
          text-align: center;
        }
      }
    }
    .past-result {
      margin-top: 125px;
      padding: 54px 0 70px;
      background: #f5f5f5;
      @include media(sp) {
        margin-top: 50px;
        padding: $sp-pad;
        padding-top: 40px;
        padding-bottom: 40px;
      }
      h3 {
        margin-bottom: 35px;
        font-size: 2.5rem;
        font-family: $mincho;
        text-align: center;
        color: $c-ao;
        @include media(sp) {
          margin-bottom: 20px;
          font-size: 2rem;
        }
      }
      ul {
        margin: 0 auto;
        max-width: 1000px;
        font-family: $mincho;
        display: flex;
        display: -webkit-flex;
        flex-wrap: wrap;
        li {
          margin: 0 10px 10px 0;
          width: 242px;
          height: 50px;
          text-align: center;
          font-size: 1.4rem;
          span:nth-last-child(2) {
            font-size: 1rem;
          }
          span:last-child {
            font-size: 1.2rem;
          }
          &:nth-child(4n+4) {
            margin-right: 0;
          }
          a {
            padding: 0 0 0 20px;
            border: 1px #e2e2e2 solid;
            width: 242px;
            height: 50px;
            line-height: 50px;
            position: relative;
            display: block;
            vertical-align: middle;
            text-decoration: none;
            font-size: 1.5rem;
            color: $c-ao;
            box-sizing: border-box;
            text-align: center;
            background: #fff;
            transition: .3s ease-in-out;
            &:hover {
              opacity: .7;
            }
            &::before,
            &::after{
              position: absolute;
              top: 0;
              bottom: 0;
              left: 60px;
              margin: auto;
              content: "";
              vertical-align: middle;
            }
            &::before{
              width: 16px;
              height: 16px;
              -webkit-border-radius: 50%;
              border-radius: 50%;
              background: #152045;
            }
            &::after{
              left: 66px;
              width: 3px;
              height: 3px;
              border-top: 1px solid #fff;
              border-right: 1px solid #fff;
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
        }
        @include media(sp) {
          width: 100%;
          li {
            margin: 0 2% 1.6% 0;
            width: 48%;
            &:nth-child(even) {
              margin-right: 0;
            }
            span:nth-last-child(2) {
            }
            span:last-child {
              font-size: 1.3rem;
            }
            a {
              padding: 0 0 0 12px;
              width: 100%;
              font-size: 1.4rem;
              &::before,
              &::after{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 5px;
                margin: auto;
                content: "";
                vertical-align: middle;
              }
              &::before{
                width: 14px;
                height: 14px;
                -webkit-border-radius: 50%;
                border-radius: 50%;
                background: #152045;
              }
              &::after{
                left: 10px;
                width: 3px;
                height: 3px;
                border-top: 1px solid #fff;
                border-right: 1px solid #fff;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
              }
            }
          }
        }
      }
    }
  }
  #experience-ti {
    padding-top: 200px;
    width: 100%;
    height: 410px;
    line-height: 1;
    background-position: center;
    background-size: cover;
    text-align: center;
    font-family: $mincho;
    box-sizing: border-box;
    span:first-child {
      margin-bottom: 25px;
      font-size: 7rem;
      color: #be0000;
      display: block;
    }
    span:nth-child(2) {
      font-size: 2.7rem;
    }
    span:nth-child(3) {
      font-size: 1.6rem;
    }
    span:last-child {
      font-size: 2.5rem;
    }
    @include media(sp) {
      padding-top: 65px;
      height: 175px;
      span:first-child {
        margin-bottom: 20px;
        font-size: 2.9rem;
      }
      span:nth-child(2) {
        font-size: 1.9rem;
      }
      span:nth-child(3) {
        font-size: 1.2rem;
      }
      span:last-child {
        font-size: 1.8rem;
      }
    }
  }
  #experience-sec {
    padding-top: 85px;
    background: #f5f5f5;
    @include media(sp) {
      margin-bottom: 60px;
      padding-top: 40px;
    }
    &.private {
      background: #fff;
      @include media(sp) {
        padding-top: 0;
      }
    }
    h3 {
      margin-bottom: 40px;
      font-size: 3rem;
      font-family: $mincho;
      text-align: center;
      color: $c-ao;
      span {
        font-size: 1.5rem;
        display: block;
      }
      &.national span {
        letter-spacing: 1px;
      }
      @include media(sp) {
        margin-bottom: 30px;
        font-size: 2.1rem;
        span {
          font-size: 1.3rem;
        }
        &.national span {
          letter-spacing: 0;
        }
      }
    }
    ul.experience-box {
      margin: 0 auto 50px;
      max-width: 1000px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @include media(sp) {
        margin-bottom: 0;
        width: 100%;
      }
      li {
        margin-bottom: 35px;
        width: 480px;
        background: #fff;
        border: 1px solid $c-gray;
        a {
          padding: 30px 30px 40px;
          display: block;
          height: 100%;
          @include media(sp) {
            padding: 25px 25px 30px;
            width: 100%;
          }
          transition: .3s ease-in-out;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          &:hover {
            opacity: .7;
          }
        }
        &:first-child {
          @include media(sp) {
            margin-bottom: 25px;
          }
        }
        .upper-wrap {
          display: flex;
          align-items: center;
          margin-bottom: 17px;
        }
        .thum {
          width: 155px;
          margin-right: 25px;
          img {
            width: 100%;
            height: auto;
          }
          @include media(sp) {
            width: 85px;
            margin-right: 20px;
          }
        }
        .txt {
          margin-top: 50px;
          font-size: 2rem;
          font-family: $mincho;
          color: $c-ao;
          @include media(sp) {
            flex: 1;
            margin-top: 0;
            font-size: 1.4rem;
          }
          .pass-name {
            display: none;
            @include media(sp) {
              display: block;
              color: #000;
              margin-top: 16px;
              font-family: $base-font-family;
            }
          }
        }
        .name {
          margin-bottom: 10px;
          color: #000;
          @include media(sp) {
            display: none;
          }
        }
        .desc {
          padding-top: 23px;
          border-top: 1px solid $c-gray;
          line-height: 1.67;
          color: #000;
          @include media(sp) {
            padding-top: 20px;
            font-size: 1.3rem;
          }
        }
      }
    }
    a.btn-more {
      margin: 0 auto 54px;
      padding-top: 20px;
      width: 395px;
      height: 80px;
      line-height: 22px;
      font-family: $mincho;
      background: #be0000;
      color: #fff;
      font-size: 1.8rem;
      text-align: center;
      display: block;
      position: relative;
      box-sizing: border-box;
      transition: .3s ease-in-out;
      letter-spacing: 1px;
      cursor: pointer;
      span {
        display: block;
        font-size: 1.3rem;
        letter-spacing: 0;
      }
      &:after {
        margin-top: -11px;
        position: absolute;
        font-size: 2.4rem;
        right: 42px;
        top: 50%;
        content: "＞";
        transform: scale( 0.3, 1);
      }
      &:hover {
        opacity: .7;
      }
      @include media(sp) {
        margin-bottom: 40px;
        width: 100%;
        height: 80px;
        span {
          display: block;
          font-size: 1.4rem;
        }
        &:after {
          right: 10px;
        }
      }
    }
    .experience-list {
      padding: 54px 0 70px;
      border-top: 1px #dbdbdb solid;
      border-bottom: 1px #dbdbdb solid;
      background: #ebebeb;
      @include media(sp) {
        padding: $sp-pad;
        padding-top: 40px;
        padding-bottom: 40px;
        border: none;
      }
      h4 {
        margin-bottom: 35px;
        font-size: 2.5rem;
        font-family: $mincho;
        text-align: center;
        color: $c-ao;
        br {
          display: none;
        }
        @include media(sp) {
          margin-bottom: 20px;
          line-height: 3rem;
          font-size: 2rem;
          br {
            display: block;
          }
        }
      }
      ul {
        margin: 0 auto;
        max-width: 1000px;
        font-family: $mincho;
        display: flex;
        display: -webkit-flex;
        flex-wrap: wrap;
        li {
          margin: 0 10px 10px 0;
          width: 242px;
          height: 50px;
          text-align: center;
          font-size: 1.4rem;
          &:nth-child(4n+4) {
            margin-right: 0;
          }
          a {
            padding: 0 0 0 20px;
            border: 1px #e2e2e2 solid;
            width: 242px;
            height: 50px;
            line-height: 50px;
            position: relative;
            display: block;
            vertical-align: middle;
            text-decoration: none;
            font-size: 1.4rem;
            color: $c-ao;
            box-sizing: border-box;
            text-align: center;
            background: #fff;
            transition: .3s ease-in-out;
            &:hover {
              opacity: .7;
            }
            &::before,
            &::after{
              position: absolute;
              top: 0;
              bottom: 0;
              left: 15px;
              margin: auto;
              content: "";
              vertical-align: middle;
            }
            &::before{
              width: 16px;
              height: 16px;
              -webkit-border-radius: 50%;
              border-radius: 50%;
              background: #be0000;
            }
            &::after{
              left: 21px;
              width: 3px;
              height: 3px;
              border-top: 1px solid #fff;
              border-right: 1px solid #fff;
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
        }
        @include media(sp) {
          width: 100%;
          li {
            margin: 0 2% 1.6% 0;
            width: 48%;
            &:nth-child(even) {
              margin-right: 0;
            }
            span {
              display: none;
            }
            a {
              padding: 0 0 0 5px;
              width: 100%;
              font-size: 1.4rem;
              &::before,
              &::after{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 5px;
                margin: auto;
                content: "";
                vertical-align: middle;
              }
              &::before{
                width: 14px;
                height: 14px;
                -webkit-border-radius: 50%;
                border-radius: 50%;
              }
              &::after{
                left: 10px;
                width: 3px;
                height: 3px;
                border-top: 1px solid #fff;
                border-right: 1px solid #fff;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
              }
            }
          }
        }
      }
    }
  }
}

@import 'result_year';
