/*
#styleguide
基本スタイル

```
<p>特に何も指定しないときの状態</p>
```
*/

/* Clearfix
------------------------*/
.cf:after {
  content: '';
  display: block;
  clear: both;
}

body {
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: $font-smoothing;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: $font-smoothing;
  text-rendering: $text-rendering;
  font-family: $base-font-family;
  color: $base-font-color;
  background-color: $base-background-color;
  -webkit-font-smoothing: antialiased;
}

.wrapper {
  position: relative;
  overflow: hidden;
}

.is-pc {
  @include media(sp) {
    display: none;
  }
}
.is-sp {
  display: none;
  @include media(sp) {
    display: block;
  }
}

.fade {
  transition: .3s ease-in-out;
  &:hover {
    opacity: .7;
  }
}
.hardfade {
  transition: .3s ease-in-out;
  &:hover {
    opacity: .4;
  }
}

.inner {
  max-width: $inner-width;
  margin: 0 auto;
  @include media(sp) {
    max-width: none;
    width: auto;
    padding: $sp-pad;
    padding: 0 25px;
    margin: 0;
  }
}
