@charset "UTF-8";
.pg-contents {
  .contents-wrapper {
    padding-bottom: 130px;
    @include media(sp) {
      padding-bottom: 90px;
    }
  }
  .contents-inner {
    display: flex;
    justify-content: space-between;
    .main {
      width: 750px;
    }
    .sidebar {
      width: 220px;
    }
    @include media(sp) {
      display: block;
      padding: 0;
      .main {
        width: 100%;
      }
      .sidebar {
        width: 100%;
      }
    }
  }

  .post-meta {
    display: flex;
    align-items: center;
    .date {
      margin-left: 14px;
      font-size: 1.6rem;
      font-family: $font02;
      @include media(sp) {
        font-size: 1.2rem;
      }
    }
  }

  .pagenation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    ul {
      display: flex;
      li {
        border: 1px solid #c8c8c8;
        border-left: 0;
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        font-family: $font02;
        margin-top: 0;
        &.pn-1:not(.active) {
          border-left: 1px solid #c8c8c8;
        }
        a {
          display: block;
          color: #919191;
          font-family: $font02;
        }
        &.active {
          border: $c-ao;
          background: $c-ao;
          color: #fff;
        }
        &.prev,
        &.next {
          width: 8px;
          height: 25px;
          line-height: normal;
          border: 0;
        }
        &.prev {
          margin-right: 24px;
          img {
            transform: scale(-1, 1);
          }
        }
        &.next {
          margin-left: 24px;
        }
      }
    }
  }

}

body.error404 {
  #main {
    padding: 70px 0 200px;
    text-align: center;
    font-family: $mincho;
    h1 {
      margin-bottom: 30px;
      font-size: 4rem;
      font-weight: bold;
      color: #959595
    }
    h2 {
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px $c-gray solid;
      font-size: 3rem;
      font-weight: bold;
      color: $c-ao;
    }
    p {
      font-size: 1.5rem;
    }
    @include media(sp) {
      padding: 40px 20px 100px;
      box-sizing: border-box;
      h1 {
        font-size: 3rem;
      }
      h2 {
        margin-bottom: 20px;
        padding-bottom: 20px;
        line-height: 1.8;
        font-size: 1.8rem;
      }
      p {
        font-size: 1.4rem;
      }
    }
  }
}
