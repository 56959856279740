@charset "UTF-8";
[class^="pg-teacher_"] {
  .mv {
    display: flex;
    margin-bottom: 45px;
    @include media(sp) {
      display: block;
      margin-bottom: 30px;
    }
    .thum {
      width: percentage(700/2000);
      img {
        width: 100%;
        height: auto;
      }
      @include media(sp) {
        width: 100%;
      }
    }
    .field {
      flex: 1;
      background: $c-ao;
      color: #fff;
      font-family: $mincho;
      display: flex;
      align-items: center;
      padding-left: 60px;
      position: relative;
      @include media(sp) {
        padding: 25px;
      }
    }
    .phrase {
      font-size: 2.2rem;
      margin-bottom: 25px;
      &::after {
        content: '';
        width: 100%;
        height: 1px;
        background: #2e489e;
        position: absolute;
        left: 0;
        margin-top: 44px;
      }
      @include media(sp) {
        font-size: 2rem;
        margin-bottom: 30px;
        &::after {
          margin-top: 43px;
        }
        br {
          display: none;
        }
      }
    }
    .name {
      font-size: 2rem;
      margin-bottom: 12px;
      font-weight: normal;
      span {
        font-size: 1.4rem;
        margin-right: 14px;
      }
      @include media(sp) {
        span {
          display: block;
          margin-right: 0;
        }
      }
    }
    .education {
      font-size: 1.4rem;
    }
  }

  .phrase02 {
    font-family: $mincho;
    font-size: 1.9rem;
    margin-bottom: 21px;
    text-align: center;
    font-weight: normal;
    @include media(sp) {
      text-align: left;
      font-size: 1.8rem;
    }
  }
  .phrase-desc {
    font-size: 1.4rem;
    line-height: 1.85;
    margin-bottom: 52px;
    @include media(sp) {
      margin-bottom: 50px;
    }
  }

  .message {
    padding: 57px 45px;
    background: #f5f5f5;
    border: 1px solid #c8c8c8;
    margin-bottom: 70px;
    @include media(sp) {
      border: none;
      padding: 25px;
      margin: 0 -25px 45px;
    }
    .row {
      display: flex;
      @include media(sp) {
        display: block;
      }
      > div {
        &:nth-child(1) {
          width: 210px;
          .head {
            font-family: $q-min;
            font-size: 2.5rem;
            margin-bottom: 10px;
            font-weight: normal;
          }
          img {
            width: 100%;
            height: auto;
          }
          @include media(sp) {
            width: 100%;
            margin-bottom: 20px;
            position: relative;
            .head {
              position: absolute;
              top: 50%;
              left: 0;
              margin-top: -12px;
              margin-left: 47%;
            }
            img {
              width: percentage(330/610);
            }
          }
        }
        &:nth-child(2) {
          flex: 1;
          margin-left: 50px;
          @include media(sp) {
            margin-left: 0;
          }
          .subhead {
            font-size: 1.8rem;
            margin-bottom: 12px;
            margin-top: 30px;
            font-family: $mincho;
            &:nth-of-type(1) {
              margin-top: 0;
            }
            @include media(sp) {
              margin-top: 30px;
              span {
                font-size: 2.3rem;
                position: relative;
                top: 2px;
                margin: 0 3px;
              }
            }
          }
          .desc {
            margin-bottom: 15px;
            font-size: 1.4rem;
            line-height: 1.85;
            @include media(sp) {
              font-size: 1.3rem;
            }
          }
        }
      }
    }
  }

  .other-teacher {
    .head {
      font-size: 2.2rem;
      font-family: $mincho;
      text-align: center;
      color: $c-ao;
      font-weight: 500;
      margin-bottom: 30px;
      @include media(sp) {
        font-size: 2rem;
        margin-bottom: 20px;
      }
    }
    .thum {
      img {
        width: 100%;
        height: auto;
      }
    }
  }


}
