@charset "UTF-8";
.address-area + input[type="text"]{
  margin-bottom: 10px;
}
.pg-contact {
  .form-errors {
    border: 1px solid #cc0000;
    padding: 8px 10px;
    margin-bottom: 20px;
    li {
      line-height: 1.5;
      font-size: 1.6rem;
      font-weight: bold;
      color: #cc0000;
      padding: 4px 0 4px 20px;
      position: relative;
    }
  }
  .form {
    dl {
      border-top: 1px solid $c-usui02;
      border-bottom: 1px solid $c-usui02;
      dt,
      dd {
        padding: 20px 0;
        font-family: $mincho;
        @include media(sp) {
          font-size: 1.4rem;
        }
      }
      dt {
        clear: both;
        // width: 200px;
        width: 360px;
        padding-right: 20px;
        float: left;
        span {
          font-size: 1.2rem;
        }
        @include media(sp) {
          width: 100%;
          float: none;
          padding-bottom: 0;
          margin-bottom: 10px;
          border-top: 1px solid $c-usui02;
          &:first-child {
            border-top: 0;
          }
        }
      }
      dd {
        width: 100%;
        padding-left: 424px;
        border-top: 1px solid $c-usui02;
        &:nth-of-type(1) {
          border-top: 0;
        }
        span.kome {
          margin-top: 5px;
          line-height: 1.5;
          font-size: 80%;
          font-family: $base-font-family;
          display: block;
          color: #777;
        }
        @include media(sp) {
          padding-left: 0;
          padding-top: 0;
          border-top: 0;
          &:nth-of-type(1) {
            border-top: 0;
          }
        }
      }
    }
    .type-radio {
      display: flex;
      flex-wrap: wrap;
      @include media(sp) {
        display: block;
      }
      > div {
        margin: 0 45px 5px 0;
        @include media(sp) {
          margin-top: 10px;
          &:nth-child(1) {
            margin-top: 0;
          }
          display: flex;
          align-items: center;
        }
      }
      &.type-radio-week {
        margin-bottom: 10px;
        > div {
          margin-right: 40px;
          @include media(sp) {
            margin-top: 0;
            display: flex;
            align-items: center;
            margin-bottom: 5px;
          }
        }
        @include media(sp) {
          display: flex;
          margin-bottom: 5px;
        }
      }
    }
    .ast {
      position: relative;
      &::after {
        content: '\002a';
        color: #fe0000;
        font-size: 1rem;
        position: relative;
        top: -4px;
        margin-left: 3px;
      }
    }
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
      background: #efefef;
      border: 1px solid $c-usui02;
      padding: 10px;
      font-size: 1.4rem;
      width: 100%;
      border-radius: 0;
    }
    textarea {
      height: 187px;
    }
    .address-area + input[type="text"]{
      margin-bottom: 10px;
    }
    .address-area {
      input[name="pref"] {
        margin-bottom: 10px;
      }
      @include media(sp) {
        position: relative;
        padding-top: 15px;
      }
    }
    .input-02 {
      display: flex;
      justify-content: space-between;
      > input {
        width: percentage(560/1154);
      }
      @include media(sp) {
        display: block;
        > input {
          width: 100%;
          &:nth-child(2) {
            margin-top: 10px;
          }
        }
      }
    }
    .zip-area {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      p {
        &:first-child {
          margin-right: 45px;
          @include media(sp) {
            display: none;
          }
        }
      }
      @include media(sp) {
        position: absolute;
        top: -41px;
        left: 0;
        width: 100%;
        padding-left: 50px;
      }
    }
    .zip-num {
      display: flex;
      align-items: center;
      @include media(sp) {
        width: 56%;
      }
    }
    .zip01 {
      width: 88px;
    }
    .zip-space {
      margin: 0 10px;
      @include media(sp) {
        margin: 0 7px;
      }
    }
    .zip02 {
      width: 99px;
    }
    .address-search-btn {
      margin-left: auto;
      a {
        display: block;
        background: $c-ao;
        text-align: center;
        color: #fff;
        font-family: $mincho;
        font-size: 1.6rem;
        padding: 8px 10px;
        width: 214px;
      }
      @include media(sp) {
        // margin-left: 15px;
        a {
          width: auto;
          font-size: 1.4rem;
        }
      }
    }
    input[name="address01"] {
      margin-bottom: 20px;
    }
    input[name="time"] {
      margin-top: 18px;
    }

    .check-agree {
      text-align: center;
      margin: 52px 0 20px;
      font-family: $mincho;
      font-size: 1.8rem;
      input {
        position: relative;
        top: -1px;
      }
      @include media(sp) {
        margin: 35px 0 20px;
      }
    }

    .questionnaire-lead-01 {
      text-align: center;
      font-size: 1.4rem;
      line-height: 1.92;
      margin-bottom: 32px;
      font-family: $mincho;
      a {
        color: inherit;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
      @include media(sp) {
        font-size: 1.2rem;
        margin-bottom: 23px;
      }
    }
    .questionnaire-lead-02 {
      text-align: center;
      font-size: 1.2rem;
      font-family: $mincho;
    }
    button,
    .form-btns a {
      background: #be0000;
      font-size: 1.8rem;
      text-align: center;
      color: #fff;
      width: 278px;
      border: 0;
      margin: 18px auto 0;
      font-family: $mincho;
      padding: 19px 20px;
      cursor: pointer;
      display: block;
      transition: .3s ease-in-out;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 15px;
        margin-top: -8px;
        background: url(/img/common/icn_arrow_02_white.svg) left top / 10px no-repeat;
        width: 10px;
        height: 16px;
      }
      &:hover {
        opacity: .7;
      }
      @include media(sp) {
        font-size: 1.6rem;
        padding: 12px 70px;
        width: auto;
        margin-top: 28px;
      }
    }


  }
  .questionnaire-area {
    margin-top: 108px;
    .sub-head {
      font-family: $mincho;
      color: $c-ao;
      margin-bottom: 35px;
      text-align: center;
      font-size: 2.5rem;
      font-weight: normal;
    }
    @include media(sp) {
      margin-top: 40px;
      .sub-head {
        font-size: 1.8rem;
        margin-bottom: 22px;
        span {
          display: block;
        }
      }
    }
  }
  .questionnaire-content {
    padding: 32px 0;
    border-top: 1px solid $c-usui02;
    &:nth-of-type(2) {
      border-bottom: 1px solid $c-usui02;
    }
    @include media(sp) {
      padding: 25px 0;
    }
    .content-head {
      font-family: $mincho;
      color: $c-ao;
      margin-bottom: 25px;
      font-size: 2.1rem;
      font-weight: normal;
      @include media(sp) {
        font-size: 1.6rem;
        margin-bottom: 15px;
      }
    }
    .form-q {
      display: flex;
      flex-wrap: wrap;
      @include media(sp) {
        display: block;
      }
      > div {
        margin-right: 58px;
        margin-bottom: 10px;
        @include media(sp) {
          display: flex;
          font-size: 1.4rem;
          label {
            flex: 1;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.pg-contact_conf,
.pg-campaign20180701_conf {
  .lead {
    margin-bottom: 18px;
    @include media(sp) {
      font-size: 1.4rem;
    }
  }
  .form {
    dl {
      border-top: 1px solid $c-usui02;
      border-bottom: 1px solid $c-usui02;
      margin-bottom: 30px;
      dt,
      dd {
        padding: 20px 0;
        font-family: $mincho;
        @include media(sp) {
          font-size: 1.4rem;
        }
      }
      dt {
        clear: both;
        padding-right: 20px;
        float: left;
        span {
          font-size: 1.2rem;
        }
        @include media(sp) {
          width: 100%;
          float: none;
          padding-bottom: 0;
          margin-bottom: 10px;
          border-top: 1px solid $c-usui02;
          &:first-child {
            border-top: 0;
          }
        }
      }
      dd {
        width: 100%;
        padding-left: 424px;
        border-top: 1px solid $c-usui02;
        min-height: 65px;
        &:nth-of-type(1) {
          border-top: 0;
        }
        &.genre,
        &.convenient-day,
        &.started,
        &.interest {
          span {
            &:nth-of-type(1) {
              span {
                display: none;
              }
            }
          }
        }
        @include media(sp) {
          padding-left: 0;
          padding-top: 0;
          border-top: 0;
          min-height: auto;
          &:nth-of-type(1) {
            border-top: 0;
          }
        }
      }
    }
    .type-radio {
      display: flex;
      flex-wrap: wrap;
      @include media(sp) {
        display: block;
      }
      > div {
        margin-right: 45px;
      }
      &.type-radio-week {
        > div {
          margin-right: 40px;
        }
        @include media(sp) {
          display: flex;
        }
      }
    }
    .ast {
      position: relative;
      &::after {
        content: '\002a';
        color: #fe0000;
        font-size: 1rem;
        position: relative;
        top: -4px;
        margin-left: 3px;
      }
    }
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
      background: #efefef;
      border: 1px solid $c-usui02;
      padding: 10px;
      font-size: 1.4rem;
      width: 100%;
    }
    textarea {
      height: 187px;
    }
    .address-area {
      @include media(sp) {
        position: relative;
        padding-top: 15px;
      }
    }
    .input-02 {
      display: flex;
      justify-content: space-between;
      > input {
        width: percentage(560/1154);
      }
      @include media(sp) {
        display: block;
        > input {
          width: 100%;
          &:nth-child(2) {
            margin-top: 10px;
          }
        }
      }
    }
    .zip-area {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      p {
        &:first-child {
          margin-right: 45px;
          @include media(sp) {
            display: none;
          }
        }
      }
      @include media(sp) {
        position: absolute;
        top: -41px;
        left: 0;
        width: 100%;
        padding-left: 50px;
      }
    }
    .zip-num {
      display: flex;
      align-items: center;
      @include media(sp) {
        width: 56%;
      }
    }
    .zip01 {
      width: 88px;
    }
    .zip-space {
      margin: 0 10px;
      @include media(sp) {
        margin: 0 7px;
      }
    }
    .zip02 {
      width: 99px;
    }
    .address-search-btn {
      margin-left: auto;
      a {
        display: block;
        background: $c-ao;
        text-align: center;
        color: #fff;
        font-family: $mincho;
        font-size: 1.6rem;
        padding: 8px 10px;
        width: 214px;
      }
      @include media(sp) {
        // margin-left: 15px;
        a {
          width: auto;
          font-size: 1.4rem;
        }
      }
    }
    input[name="address01"] {
      margin-bottom: 20px;
    }
    input[name="time"] {
      margin-top: 18px;
    }

    .check-agree {
      text-align: center;
      margin: 52px 0 20px;
      font-family: $mincho;
      font-size: 1.8rem;
      input {
        position: relative;
        top: -1px;
      }
      @include media(sp) {
        margin: 35px 0 20px;
      }
    }

    .questionnaire-lead-01 {
      text-align: center;
      font-size: 1.4rem;
      line-height: 1.92;
      margin-bottom: 32px;
      font-family: $mincho;
      a {
        color: inherit;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
      @include media(sp) {
        font-size: 1.2rem;
        margin-bottom: 23px;
      }
    }
    .questionnaire-lead-02 {
      text-align: center;
      font-size: 1.2rem;
      font-family: $mincho;
    }
    .form-btns {
      display: flex;
      justify-content: center;
      @include media(sp) {
        display: block;
      }
    }
    button,
    .form-btns a {
      background: #be0000;
      font-size: 1.8rem;
      text-align: center;
      color: #fff;
      width: 278px;
      border: 0;
      margin: 18px 0 0;
      font-family: $mincho;
      padding: 19px 20px;
      cursor: pointer;
      display: block;
      transition: .3s ease-in-out;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 15px;
        margin-top: -8px;
        background: url(/img/common/icn_arrow_02_white.svg) left top / 10px no-repeat;
        width: 10px;
        height: 16px;
      }
      &:hover {
        opacity: .7;
      }
      @include media(sp) {
        font-size: 1.6rem;
        padding: 12px 70px;
        width: auto;
        margin-top: 0;
      }
    }
  }
  .form-submit {
    clear: both;
  }
  .submit-text {
    text-align: center;
    margin-bottom: 20px;
    @include media(sp) {
      font-size: 1.4rem;
    }
  }
  .form {
    .form-btns {
      .btn-revise {
        background: #333;
      }
      .btn-send {
        margin-left: 20px;
        @include media(sp) {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
  }
}

.pg-contact_comp,
.pg-campaign20180701_comp {
  .message {
    p {
      font-family: $mincho;
      &:nth-child(1) {
        margin-bottom: 20px;
      }
      &:nth-child(2) {
        margin-bottom: 35px;
      }
      a {
        color: inherit;
      }
    }
    @include media(sp) {
      margin-top: 30px;
      p {
        font-size: 1.4rem;
        &:nth-child(2) {
          margin-bottom: 40px;
        }
      }
    }
    .btn__blue {
      a {
        color: #fff;
        padding: 14px 45px;
        &::after {
          right: 20px;
        }
      }
      @include media(sp) {
        text-align: center;
        a {
          padding: 12px;
          width: 64%;
          font-size: 1.6rem;
          &::after {
            right: 15px;
          }
        }
      }
    }
  }
}
