@charset "UTF-8";
.pg-contents.single {
  .main {
    @include media(sp) {
      margin-bottom: 50px;
    }
  }
  .page-title {
    margin-bottom: 10px;
    @include media(sp) {
      text-align: left;
      // font-size: 1.8rem;
      font-size: 2rem;
    }
  }
  .article-wrap {
    border: 1px solid #e2e2e2;
    padding: 53px 45px;
    @include media(sp) {
      padding: 25px;
      border: 0;
      border-bottom: 1px solid #e2e2e2;
    }
  }
  .post-meta {
    display: flex;
    align-items: center;
    .date {
      margin-left: 14px;
      font-size: 1.6rem;
      font-family: $font02;
      @include media(sp) {
        font-size: 1.2rem;
      }
    }
  }
  .article-wrap {
    .post-meta {
      margin-bottom: 35px;
      @include media(sp) {
        margin-bottom: 15px;
      }
    }
  }
  .article {
    p,
    h1,
    h2,
    h3,
    h4 {
      line-height: 2.31;
      font-size: 1.6rem;
      @include media(sp) {
        line-height: 1.77;
        font-size: 1.4rem;
      }
    }
    p {
    margin-bottom: 38px;
     @include media(sp) {
       margin-bottom: 21px;
     }
    }
    img {
      max-width: 100%;
      height: auto;
    }

    // エディタ項目
    .atl_inner {
      overflow: hidden;
    }
    .aligncenter {
        display: block;
        margin: 0 auto;
    }
    .alignright { float: right; }
    .alignleft { float: left; }
  }

  .page-link-area {
    display: flex;
    justify-content: center;
    margin-top: 118px;
    // font-size: 2.6rem;
    @include media(sp) {
      justify-content: center;
      margin-bottom: 40px;
      margin-top: 68px;
    }

    .separate {
      width: 1px;
      background: #c6c6c6;
      margin: 4px 15px 0;
      height: 19px;
    }
    .txt {
      margin-top: 1px;
      display: inline-block;
    }
    a {
      color: inherit;
      transition: .3s ease-in-out;
      &:hover {
        opacity: .7;
      }
    }
    .no-link {
      color: #c6c6c6;
      .arw {
        opacity: .4;
      }
    }
    .prev {
      .arw {
        img {
          transform: scale(-1, 1);
        }
      }
      .txt {
        margin-left: 5px;
      }
    }
    .next {
      .txt {
        margin-right: 5px;
      }
    }
  }
}

// 生徒・保護者メッセージ
.pg-contents.single {
  main.message {
    .page-title {
      margin-bottom: 20px;
      padding-bottom: 30px;
      border-bottom: 1px $c-gray solid;
      display: block;
      br {
        display: none;
      }
      @include media(sp) {
        margin-bottom: 15px;
        padding-bottom: 13px;
        font-size: 2rem;
        br {
          display: block;
        }
      }
    }
    .page-desc {
      margin-bottom: 50px;
      @include media(sp) {
        margin-bottom: 45px;
        font-size: 1.4rem;
        letter-spacing: -1px;
        text-align: center;
      }
    }
    .top-prof {
      margin-bottom: 80px;
      padding: 40px 0;
      background: #ebebeb;
      .inner {
        display: flex;
        display: -webkit-flex;
      }
      .ph {
        margin: 0 auto 0 0;
        width: 210px;
        height: 200px;
        img {
          width: 100%;
        }
      }
      .txt {
        margin: 0 0 0 auto;
        width: 730px;
        h1 {
          margin-bottom: 20px;
          line-height: 3.8rem;
          font-size: 2.4rem;
          color: $c-ao;
        }
        h2, p {
          line-height: 2.5rem;
          font-size: 1.6rem;
          font-weight: 800;
          &.school {
            margin-bottom: 10px;
          }
        }
      }
      @include media(sp) {
        padding: 25px 0;
        .ph {
          margin: 0 20px 0 auto;
          width: 120px;
          height: auto;
          img {
            width: 120px;
          }
        }
        .txt {
          margin: 0 auto 0 0;
          width: auto;
          box-sizing: border-box;
          h1 {
            margin-bottom: 15px;
            line-height: 2.3rem;
            font-size: 1.5rem;
            color: #000;
          }
          h2, p {
            line-height: 2.1rem;
            font-size: 1.2rem;
          }
        }
      }
    }
    .content-box {
      h3 {
        margin: 80px 0 40px;
        border: 1px #e5e5e5 solid;
        border-right: none;
        border-left: none;
        p {
          margin: 0 auto;
          padding-left: 60px;
          max-width: 1000px;
          height: 70px;
          line-height: 70px;
          font-size: 2.8rem;
          font-family: $mincho;
          color: $c-ao;
          display: block;
          position: relative;
          box-sizing: border-box;
        }
        span {
          width: 100px;
          border-top: 1px $c-aka solid;
          display: block;
          transform: rotate(-70deg);
          position: absolute;
          top: 34px;
          left: -25px;
        }
      }
      p {
        margin: 0 auto 10px;
        max-width: 1000px;
        line-height: 2.6rem;
        font-size: 1.6rem;
      }
      @include media(sp) {
        h3 {
          margin: 50px 0 25px;
          padding: 10px 25px;
          height: auto;
          p {
            padding-left: 30px;
            width: 100%;
            height: auto;
            line-height: 1.5;
            font-size: 1.6rem;
            box-sizing: border-box;
          }
          span {
            margin-top: -1px;
            width: 80px;
            border-top: 1px $c-aka solid;
            display: block;
            position: absolute;
            top: 50%;
            left: -25px;
          }
        }
        p {
          padding: 0 25px;
          width: 100%;
          line-height: 2.2rem;
          font-size: 1.2rem;
          box-sizing: border-box;
        }
      }
    }
    .experience-list {
      margin-top: 120px;
      padding: 40px 0 55px;
      width: 100%;
      &.parents {
        margin-bottom: 100px;
        padding: 50px 0 55px;
        background: #ebebeb;
      }
      @include media(sp) {
        padding: 25px 20px 45px;
        box-sizing: border-box;
        &.parents {
          margin-bottom: 70px;
          padding: 50px 20px 45px;
        }
      }
      h2 {
        margin-bottom: 50px;
        text-align: center;
        color: $c-ao;
        font-family: $mincho;
        @include media(sp) {
          margin-bottom: 40px;
          font-size: 2rem;
        }
      }
      ul {
        margin: 0 auto 35px;
        max-width: 1000px;
        display: flex;
        overflow: hidden;
        li {
          margin-right: 20px;
          border: 1px #e5e5e5 solid;
          width: 235px;
          line-height: 24px;
          background: #fff;
          font-size: 1.4rem;
          font-weight: 800;
          a {
            padding: 20px 20px 35px;
            width: 235px;
            box-sizing: border-box;
            color: #000;
            display: block;
            transition: .3s ease-in-out;
            &:hover {
              opacity: .7;
            }
          }
          .prof {
            .ph {
              margin-bottom: 20px;
              width: 195px;
              height: 135px;
              background-size: cover;
              background-position: center;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
        @include media(sp) {
          margin-bottom: 0;
          width: 100%;
          display: block;
          li {
            margin: 0 0 25px 0;
            border: 1px #e5e5e5 solid;
            width: 100%;
            line-height: 25px;
            font-size: 1.5rem;
            a {
              padding: 20px 20px 30px;
              width: 100%;
            }
            .prof {
              display: flex;
              align-items: center;
              .ph {
                margin: 0 20px 0 0;
                width: 110px;
                height: 75px;
              }
              .txt {
                flex-grow: 1;
              }
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
    .experience-list {
      background: #ebebeb;
      .inner.more-info {
        text-align: right;
        a {
          padding-right: 20px;
          position: relative;
          display: inline-block;
          font-size: 18px;
          color: $c-ao;
          font-family: $mincho;
          text-decoration: none;
          transition: .3s ease-in-out;
          &:hover {
            opacity: .7;
          }
        }
        @include media(sp) {
          padding:  0;
          a {
            font-size: 1.6rem;
          }
        }
        a:after {
          margin: auto 0 auto auto;
          width: 8px;
          height: 8px;
          border-top: 2px solid #be0000;
          border-right: 2px solid #be0000;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          content: "";
          vertical-align: middle;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }
}

// 年間計画
.pg-schedule,
.pg-schedule2019 ,
.pg-schedule2020 ,
.page-template-page-schedule2020-php {
  .table-box {
    margin-bottom: 70px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  h2 {
  }
  p.txt {
    margin-top: 20px;
    font-size: 1.4rem;
  }
  table {
    margin-top: 20px;
    font-size: 1.2rem;
    border: 1px solid $c-gray;
    border-bottom: none;
    width: 100%;
    box-sizing: border-box;
    word-wrap: break-word;
    th {
      border-right: 1px solid #EDF0F6;
      text-align: center;
      background: #C1CCE0;
      &:first-child {
        width: 15%;
      }
      &:nth-child(2), &:nth-child(3), &:nth-child(4) {
        width: 5%;
      }
    }
    td {
      border-right: 1px solid $c-gray;
      border-bottom: 1px solid $c-gray;
      line-height: 1.4rem;
      &:nth-child(2), &:nth-child(3), &:nth-child(4) {
        text-align: center;
      }
    }
    th, td {
      padding: 10px 5px;
      vertical-align: middle;
      &:last-child {
        border-right: none;
      }
    }
    tbody tr:nth-child(even) {
      td {
        background: #EDF0F6;
      }
    }
  }
  @include media(sp) {
    h2 {
      font-size: 1.6rem;
      text-align: center;
    }
    p.txt {
      font-size: 1.3rem;
      &.is-sp {
        display: none;
      }
    }
  }
  @media all and (max-width: 440px) {
    .table-box {
      width: 100%;
      overflow-x: scroll;
    }
    table {
      margin-top: 0;
      width: 400px;
      table-layout: fixed;
      th {
        line-height: 1.4rem;
        &:first-child {
          width: 20%;
        }
        &:nth-child(2), &:nth-child(3), &:nth-child(4) {
          width: 5%;
        }
        &:nth-child(5) {
          width: 30%;
        }
        &:last-child {
          width: 30%;
        }
      }
      th, td {
        padding: 5px;
      }
    }
    p.txt.is-sp {
      margin: 10px 0 5px;
      display: block;
    }
  }
}
