@charset "UTF-8";
.pg-company {
  .list-area {
    @include media(sp) {
      margin-top: 50px;
    }
  }
  dl {
    dt,
    dd {
      padding: 20px 0;
      font-family: $mincho;
      @include media(sp) {
        font-size: 1.4rem;
      }
    }
    dt {
      clear: both;
      width: 150px;
      padding-right: 20px;
      float: left;
      @include media(sp) {
        width: 80px;
      }
    }
    dd {
      width: 100%;
      padding-left: 154px;
      border-top: 1px solid $c-gray;
      &:nth-of-type(1) {
        border-top: 0;
      }
      @include media(sp) {
        padding-left: 100px;
        &:nth-of-type(1) {
          border-top: 0;
        }
      }
    }
  }
  #map {
    width: 100%;
    height: 450px;
    background: #ddd;
    margin-top: 67px;
    @include media(sp) {
      margin-top: 7px;
      height: 360px;
    }
  }
}
