@charset "UTF-8";
.pg-contents.page-contents,
.pg-contents.category,
.pg-contents.date {
  .page-title {
    margin-bottom: 12px;
  }
  .list-wrap {
    border: 1px solid #e2e2e2;
    padding: 53px 45px;
    @include media(sp) {
      padding: 25px;
    }
  }
  .post-meta {
    margin-bottom: 12px;
  }
  .title {
    font-weight: 500;
  }
}

.pg-contents.page-contents,
.pg-contents.category,
.pg-contents.date,
.pg-contents.single {
  .post-meta {
    @include media(sp) {
      margin-bottom: 10px;
    }
  }
  .list-wrap {
    > ul {
      li {
        padding-bottom: 10px;
        margin-top: 30px;
        border-bottom: 1px solid #e5e5e5;
        @include media(sp) {
          margin-top: 21px;
        }
        .title {
          font-size: 1.6rem;
          a {
            color: inherit;
          }
          @include media(sp) {
            line-height: 1.4;
            font-weight: 500;
            font-size: 1.4rem;
          }
        }
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  }
}