@charset "UTF-8";
.pg-privacy {
  .page-title {
    @include media(sp) {
      border-bottom: 1px solid $c-gray;
      padding-bottom: 8px;
    }
  }
  .main {
    > .inner {
      @include media(sp) {
        padding: 0;
      }
    }
    .privacy-wrap {
      @include media(sp) {
        padding: 0 25px;
      }
    }
    p {
      line-height: 1.75;
      font-family: $mincho;
      @include media(sp) {
        font-size: 1.4rem;
      }
    }
    .lead {
      margin-bottom: 25px;
    }
    ul {
      margin-bottom: 40px;
      li {
        margin-top: 18px;
        display: flex;
        &:nth-child(1) {
          margin-top: 0;
        }
        .num {
          font-size: 2.3rem;
          width: 45px;
          display: block;
          font-family: $mincho;
          @include media(sp) {
            width: 27px;
          }
        }
        p {
          padding-top: 4px;
          flex: 1;
        }
      }
    }

    .contact-privacy-wrap {
      p {
        &:nth-child(1) {
          font-size: 1.8rem;
        }
      }
      a {
        color: inherit;
        cursor: text;
        padding-left: .5em;
        @include media(sp) {
          cursor: pointer;
        }
      }
    }
  }
}
