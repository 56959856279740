//antialiased,subpixel-antialiased,none
$font-smoothing: antialiased;

//auto,optimizeSpeed,optimizeLegibility,geometricPrecision
$text-rendering: auto;

// ------------
// Fonts
// ------------
$base-font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$mincho: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
$font01: 'Unica One', "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, cursive;
$font02: 'Quattrocento', "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$q-min: 'Quattrocento', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;

// ------------
// Base
// ------------
$base-font-size: 62.5%;
$base-font-color: #323232;
$base-background-color: #fff;
$inner-width: 1000px;
$sp-width: 768px;

// ------------
// Colors
// ------------
$c-usui: #b4b4b4;
$c-usui02: #c8c8c8;

$c-ao: #131f4b;
$c-audo: #e6d496;
$c-aka: #be0000;
$c-gray: #c8c8c8;

// ------------
// Padding
// ------------
$sp-pad: 0 20px;
