@charset "UTF-8";
[class^="pg-result_"] {
  .main {
    > .inner {
      @include media(sp) {
        // padding: 0;
      }
    }

    .pass-num {
      text-align: center;
      border-top: 1px solid #c8c8c8;
      border-bottom: 1px solid #c8c8c8;
      padding: 31px 0;
      line-height: 1.2;
      margin-bottom: 45px;
      @include media(sp) {
        padding: 0;
        border: none;
        margin-bottom: 20px;
      }
      .txt01 {
        font-size: 1.5rem;
        font-weight: bold;
        @include media(sp) {
          font-size: 1.4rem;
        }
      }
      .txt02 {
        font-size: 2.7rem;
        display: block;
        @include media(sp) {
          font-size: 2.2rem;
        }
        > span {
          color: #ff0000;
          font-size: 3.1rem;
          position: relative;
          top: 2px;
          @include media(sp) {
            font-size: 2.5rem;
          }
          &.sm {
            font-size: 2rem;
            position: relative;
            top: 1px;
            @include media(sp) {
              font-size: 1.5rem;
            }
          }
        }
      }
    }

    .result-table {
      display: flex;
      justify-content: space-between;
      > div {
        width: percentage(960/2000);
      }
      @include media(sp) {
        display: block;
        > div {
          width: 100%;
          &.table-r {
            margin-top: 20px;
          }
        }
      }
    }

    .table-wrap {
      margin-top: 20px;
      &:nth-of-type(1) {
        margin-top: 0;
      }
      .title {
        margin-bottom: 2px;
      }
    }

    table {
      border: 1px solid #c8c8c8;
      width: 100%;
      th,
      td {
        border: 1px solid #c8c8c8;
        font-size: 1.4rem;
        line-height: 1.4;
      }
      th {
        background: #f5f5f5;
        text-align: center;
        padding: 6px 0;
        &:nth-child(2) {
          width: 123px;
          @include media(sp) {
            // width: 77px;
            width: 106px;
          }
        }
      }
      td {
        padding: 7px 7px 7px 25px;
        @include media(sp) {
          padding: 7px 14px;
        }
        span {
          display: block;
          font-size: 1.2rem;
        }
        &:nth-child(2) {
          padding: 7px;
          text-align: center;
        }
      }
    }

    .past-resulet-wrap {
      background: #f5f5f5;
      padding: 48px 0 70px;
      margin-top: 125px;
      @include media(sp) {
        margin-top: 50px;
        padding: 32px 0 40px;
      }
      .title {
        font-size: 2.5rem;
        font-family: $mincho;
        color: $c-ao;
        margin-bottom: 28px;
        text-align: center;
        font-weight: 500;
        @include media(sp) {
          font-size: 2rem;
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
      }
      li {
        width: percentage(485/2000);
        margin-left: percentage(20/2000);
        @include media(sp) {
          width: percentage(295/610);
          margin-left: percentage(20/610);
        }
        &:nth-child(4n+1) {
          margin-left: 0;
        }
        &:nth-child(2n+1) {
          @include media(sp) {
            margin-left: 0;
          }
        }
        &:nth-child(n+5) {
          margin-top: percentage(20/2000);
          @include media(sp) {
            margin-top: 0;
          }
        }
        &:nth-child(n+3) {
          @include media(sp) {
            margin-top: percentage(20/610);
          }
        }
        a {
          background: #fff;
          border: 1px solid #c8c8c8;
          display: block;
          font-size: 1.4rem;
          font-family: $mincho;
          color: $c-ao;
          text-align: center;
          position: relative;
          padding: 16px 0;
          line-height: 1;
          @include media(sp) {
            padding-left: 19px;
          }
          span {
            &:nth-child(1) {
              font-size: 1rem;
            }
            &:nth-child(2) {
              font-size: 1.3rem;
            }
          }
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            background: url(../img/common/icn_arrow_08_blue.png) left top / 16px no-repeat;
            width: 16px;
            height: 16px;
            margin-top: -8px;
            left: 0;
            margin-left: calc(40% - 44px);
          }
        }
      }
    }

    .experience-wrap {
      margin-top: 125px;
      @include media(sp) {
        margin-top: 60px;
      }
      .title {
        font-size: 3rem;
        font-family: $mincho;
        color: $c-ao;
        text-align: center;
        font-weight: 500;
        border-top: 1px solid #c8c8c8;
        border-bottom: 1px solid #c8c8c8;
        padding: 13px 0;
        margin-bottom: 75px;
        @include media(sp) {
          font-size: 2rem;
          margin-bottom: 25px;
          padding: 9px 0;
        }
      }
      .experience-btns {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @include media(sp) {
          display: block;
        }
        > div {
          width: percentage(790/2000);
          @include media(sp) {
            width: 100%;
          }
          &:nth-child(2) {
            margin-left: percentage(110/2000);
            @include media(sp) {
              margin-left: 0;
              margin-top: 20px;
            }
          }
          a {
            background: #be0000;
            display: block;
            font-size: 1.4rem;
            font-family: $mincho;
            color: #fff;
            text-align: center;
            padding: 16px 0;
            position: relative;
            @include media(sp) {
              font-size: 1.2rem;
            }
            &::after {
              content: '';
              background: url(../img/common/icn_arrow_09_white.svg) left top / 7px no-repeat;
              width: 7px;
              height: 17px;
              position: absolute;
              top: 50%;
              right: 0;
              margin-top: -8px;
              margin-right: 10%;
              @include media(sp) {
                margin-right: 16px;
              }
            }
            span {
              font-size: 1.8rem;
              display: block;
              @include media(sp) {
                font-size: 1.6rem;
                letter-spacing: .1em;
              }
            }
          }
        }
      }
    }
  }
}
