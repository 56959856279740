@charset "UTF-8";
.pg-teacher {
  .page-title {
    margin-bottom: 17px;
  }
  .lead {
    font-size: 1.6rem;
    line-height: 1.78;
    margin-bottom: 40px;
    @include media(sp) {
      font-size: 1.2rem;
      margin-bottom: 23px;
    }
  }
  .sorttab {
    display: flex;
    background: #fff;
    border-right: 1px solid #c8c8c8;
    border-top: 1px solid #c8c8c8;
    border-bottom: 1px solid #c8c8c8;
    margin-bottom: 30px;
    @include media(sp) {
      flex-wrap: wrap;
      margin: 0 -15px 30px;
    }
    > li {
      border-left: 1px solid #c8c8c8;
      position: relative;
      &:nth-child(1) {
        flex: 1;
      }
      &:nth-child(n+4) {
        @include media(sp) {
          border-top: 1px solid #c8c8c8;
        }
      }
      @include media(sp) {
        width: percentage(1/3);
      }
      span {
        font-family: $mincho;
        font-size: 1.6rem;
        text-align: center;
        display: block;
        padding: 10px 29px;
        color: #000;
        transition: .1s ease-in-out;
        position: relative;
        cursor: pointer;
        &:hover {
          background: $c-ao;
          color: #fff;
        }
        @include media(sp) {
          font-size: 1.2rem;
          padding: 10px 4px;
        }
      }
      &.current {
        span {
          background: $c-ao;
          color: #fff;
          &::before {
            content: "";
            position: absolute;
            bottom: -8px;
            left: 50%;
            margin-left: -9px;
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 9px 9px 0 9px;
            border-color: $c-ao transparent transparent transparent;
            z-index: 0;
          }
          &::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 50%;
            margin-left: -10px;
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 10px 10px 0 10px;
            border-color: #c8c8c8 transparent transparent transparent;
            z-index: -1;
          }
          &::before,
          &::after {
            @include media(sp) {
              content: normal;
            }
          }
        }
      }
    }
  }
}