@charset "UTF-8";
.pg-philosophy {
  .mainvisual {
    width: 100%;
    height: 410px;
    background-position: center;
    background-size: cover;
    img {
      display: none;
    }
    .txt {
      margin: 0 auto;
      max-width: 1000px;
      .txt-inner {
        padding-top: 76px;
        writing-mode: vertical-rl;
        p {
          padding: 20px 15px;
          position: relative;
          line-height: 4.4rem;
          font-size: 2.5rem;
          font-family: $mincho;
          color: $c-ao;
          &:first-child {
            margin-left: 50px;
          }
        }
        p::before,
        p::after {
          position: absolute;
          content: '';
          width: 50px;
          height: 50px;
        }
        p::before {
          top: 0;
          right: 0;
          border-top: 1px solid #828382;
          border-right: 1px solid #828382;
        }
        p::after {
          bottom: 0;
          left: 0;
          border-bottom: 1px solid #828382;
          border-left: 1px solid #828382;
        }
      }
    }
    @include media(sp) {
      width: 100%;
      max-width: 100%;
      height: auto;
      background-size: 0;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
      .txt {
        width: 100%;
        .txt-inner {
          margin: 0 auto;
          padding-top: 35px;
          p {
            padding: 15px 10px;
            line-height: 8vw;
            font-size: 4.266vw;
            color: #000;
            &:first-child {
              margin-left: 15px;
            }
          }
          p::before,
          p::after {
            width: 33px;
            height: 33px;
          }
          p::before {
            border-top: 1px solid #969696;
            border-right: 1px solid #969696;
          }
          p::after {
            border-bottom: 1px solid #969696;
            border-left: 1px solid #969696;
          }
        }
      }
    }
  }
  .sec01, .sec02, .sec03, .sec04 {
    .sec-inner {
      margin: 0 auto;
      max-width: 1000px;
    }
    h3 {
      line-height: 4.9rem;
      font-family: $mincho;
      font-size: 3.1rem;
      writing-mode: vertical-rl;
      color: $c-ao;
    }
    .txt-box .txt {
      line-height: 2.6rem;
      font-size: 1.4rem;
    }
    @include media(sp) {
      .sec-inner {
        width: 100%;
        max-width: 100%;
      }
      h3 {
        line-height: 8vw;
        font-size: 5.6vw;
        color: $c-ao;
      }
      .txt-box .txt {
        line-height: 2.4rem;
        font-size: 1.3rem;
      }
    }
  }
  .sec01, .sec04 {
    margin-top: 70px;
    padding-top: 60px;
    border-top: 1px $c-gray solid;
    .sec-inner {
      display: flex;
      display: -webkit-flex;
    }
    picture {
      margin: 0 auto 0 0;
      width: 330px;
      position: relative;
      img {
        width: 710px;
        height: 410px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
      }
    }
    .txt-box {
      display: flex;
      display: -webkit-flex;
      width: 600px;
      h3 {
        width: 40px;
        br {
          display: none;
        }
      }
      .txt {
        margin: 0 0 0 60px;
        width: 500px;
      }
    }
  }
  @include media(sp) {
    .sec01, .sec04 {
      margin-top: 35px;
      padding-top: 25px;
      .sec-inner {
        display: block;
      }
      picture {
        margin: 0;
        width: 100%;
        position: stataic;
        img {
          width: 100%;
          height: auto;
          position: static;
        }
      }
      .txt-box {
        margin-top: -35%;
        display: block;
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        h3 {
          width: 100%;
          span {
            top: 0;
            right: 20px;
            position: relative;
            display: block;
          }
          br {
            display: block;
          }
        }
        .txt {
          margin: 0 auto;
          padding: $sp-pad;
          width: 100%;
        }
      }
    }
  }
  .sec02 {
    margin-top: 60px;
    padding-top: 60px;
    border-top: 1px $c-gray solid;
    .sec-inner {
      position: relative;
    }
    picture {
      margin: 0 auto;
      width: 984px;
      height: 360px;
      position: absolute;
      top: 270px;
      left: 0;
      overflow: hidden;
      img {
        width: 800px;
        height: 360px;
        position: absolute;
        top: 0;
        left: 200px;
      }
    }
    .txt-box {
      display: flex;
      display: -webkit-flex;
      max-width: 1000px;
      h3 {
        width: 140px;
      }
      .txt {
        margin: 0 0 0 60px;
        width: 800px;
        p {
          margin-bottom: 30px;
          width: 800px;
        }
        h4 {
          margin-bottom: 20px;
          font-size: 1.7rem;
          br {
            display: none;
          }
        }
        ul {
          li {
            position: relative;
            &::before {
              content: "● ";
              color: $c-ao;
              font-size: 8px;
            }
          }
        }
      }
    }
    @include media(sp) {
      margin-top: 40px;
      padding-top: 75px;
      .sec-inner {
        position: static;
      }
      picture {
        width: 100%;
        height: autp;
        position: static;
        img {
          width: 100%;
          height: auto;
          position: static;
        }
      }
      .txt-box {
        display: block;
        max-width: 100%;
        h3 {
          margin-top: -75%;
          margin-bottom: 20%;
          width: auto;
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          span {
            top: 0;
            right: 20px;
            position: relative;
            display: block;
          }
        }
        .txt {
          margin: 0 auto;
          padding: $sp-pad;
          width: 100%;
          p {
            margin-bottom: 30px;
            width: 100%;
          }
          h4 {
            margin-bottom: 15px;
            line-height: 6.93vw;
            font-size: 4.53vw;
            letter-spacing: -1.5px;
            br {
              display: block;
            }
          }
          ul {
            li {
              margin-bottom: 5px;
              padding-left: 1em;
              line-height: 6.13vw;
              text-indent: -1em;
              position: relative;
              letter-spacing: -1px;
              &::before {
                content: "● ";
                color: $c-ao;
                font-size: 2.13vw;
              }
            }
          }
        }
      }
    }
  }
  .sec03 {
    margin-top: 100px;
    padding-top: 240px;
    border-top: 1px $c-gray solid;
    .sec-inner {
      .txt-box {
        margin: 0 0 0 auto;
        width: 800px;
        h4 {
          margin-bottom: 20px;
          br {
            display: none;
          }
        }
      }
    }
    @include media(sp) {
      margin-top: 50px;
      padding-top: 50px;
      .sec-inner {
        .txt-box {
          margin: 0;
          width: 100%;
          padding: $sp-pad;
          box-sizing: border-box;
          h4 {
            margin-bottom: 15px;
            br {
              display: block;
            }
          }
          .txt {
            margin: 0 auto;
            width: 100%;
          }
        }
      }
    }
  }
  .sec04 {
    margin-top: 60px;
    @include media(sp) {
      margin-top: 50px;
      padding-top: 60px;
      .txt-box {
        margin-top: -75%;
        display: block;
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        h3 {
          margin-bottom: 55%;
          width: 100%;
          span {
            right: 64%;
          }
        }
        .txt {
          margin: 0 auto;
          padding: $sp-pad;
          width: 100%;
        }
      }
    }
  }
}
