@charset "UTF-8";
main.main.college {
  br.sp {
    display: none;
  }
  @include media(sp) {
    br.sp {
      display: block;
    }
  }
  h1 {
    margin-bottom: 0;
  }
  h2.college-info {
    margin-bottom: 20px;
    padding-bottom: 30px;
    border-bottom: 1px $c-gray solid;
    text-align: center;
    font-family: $mincho;
    font-size: 30px;
    color: $c-ao;
    @include media(sp) {
      padding-bottom: 20px;
      font-size: 2rem;
    }
  }
  .inner {
    p.college-desc {
      margin-bottom: 60px;
      font-size: 1.5rem;
      @include media(sp) {
        margin-bottom: 30px;
        line-height: 2.1rem;
        font-size: 1.4rem;
      }
    }
    .college-box {
      margin-bottom: 60px;
      line-height: 2.6rem;
      font-size: 1.6rem;
      &:last-child {
        margin-bottom: 100px;
      }
      @include media(sp) {
        margin-bottom: 50px;
        line-height: 2.2rem;
        font-size: 1.4rem;
        &:last-child {
          margin-bottom: 50px;
        }
      }
      h2 {
        margin-bottom: 20px;
        padding: 8px 0;
        border: 1px #e2e2e2 solid;
        border-left: none;
        color: $c-ao;
        span {
          padding-left: 20px;
          border-left: 4px $c-ao solid;
          height: 34px;
          line-height: 34px;
          font-size: 2rem;
          font-family: $mincho;
          display: block;
          span {
            padding-left: 0;
            border-left: none;
            height: auto;
            line-height: auto;
            font-size: 1.5rem;
            font-family: $base-font-family;
            font-weight: 800;
            display: inline-block;
          }
        }
        @include media(sp) {
          padding: 6px 0;
          span {
            padding-left: 12px;
            height: 22px;
            line-height: 22px;
            font-size: 1.6rem;
            span {
              font-size: 1.4rem;
            }
          }
        }
      }
      p {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      ul li {
        padding-left: 1em;
        text-indent: -1em;
      }
    }
    .campus-info {
      dl {
        margin-bottom: 25px;
        width: 100%;
        display: flex;
        &:last-child {
          margin-bottom: 0;
        }
        dt {
          margin-right: 15px;
          padding: 10px 8px;
          width: 70px;
          line-height: 23px;
          box-sizing: border-box;
          flex-wrap: wrap;
          color: $c-ao;
          background: url("/img/result/college/bg_info.png") repeat;
          background-size: 2px;
          font-size: 1.3rem;
          font-weight: 800;
          text-align: center;
          box-sizing: border-box;
          span {
            font-size: 1.6rem;
            &.common {
              display: block;
            }
          }
        }
        dd {
          flex-grow: 1;
          line-height: 26px;
          span.campus-name {
            font-weight: 800;
            display: block;
          }
          ul {
            margin-top: 5px;
            li {
              padding-left: 0;
              text-indent: 0;
            }
          }
        }
      }
      @include media(sp) {
        dl {
          margin-bottom: 30px;
          width: 100%;
          display: block;
          &:last-child {
            margin-bottom: 0;
          }
          dt {
            margin: 0 0 14px;
            padding: 10px;
            width: 100%;
            line-height: 1;
            background: url("/img/result/college/sp/bg_info.png") repeat;
            background-size: 2px;
            font-size: 1.4rem;
            text-align: left;
            box-sizing: border-box;
            span {
              font-size: 1.4rem;
              &.common {
                display: inline-block;
              }
            }
            br {
              display: none;
            }
          }
          dd {
            width: 100%;
            flex-grow: 1;
            line-height: 26px;
            font-size: 1.4rem;
            span.campus-name {
              font-size: 1.6rem;
              font-weight: 800;
            }
            ul {
              margin-top: 5px;
            }
          }
        }
      }
    }
    .exam-info {
      p.kome {
        margin-bottom: 20px;
        font-size: 1.4rem;
        font-weight: 800;
        br {
          display: none
        }
        @include media(sp) {
          padding-left: 1em;
          text-indent: -1em;
          font-weight: normal;
          br {
            display: block;
          }
        }
      }
      .college-box-h3 {
        padding: 20px 0;
        border-top: 1px $c-gray solid;
        line-height: 1;
        font-size: 1.6rem;
        display: flex;
        display: -webkit-flex;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        h3 {
          margin-right: 15px;
          font-weight: 800;
        }
        &.sub {
          margin-bottom: 30px;
        }
        @include media(sp) {
          padding: 15px 0;
          h3 {
            font-size: 1.6rem;
          }
          p {
            margin-top: 10px;
            width: 100%;
            font-size: 1.4rem;
          }
          &.sub {
            border-bottom: 1px $c-gray solid;
          }
        }
      }
      table {
        margin-bottom: 70px;
        border-top: 1px $c-gray solid;
        width: 100%;
        text-align: left;
        font-size: 1.6rem;
        font-weight: 800;
        @include media(sp) {
          border-top: none;
        }
        th:first-child, td:first-child {
          border-right: 1px $c-gray solid;
        }
        tr {
          border-bottom: 1px $c-gray solid;
          display: flex;
          display: -webkit-flex;
          flex-wrap: nowrap;
          -webkit-flex-wrap: nowrap;
        }
        th {
          padding: 20px 0;
          @include media(sp) {
            padding: 5px 0;
          }
        }
        td {
          padding: 15px 0;
          min-height: 80px;
          box-sizing: border-box;
          @include media(sp) {
            padding: 5px 0;
            font-size: 1.4rem;
          }
        }
        tr:first-child {
          th:first-child {
            width: 74.5%;
            font-size: 2rem;
            display: flex;
            display: -webkit-flex;
            flex-wrap: nowrap;
            -webkit-flex-wrap: nowrap;
            span {
              margin-left: 15px;
              font-size: 1.6rem;
            }
            @include media(sp) {
              letter-spacing: -1px;
              span {
                font-weight: normal;
              }
            }
          }
          th:last-child {
            width: 25.5%;
            text-align: center;
          }
          @include media(sp) {
            th:first-child {
              width: 70%;
              font-size: 1.6rem;
              span {
                margin-left: 5px;
                font-size: 1.4rem;
              }
            }
            th:last-child {
              width: 30%;
            }
          }
        }
        td:first-child {
          width: 74.5%;
          display: flex;
          display: -webkit-flex;
          flex-wrap: nowrap;
          -webkit-flex-wrap: nowrap;
          align-items: stretch;
          .ti {
            margin-right: 20px;
            width: 80px;
            line-height: 1;
            color: $c-ao;
            background: url("/img/result/college/bg_info.png") repeat;
            background-size: 2px;
            text-align: center;
            box-sizing: border-box;
            display: flex;
            display: -webkit-flex;
            align-items: center;
            justify-content: center;
            @include media(sp) {
              line-height: 2.2rem;
              -webkit-writing-mode: vertical-rl;
              -ms-writing-mode: tb-rl;
              writing-mode: vertical-rl;
            }
          }
          p {
            width: 645px;
            display: flex;
            display: -webkit-flex;
            align-items: center;
            flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            span {
              margin-left: 10px;
            }
          }
          @include media(sp) {
            .ti {
              margin-right: 5%;
              width: 13%;
            }
            p {
              width: 82%;
              span {
                margin-left: 0;
                display: block;
              }
            }
          }
        }
        td:last-child {
          width: 25.5%;
          display: flex;
          display: -webkit-flex;
          flex-wrap: wrap;
          -webkit-flex-wrap: wrap;
          justify-content: flex-end;
          align-items: center;
          text-align: right;
        }
        @include media(sp) {
          td:first-child {
            width: 70%;
          }
          td:last-child {
            width: 30%;
          }
        }
        tr.sum {
          td:first-child {
            display: flex;
            display: -webkit-flex;
            align-items: center;
          }
          td {
            border-right: none;
            width: 100%;
            span.ti {
              margin-right: 20px;
            }
          }
          td:first-child {
            width: 200px;
          }
          span.hiritsu {
            width: 100%;
            font-size: 1.4rem;
            display: block;
          }
          @include media(sp) {
            td {
              min-height: 45px;
            }
            td:first-child {
              width: 10%;
            }
            span.hiritsu {
              font-weight: normal;
            }
          }
          &.gohi {
            @include media(sp) {
              td:first-child {
                width: 30%;
                align-items: flex-start;
              }
              td:last-child {
                width: 70%;
                text-align: left;
              }
            }
          }
        }
      }
    }
    .point-info {
      span.stress {
        display: block;
      }
      @include media(sp) {
        span.stress {
          margin-top: 15px;
          font-size: 1.6rem;
          span {
            font-weight: 800;
          }
        }
      }
    }
  }
  .experience-list {
    padding: 40px 0 55px;
    width: 100%;
    &.parents {
      margin-bottom: 100px;
      padding: 50px 0 55px;
      background: #ebebeb;
    }
    @include media(sp) {
      padding: 25px 20px 45px;
      box-sizing: border-box;
      &.parents {
        margin-bottom: 70px;
        padding: 50px 20px 45px;
      }
    }
    h2 {
      margin-bottom: 50px;
      text-align: center;
      color: $c-ao;
      font-family: $mincho;
      @include media(sp) {
        margin-bottom: 40px;
        font-size: 2rem;
      }
    }
    ul {
      margin: 0 auto 35px;
      max-width: 1000px;
      display: flex;
      overflow: hidden;
      li {
        margin-right: 20px;
        border: 1px #e5e5e5 solid;
        width: 235px;
        line-height: 24px;
        background: #fff;
        font-size: 1.4rem;
        font-weight: 800;
        a {
          padding: 20px 20px 35px;
          width: 235px;
          box-sizing: border-box;
          color: #000;
          display: block;
          transition: .3s ease-in-out;
          &:hover {
            opacity: .7;
          }
        }
        .prof {
          .ph {
            margin-bottom: 20px;
            width: 195px;
            height: 135px;
            background-size: cover;
            background-position: center;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
      @include media(sp) {
        margin-bottom: 0;
        width: 100%;
        display: block;
        li {
          margin: 0 0 25px 0;
          border: 1px #e5e5e5 solid;
          width: 100%;
          line-height: 25px;
          font-size: 1.5rem;
          a {
            padding: 20px 20px 30px;
            width: 100%;
          }
          .prof {
            display: flex;
            align-items: center;
            .ph {
              margin: 0 20px 0 0;
              width: 110px;
              height: 75px;
            }
            .txt {
              flex-grow: 1;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    a.btn-more {
      margin: 0 auto;
      width: 215px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background: $c-ao;
      color: #fff;
      font-family: $mincho;
      font-size: 1.8rem;
      display: block;
      transition: .3s ease-in-out;
      &:hover {
        opacity: .7;
      }
      @include media(sp) {
        width: 200px;
        height: 45px;
        line-height: 45px;
        font-size: 1.5rem;
      }
    }
  }
  .return-college {
    .return-college-inner {
      margin: 0 auto;
      border-top: 1px #e5e6e5 solid;
      border-bottom: 1px #e5e6e5 solid;
      max-width: 1000px;
      height: 55px;
      line-height: 53px;
      text-align: right;
    }
    @include media(sp) {
      .return-college-inner {
        width: 100%;
        height: 50px;
        line-height: 48px;
        box-sizing: border-box;
        text-align: center;
      }
    }
    a {
      padding-right: 30px;
      font-family: $mincho;
      font-size: 1.8rem;
      position: relative;
      color: $c-ao;
      position: relative;
      display: inline-block;
      color: #000;
      vertical-align: middle;
      text-decoration: none;
      transition: .3s ease-in-out;
      &:hover {
        opacity: .7;
      }
      @include media(sp) {
        padding: 0;
        font-size: 1.6rem;
      }
    }
    a::after {
      margin: auto 0 auto auto;
      width: 8px;
      height: 8px;
      border-top: 2px solid #be0000;
      border-right: 2px solid #be0000;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      content: "";
      vertical-align: middle;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      @include media(sp) {
        top: 0;
        bottom: 0;
        right: -15px;
      }
    }
  }
}
