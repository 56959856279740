@charset "UTF-8";
.sidebar {
  @include media(sp) {
    padding: 0 25px;
  }
  .sidebar-title {
    font-family: $q-min;
    color: $c-ao;
    font-size: 1.8rem;
    font-weight: 500;
    border-bottom: 1px solid #c8c8c8;
    padding: 18px 0 13px;
    margin-bottom: 25px;
    @include media(sp) {
      font-size: 1.8rem;
      margin-bottom: 20px;
    }
  }
  .pg-contents.single & {
    .recent-posts {
      margin-bottom: 29px;
      .sidebar-title {
        padding-top: 0;
      }
      .post-meta {
        margin-bottom: 12px;
        @include media(sp) {
          margin-bottom: 5px;
        }
      }
      .title {
        line-height: 1.67;
        font-weight: 500;
        font-size: 1.5rem;
        @include media(sp) {
          font-size: 1.4rem;
        }
      }
      .list-wrap {
        li {
          margin-top: 19px;
          padding-bottom: 14px;
          &:nth-child(1) {
            margin-top: 0;
          }
        }
        @include media(sp) {
          li {
            padding-bottom: 7px;
          }
        }
      }
    }
  }

  .side-category-list {
    li {
      margin-top: 13px;
      &:nth-child(1) {
        margin-top: 0;
      }
      @include media(sp) {
        font-size: 1.4rem;
      }
      a {
        color: inherit;
        padding-left: 17px;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          width: 6px;
          height: 10px;
          background: url(/img/common/icn_arrow_06_blue.svg) left top / 6px no-repeat;
          top: 50%;
          left: 0;
          margin-top: -5px;
        }
      }
    }
  }

  .banner-area {
    margin-top: 35px;
    > div {
      margin-top: 20px;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    @include media(sp) {
      > div {
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
