@charset "UTF-8";
.pg-faq {
  .main {
    > .inner {
      padding: 0;
    }
  }
  .tabs-02 {
    margin-bottom: 78px;
    display: flex;
    @include media(sp) {
      display: none;
    }
    > div {
      width: percentage(1/3);
      border-top: 1px solid $c-gray;
      border-bottom: 1px solid $c-gray;
      border-right: 1px solid $c-gray;
      &:first-child {
        border-left: 1px solid $c-gray;
      }
      @include media(sp) {
        padding: 16px 0;
        margin-left: 1px;
        font-size: 3.75vw;
        &:first-child {
          margin-left: 0px;
        }
      }
      a {
        color: inherit;
        padding: 22px 0;
        font-family: $mincho;
        text-align: center;
        display: block;
        transition: .3s ease-in-out;
      }
      &:hover {
        a {
          background: $c-ao;
          position: relative;
          color: #fff;
        }
      }
    }
  }

  .tab-contents-title {
    color: $c-ao;
    margin-bottom: 15px;
    @include media(sp) {
      text-align: center;
      font-family: $mincho;
      font-weight: normal;
      font-size: 1.6rem;
      margin-bottom: 0;
      padding: 10px;
      position: relative;
      cursor: pointer;
      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 17px;
        height: 9px;
        background: url(/img/common/icn_arrow_04_blue.svg) left top / 17px no-repeat;
        top: 50%;
        margin-top: -4px;
        transition: .3s ease-in-out;
      }
      &::before {
        left: 20px;
      }
      &::after {
        right: 20px;
      }
    }
  }

  .tabs-contents {
    > div {
      margin-top: 98px;
      &:first-child {
        margin-top: 0;
      }
      @include media(sp) {
        margin-top: 0;
        .tab-contents-title {
          background: #ebebeb;
        }
        dl {
          display: none;
        }
        &:first-child {
          dl {
            display: block;
          }
        }
        &.active {
          .tab-contents-title {
            background: $c-ao;
            color: #fff;
            &::before,
            &::after {
              background: url(/img/common/icn_arrow_04_white.svg) left top / 17px no-repeat;
              transform: scale(1, -1);
            }
          }
        }
      }
    }
    dl {
      border-bottom: 1px solid $c-gray;
    }
    dt p,
    dd {
      line-height: 2;
      position: relative;
      &::before {
        position: absolute;
        font-size: 3.4rem;
        font-family: $q-min;
        left: 0;
        top: 4px;
      }
      a {
        text-decoration: underline;
        color: #323232;
        &:hover {
          text-decoration: none;
        }
      }
      @include media(sp) {
        line-height: 1.7;
        font-size: 1.4rem;
      }
    }
    dt {
      border-top: 1px solid $c-gray;
      font-family: $mincho;
      cursor: pointer;
      p {
        padding: 22px 46px;
        &::before {
          content: 'Q';
          color: $c-ao;
        }
      }
      @include media(sp) {
        p {
          padding: 22px 0 22px 46px;
          margin: 0 25px;
        }
      }
    }
    dd {
      display: none;
      border-top: 1px dotted $c-gray;
      &::before {
        content: 'A';
        color: $c-aka;
      }
      @include media(sp) {
        display: block;
        width: calc(100% - 50px);
        margin: 0 auto;
      }
      > p {
        margin: 22px 46px;
        @include media(sp) {
          margin: 22px 0px 22px 46px;
        }
      }
    }
  }
}
