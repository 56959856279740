@charset "UTF-8";
.pg-national,
.pg-private
{
  .school-switch {
    margin-bottom: 90px;
    display: flex;
    h3 {
      width: 50%;
      background: #b4b4b4;
      color: #fff;
      text-align: center;
      font-family: $mincho;
      height: 130px;
      line-height: 130px;
      font-size: 3.5rem;
      font-weight: normal;
      a {
        display: block;
        height: 130px;
        color: #fff;
        transition: .3s ease-in-out;
        &.active,
        &:hover {
          background: $c-ao;
        }
        &.active {
          pointer-events: none;
          cursor: default;
        }
      }
      @include media(sp) {
        height: auto;
        line-height: 1;
        font-size: 1.6rem;
        a {
          padding: 30px 0;
          height: auto;
        }
      }
      span {
        font-size: 2.6rem;
        display: block;
        @include media(sp) {
          font-size: 1.1rem;
        }
      }
    }
    @include media(sp) {
      margin-bottom: 30px;
    }
  }
  .tabs-contents {
    @include media(sp) {
      padding: 0 15px;
    }
    > div {
      display: none;
      &.active {
        display: block;
        animation: fadeIn .5s ease 0s 1 normal;
      }
    }
  }

  .d-parts-01 {
    background: #f5f5f5;
  }
  .d-parts-02 {
    border-top: 1px solid $c-gray;
    font-weight: bold;
    > p {
      line-height: 1.56;
    }
  }

  .detail-experience-wrap-01 {
    display: flex;
    justify-content: space-between;
    @include media(sp) {
      display: block;
    }
    .detail-experience-box {
      width: 480px;
      @include media(sp) {
        width: 100%;
        &:nth-child(2) {
          margin-top: 40px;
        }
      }
    }
    .d-parts-01 {
      padding: 30px;
      @include media(sp) {
        padding: 20px;
      }
    }
    .d-parts-01-01 {
      display: flex;
      margin-bottom: 20px;
      @include media(sp) {
        margin-bottom: 13px;
      }
      .thum {
        width: 105px;
        margin-right: 30px;
        @include media(sp) {
          width: 75px;
          margin-right: 20px;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      .title {
        flex: 1;
        font-size: 2.2rem;
        font-weight: bold;
        line-height: 1.63;
        @include media(sp) {
          font-size: 1.4rem;
        }
      }
    }
    .d-parts-01-02 {
      line-height: 1.63;
      font-size: 1.6rem;
      @include media(sp) {
        font-size: 1.2rem;
      }
    }

    .d-parts-02 {
      padding: 18px 30px;
      position: relative;
      @include media(sp) {
        padding: 16px 20px;
        font-size: 1.4rem;
      }
      > p {
        line-height: 1.56;
      }
      .btn-more {
        position: absolute;
        bottom: 0;
        right: 0;
        @include media(sp) {
          position: relative;
          margin-top: 20px;
        }
        a {
          background: $c-ao;
          color: #fff;
          font-size:  1.3rem;
          font-weight: bold;
          text-align: center;
          display: block;
          width: 105px;
          padding: 11px 0;
          @include media(sp) {
            width: 100%;
            font-size: 1.5rem;
          }
        }
      }
    }
  }
  .detail-experience-wrap-02 {
    .detail-experience-box {
      margin-top: 50px;
      display: block;
      color: #000;
      transition: .3s ease-in-out;
      &:hover {
        opacity: .7;
      }
    }
    .d-parts-01 {
      padding: 18px 30px;
      color: $c-ao;
      font-size: 2rem;
      font-weight: bold;
    }
    .d-parts-02 {
      padding: 18px 30px;
      @include media(sp) {
        font-size: 1.4rem;
      }
    }
    .info-person {
      margin-bottom: 11px;
    }

    .title {
      @include media(sp) {
        font-size: 1.6rem;
      }
    }
    .info-desc {
      font-weight: normal;
    }
  }
  .detail-experience-box {
    border: 1px solid $c-gray;
  }
  .experience-list {
    margin: 80px 0 45px;
    padding: 54px 0 70px;
    background: #ebebeb;
    @include media(sp) {
      margin-bottom: 0;
      padding: $sp-pad;
      padding-top: 40px;
      padding-bottom: 40px;
    }
    h4 {
      margin-bottom: 35px;
      font-size: 2.5rem;
      font-family: $mincho;
      text-align: center;
      color: $c-ao;
      br {
        display: none;
      }
      @include media(sp) {
        margin-bottom: 20px;
        line-height: 3rem;
        font-size: 2rem;
        br {
          display: block;
        }
      }
    }
    ul {
      margin: 0 auto;
      max-width: 1000px;
      font-family: $mincho;
      display: flex;
      display: -webkit-flex;
      flex-wrap: wrap;
      li {
        margin: 0 10px 10px 0;
        width: 242px;
        height: 50px;
        text-align: center;
        font-size: 1.4rem;
        &:nth-child(4n+4) {
          margin-right: 0;
        }
        a {
          padding: 0 0 0 20px;
          border: 1px #e2e2e2 solid;
          width: 242px;
          height: 50px;
          line-height: 50px;
          position: relative;
          display: block;
          vertical-align: middle;
          text-decoration: none;
          font-size: 1.4rem;
          color: $c-ao;
          box-sizing: border-box;
          text-align: center;
          background: #fff;
          transition: .3s ease-in-out;
          &:hover {
            opacity: .7;
          }
          &::before,
          &::after{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 15px;
            margin: auto;
            content: "";
            vertical-align: middle;
          }
          &::before{
            width: 16px;
            height: 16px;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            background: #be0000;
          }
          &::after{
            left: 21px;
            width: 3px;
            height: 3px;
            border-top: 1px solid #fff;
            border-right: 1px solid #fff;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
      @include media(sp) {
        width: 100%;
        li {
          margin: 0 2% 1.6% 0;
          width: 48%;
          &:nth-child(even) {
            margin-right: 0;
          }
          span {
            display: none;
          }
          a {
            padding: 0 0 0 5px;
            width: 100%;
            font-size: 1.4rem;
            &::before,
            &::after{
              position: absolute;
              top: 0;
              bottom: 0;
              left: 5px;
              margin: auto;
              content: "";
              vertical-align: middle;
            }
            &::before{
              width: 14px;
              height: 14px;
              -webkit-border-radius: 50%;
              border-radius: 50%;
            }
            &::after{
              left: 10px;
              width: 3px;
              height: 3px;
              border-top: 1px solid #fff;
              border-right: 1px solid #fff;
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
        }
      }
      @media all and (max-width: 430px) {
        li.l2 a {
          padding-top: 5px;
          line-height: 1.5;
        }
      }
      @media all and (min-width: 430px) {
        li br.is-430 {
          display: none !important;
        }
      }
    }
  }
}
