@charset "UTF-8";
.header {
  padding-top: 33px;
  @include media(sp) {
    padding-top: 25px;
    position: relative;
  }
  .logo {
    width: 270px;
    img {
      width: 100%;
      height: auto;
    }
    @include media(sp) {
      width: percentage(482/610);
      a {
        display: block;
        line-height: 1;
      }
    }
  }
  .h-upper-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .sp-nav-btn {
    display: none;
    @include media(sp) {
      display: block;
      position: fixed;
      top: 10px;
      right: 10px;
      width: 50px;
      height: 50px;
      z-index: 7;
      cursor: pointer;
      background: $c-ao;
      span {
        display: block;
        width: 30px;
        height: 3px;
        background: #fff;
        position: absolute;
        left: 12px;
        transition: .3s ease-in-out;
        &:first-child {
          top: 15px;
        }
        &:nth-child(2) {
          top: 30px;
        }
      }
      &.active span:first-child {
        transform: translateY(8px) rotate(45deg);
        background: #fff;
      }
      &.active span:nth-child(2) {
        transform: translateY(-7px) rotate(-45deg);
        background: #fff;
      }
      &.active p {
        top: 30px
      }
    }
  }

  .sp-menu {
    display: none;
    @include media(sp) {
      position: fixed;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 6;
      width: 100%;
      height: 100%;
      overflow: hidden;
      min-height: 100%;
      transition: .3s ease-in-out;
      transform: translate3d(0, -100%, 0);
      background: rgba(32,32,32,1);
      color: #fff;
      display: block;
      // padding: 80px 25px 10px;
      padding: 80px 25px 40px;
      overflow-y: scroll;

      .list01-head {
        border-top: 1px solid #505050;
        border-bottom: 1px solid #505050;
        a {
          &::after {
            background: url('/img/common/icn_arrow_03_blue02.svg') left top / 9px no-repeat;
          }
        }
      }
    }
    .sp-menu-open & {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    .list01-head{
      a {
        color: #fff;
      }
    }

    .inner {
      @include media(sp) {
        padding: 0;
      }
      ul.list02 {
        margin-top: 30px;
      }
      > ul {
        display: block;
        > li {
          margin-top: 30px;
          &:nth-child(1) {
            margin-top: 0;
          }
        }
        ul {
          > li {
            position: relative;
            padding-left: 20px;
            font-size: 1.4rem;
            margin-top: 8px;
            &::before {
              content: '';
              position: absolute;
              width: 11px;
              height: 11px;
              top: 5px;
              left: 0;
              border-radius: 50%;
              background: #484ccc;
            }
            a {
              color: inherit;
            }
          }
        }
      }
    }
  }
  .list-menu-child {
    >li {
      display: none;

      &.is-active {
        display: block;
      }
    }
  }

  .list-menu {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    align-content: flex-start;
    flex-flow: row wrap;
    margin-bottom: 30px;
  }
  .list-menu-item {
    &:nth-child(2n+1) {
      flex: 0 0  45%;
      max-width: 45%;
    }
    &:nth-child(2n) {
      flex: 0 0  54%;
      max-width: 54%;
      margin-left: 1%;
    }

    margin-bottom: 1%;
    background-color: #2f3032;
    padding: 12px 10px;
    padding-right: 20px;
    position: relative;
    font-size: 11px;
    font-weight: 700;
    color: #fff;
    letter-spacing: -0.03em;
    font-feature-settings: "palt";
    transition: all 300ms  ease-out 0ms;

    &.has-list {
      &::after {
        content: "";
        pointer-events: none;
        position: absolute;
        top: 50%;
        right: 8px;
        width:  8px;
        height: 8px;
        border-top: 1px solid   #929393;
        border-right: 1px solid #929393;
        margin-top: -2px;
        transform: translate3d(0, -50%, 0) rotate(135deg);
      }
    }

    &.is-active {
      background-color: #000000;
      &::after {
        border-color: #ccbe93;
      }
    }
  }
  .sp-menu-footer {
    border-top: 1px solid #727070;
    padding-top: 20px;
    margin-top: 30px;
  }
  .sp-menu-footer__tel {

  }
  .sp-menu-footer__tel-label {
    font-size: 18px;
    font-family: $q-min;
    color: #fff;
    font-weight: 400;
    margin-right: 5px;
  }
  .sp-menu-footer__tel-num {
    font-size: 32px;
    font-family: $q-min;
    color: #fff;
    font-weight: 400;
    text-decoration: none;
  }
  .sp-menu-footer__info {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    align-content: flex-start;
    flex-flow: row wrap;
    font-size: 12px;
    color: #fff;
  }
  .sp-menu-footer__info-time {
    margin-right: 10px;
    color: #fff;
  }
  .sp-menu-footer__info-addr {
    color: #fff;
  }
  .sp-menu-footer__cv-btn {
    margin-top: 15px;
    >a {
      display: block;
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #b02418;
      font-size: 16px;
      color: #fff;
      font-weight: 400;
      letter-spacing: 0.00em;
      font-family: $mincho;
    }
    span {
      display: inline-block;
      position: relative;
      padding-left: 15px;
      &::before {
        content: "";
        position: absolute;
        z-index: 2;
        top: 50%;
        left:  0;
        margin: 0 auto;
        width:  0px;
        height: 0px;
        border: 0px solid transparent;
        border-width: 5px 8px 5px 8px;
        border-left-color: #fff;
        transform: translate3d(0, -50%, 0) rotate(0deg);
      }
    }
  }
}

.h-upper-02 {
  display: flex;
  align-items: center;
  @include media(sp) {
    display: none;
  }
}

.tel-wrap {
  margin-right: 22px;
  > p {
    span {
      display: block;
      font-family: $q-min;
      color: $c-ao;
      text-align: right;
      &.tel {
        font-size: 1.6rem;
        a {
          font-size: 3.2rem;
          margin-left: 8px;
          display: inline-block;
          cursor: text;
        }
      }
      &.time {
        font-size: 1.2rem;
        span {
          font-size: 1.3rem;
          display: inline-block;
          margin-left: 5px;
        }
      }
      &.address {
        font-size: 1.3rem;
      }
      a {
        color: $c-ao;
        font-family: $q-min;
      }
    }
  }
}

.cv-wrap {
  a {
    transition: opacity 300ms  ease-out 0ms;
    &:hover {
      opacity: 0.8;
    }
  }
  .cv-wrap__contact {
    >a {
      display: block;
      width: 310px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background-color: #b02418;
      font-size: 16px;
      color: #fff;
      font-weight: 400;
      letter-spacing: 0.00em;
      font-family: $mincho;
    }
    span {
      display: inline-block;
      position: relative;
      padding-left: 15px;
      &::before {
        content: "";
        position: absolute;
        z-index: 2;
        top: 50%;
        left:  0;
        margin: 0 auto;
        width:  0px;
        height: 0px;
        border: 0px solid transparent;
        border-width: 7px 10px 7px 10px;
        border-left-color: #fff;
        transform: translate3d(0, -50%, 0) rotate(0deg);
      }
    }
  }
  .cv-wrap__faq {
    text-align: right;
    margin-top: 10px;
    >a {
      font-size: 16px;
      color: #151f49;
      font-weight: 400;
      letter-spacing: 0.00em;
      line-height: 1.5;
      padding-right: 20px;
      position: relative;
      font-family: $mincho;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 5px;
        width:  10px;
        height: 10px;
        border-top:   1px solid #151f49;
        border-right: 1px solid #151f49;
        transform: translate3d(0, -50%, 0) rotate(45deg);
      }
    }
  }
}
.contact-wrap {
  display: flex;
  p {
    a {
      font-family: $mincho;
      font-size: 1.8rem;
      display: block;
      padding: 18px 22px 18px 35px;
      text-align: center;
      position: relative;
    }
    &:nth-child(1) {
      a {
        border: 1px solid $c-ao;
        // border-right: 0;
        color: $c-ao;
        padding-top: 17px;
        padding-bottom: 17px;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 21px;
          background: url('/img/common/icn_arrow_01_blue.svg') left top / 5px no-repeat;
          width: 5px;
          height: 10px;
          margin-top: -5px;
        }
      }
    }
    // &:nth-child(2) {
    //   a {
    //     background: $c-ao;
    //     color: #fff;
    //     &::before {
    //       content: '';
    //       position: absolute;
    //       top: 50%;
    //       left: 21px;
    //       background: url('/img/common/icn_arrow_01_white.svg') left top / 5px no-repeat;
    //       width: 5px;
    //       height: 10px;
    //       margin-top: -5px;
    //     }
    //   }
    // }
  }
}

// header-bottom
.header-bottom {
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  padding: 1px 0;
  @include media(sp) {
    display: none;
  }
}
.header-bottom-inner {
  background: #f5f5f5;
}
.nav {
  background: #fff;
  position: relative;
  .first-nav {
    display: flex;
    background: #f5f5f5;
    border-right: 1px solid #c8c8c8;
    li.about {
      width: 28%;
    }
    li.course {
      width: 18%;
    }
    li.result {
      width: 22%;
    }
    li.teacher {
      width: 16%;
    }
    li.contents {
      width: 16%;
    }
    li.faq {
      width: 16%;
    }
    > li {
      border-left: 1px solid #c8c8c8;
      padding: 0 1px;
      box-sizing: border-box;
      &:last-child a:hover {
        // background: $c-ao;
        // color: #fff;
      }
      a {
        width: 100%;
        font-family: $mincho;
        font-size: 1.6rem;
        text-align: center;
        display: block;
        padding: 31px 0;
        color: #000;
        transition: .1s ease-in-out;
      }
    }
  }
  .second-nav {
    width: 1000px;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 88px;
    background: rgba(21, 32, 69, .95);
    z-index: 2;
    left: 0;
    transition: .2s ease-in-out;
    padding: 30px 39px;
    &::after {
      content: "";
      position: absolute;
      top: -10px;
      left: 100px;
      margin-left: 34px;
      display: block;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent rgba(21, 32, 69, .9) transparent;
    }
    @media all and (max-width: 1020px) {
      width: auto;
      max-width: 1000px;
      &::after {
        border: none;
      }
    }
    li {
      text-align: left;
      position: relative;
      &:last-child {
        border-bottom: 0;
      }
      &.ti {
        color: #fff;
        font-family: $mincho;
        font-size: 1.7rem;
        text-align: left;
      }
      a {
        padding: 6px 0 6px 13px;
        border-bottom: 1px solid #3c55a7;
        color: #fff;
        text-align: left;
        transition: .1s ease-in-out;
        &::before {
          content: '';
          position: absolute;
          width: 7px;
          height: 7px;
          top: 50%;
          left: 0;
          margin-top: -3px;
          background: #e6d496;
          border-radius: 50%;
        }
        &:hover {
          background: none;
          color: #fff;
          opacity: .7;
        }
      }
    }
  }
  li.about .second-nav li {
    margin-right: 30px;
    float: left;
    width: 230px;
    &:first-child, &:nth-child(2) {
      float: none;
      width: 100%;
      a {
        width: 230px;
      }
    }
  }
  li.course .second-nav {
    &::after {
      left: 310px;
    }
    li {
      margin-right: 30px;
      float: left;
      width: 230px;
      &:first-child {
        float: none;
        width: 100%;
        a {
          width: 230px;
        }
      }
    }
  }
  li.result .second-nav {
    &::after {
      left: 485px;
    }
    li.ti {
      width: 100%;
    }
    li.national, li.private {
      width: 230px;
    }
    li.national a, li.private a {
      padding-bottom: 0;
      border-bottom: none;
      &::before {
        margin-top: 2px;
      }
    }
    .result-list {
      margin-bottom: 20px;
      li {
        margin-right: 30px;
        float: left;
        width: 200px;
      }
    }
    .college-list {
      &.national {
        margin-bottom: 5px;
      }
      li {
        margin-right: 30px;
        float: left;
        width: auto;
        a::before {
          background: #fff;
        }
      }
    }
  }
  li.teacher .second-nav {
    &::after {
      left: 662px;
    }
    li {
      margin-right: 30px;
      float: left;
      width: 200px;
    }
  }
  li.contents .second-nav {
    &::after {
      left: 855px;
    }
    li {
      margin-right: 30px;
      float: left;
      width: 200px;
    }
  }

  li.faq .second-nav {
    &::after {
      left: 840px;
    }
    li {
      margin-right: 30px;
      float: left;
      width: 200px;
    }
  }

  .first-nav {
    li {
      &:hover {
        > .second-nav {
          opacity: 1;
          visibility: visible;
          // display: block;
        }
      }
    }
  }
}
